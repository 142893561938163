import React from 'react'
import Button from '@fnd/components/Button'
import { QUERIES } from '@fnd/constants'
import { useQueueStore } from '@fnd/store'
import { useQueryClient } from '@tanstack/react-query'
import { useIntl } from 'react-intl'
import { userSettingsSelector, useUserContext } from '@fnd/modules/User'

export const AcceptSubmission = ({ playlistId, track, submitter }) => {
  const intl = useIntl()

  const { profile_settings } = useUserContext(({ user }) => {
    return {
      profile_settings: userSettingsSelector(user),
    }
  })

  const {
    addTrackToPlaylist,
    loadingTrackId,
    setLoadingTrackId,
    resetLoadingTrackId,
    followArtists,
  } = useQueueStore()
  const queryClient = useQueryClient()
  const queryKey = `${QUERIES.PLAYLIST.QUEUE}.${playlistId}`

  const handleAddTrackToPlaylist = async () => {
    setLoadingTrackId(track.id)

    await addTrackToPlaylist(playlistId, track.id, submitter.id)

    if (profile_settings.submission_accept_follow_artists) {
      const artistIds = track.artists.map((artist) => artist.id)
      if (artistIds?.length > 0) {
        await followArtists(artistIds)
      }
    }

    queryClient.setQueryData([queryKey], (oldData) => {
      if (!oldData) return oldData

      const newPages = oldData.pages.map((page) => {
        const newQueue = page.data.map((submission) => {
          if (submission.track.id === track.id) {
            return {
              ...submission,
              handled_by: 'user',
              status: 'accepted',
            }
          }

          return submission
        })

        return {
          ...page,
          data: newQueue,
        }
      })

      return {
        ...oldData,
        pages: newPages,
      }
    })

    resetLoadingTrackId()
  }

  return (
    <Button
      small
      variant="green-light"
      icon="thumbs-up"
      loading={loadingTrackId === track.id}
      disabled={loadingTrackId === track.id}
      tooltip={intl.formatMessage({
        id: 'playlist_queue.action.add',
      })}
      onClick={handleAddTrackToPlaylist}
    />
  )
}

export default AcceptSubmission
