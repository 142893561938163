import React, { useState, useEffect } from 'react'
import { userProfileSelector } from '@fnd/modules/User'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import Field from '@fnd/components/Field'
import { useReceiveArtists } from '@fnd/modules/Artist'
import {
  CustomImageOption,
  CustomImageSingleValue,
} from '@fnd/components/Field/SelectComponents'
import classNames from 'classnames'

export const ArtistSelect = ({ onChange, className }) => {
  const [artist, setArtist] = useState(null)
  const [artistId, setArtistId] = useState(null)
  const [artistOptions, setArtistOptions] = useState([])

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  const [artistState] = useReceiveArtists(profile.spotify_artist_id)

  useEffect(() => {
    if (!artistId && artistState?.artists?.length > 0) {
      setArtistId(artistState.artists[0].id)

      if (onChange) {
        onChange(artistState.artists[0])
      }
    }
  }, [artistState])

  useEffect(() => {
    if (artistState.artists.length > 0) {
      const options = artistState.artists.map((artist) => ({
        image: artist?.images[0].url,
        value: artist?.id,
        label: artist?.name,
      }))

      setArtistOptions(options)
      setArtist(options[0])
    }
  }, [artistState])

  const handleChange = (artist) => {
    const artistId = artist.value
    const artistData = artistState.artists.find(
      (artist) => artist.id === artistId
    )
    if (!artistData) return

    setArtist(artist)
    setArtistId(artistData.id)

    if (onChange) {
      onChange(artistData)
    }
  }

  const classes = classNames({
    [className]: className,
  })

  if (artistOptions.length === 0) {
    return null
  }

  return (
    <div className={classes}>
      <Field
        type="select"
        isClearable={false}
        options={artistOptions}
        value={artist}
        defaultValue={artistOptions[0]}
        isDisabled={artistOptions.length === 1}
        components={{
          Option: CustomImageOption,
          SingleValue: CustomImageSingleValue,
        }}
        onChange={handleChange}
        imageClasses="w-8 h-8 rounded-full"
      />
    </div>
  )
}

export default ArtistSelect
