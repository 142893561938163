import React from 'react'
import Button from '@fnd/components/Button'
import { QUERIES } from '@fnd/constants'
import { useQueueStore } from '@fnd/store'
import { useQueryClient } from '@tanstack/react-query'
import { useIntl } from 'react-intl'

export const RemoveSubmission = ({ playlistId, track, submitter }) => {
  const intl = useIntl()

  const {
    loadingTrackId,
    removeTrackFromPlaylist,
    resetLoadingTrackId,
    setLoadingTrackId,
  } = useQueueStore()

  const queryClient = useQueryClient()
  const queryKey = `${QUERIES.PLAYLIST.QUEUE}.${playlistId}`

  const handleRemoveTrack = () => {
    setLoadingTrackId(track.id)

    removeTrackFromPlaylist(playlistId, track.id, submitter.id)

    queryClient.setQueryData([queryKey], (oldData) => {
      if (!oldData) return oldData

      const newPages = oldData.pages.map((page) => {
        const newQueue = page.data.map((submission) => {
          if (submission.track.id === track.id) {
            return {
              ...submission,
              handled_by: 'user',
              status: 'pending',
            }
          }

          return submission
        })

        return {
          ...page,
          data: newQueue,
        }
      })

      return {
        ...oldData,
        pages: newPages,
      }
    })

    resetLoadingTrackId()
  }

  return (
    <Button
      small
      variant="inverse-light"
      icon="minus"
      loading={loadingTrackId === track.id}
      disabled={loadingTrackId === track.id}
      tooltip={intl.formatMessage({
        id: 'playlist_queue.action.remove',
      })}
      onClick={handleRemoveTrack}
    />
  )
}

export default RemoveSubmission
