import React from 'react'

const Emoji = (props) => {
  const { className, label, symbol } = props
  return (
    <span
      className={`emoji ${className}`}
      role="img"
      aria-label={label}
      aria-hidden={label ? 'false' : 'true'}
    >
      {symbol}
    </span>
  )
}

export default Emoji

Emoji.defaultProps = {
  className: '',
}
