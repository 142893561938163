import React from 'react'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import { Modal } from '@fnd/components/Modal'
import { PLANS } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import { useIntl } from 'react-intl'

export const ConversationRestrictions = ({ error }) => {
  const intl = useIntl()

  const descriptionText = intl.formatMessage(
    { id: 'chat_upgrade.description' },
    {
      agency: (
        <Button variant="link" to={`${ROUTES.PRICING}?plan=${PLANS.AGENCY}`}>
          Agency
        </Button>
      ),
      artist: (
        <Button variant="link" to={`${ROUTES.PRICING}?plan=${PLANS.ARTIST}`}>
          Artist
        </Button>
      ),
    }
  )

  return (
    <Modal
      title={intl.formatMessage({ id: 'messages.upgrade_required' })}
      isOpen={error}
    >
      <div className="p-5">
        <p className="text-xl mb-6">{descriptionText}</p>

        <Alert>{intl.formatMessage({ id: 'chat_upgrade.note' })}</Alert>
      </div>
    </Modal>
  )
}

export default ConversationRestrictions
