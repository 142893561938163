import React from 'react'
import { useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import Card from '@fnd/components/Card'
import Icon from '@fnd/components/Icon'
import Info from '@fnd/components/Info'
import Separator from '@fnd/components/Separator'
import { formatCurrency } from '@fnd/core/libs/currency'
import { useVipCampaignStore } from '@fnd/store'
import VipCampaignEstimate from './VipCampaignEstimate'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import classNames from 'classnames'
import {
  CAMPAIGN_ALGO_PRICE,
  CAMPAIGN_BOOST_PRICE,
  CAMPAIGN_MANAGER_PRICE,
} from './config'

const VipCampaignSummary = ({
  campaign,
  impressions,
  payment,
  className,
  showEstimate = false,
}) => {
  const { getCommissions, getTikTokTalentsTotal, hasPlan } =
    useVipCampaignStore()

  const { plan } = useUserContext(({ user }) => userProfileSelector(user))

  const intl = useIntl()
  const { locale } = intl

  const classes = classNames({
    'vip-campaign-summary': true,
    'p-5 border-outline': true,
    [className]: className,
  })

  const vipCampaignFeatures = [
    'vip_campaign.feature1',
    'vip_campaign.feature2',
    'vip_campaign.feature3',
  ]

  return (
    <Card
      className={classes}
      title={intl.formatMessage({ id: 'vip_campaign.summary.title' })}
    >
      <p className="text-sm opacity-80 leading-5 mb-6">
        {intl.formatMessage({ id: 'vip_campaign.summary.text' })}
      </p>

      <ul className="flex flex-col items-start gap-2">
        {vipCampaignFeatures.map((feature, index) => (
          <li key={index}>
            <Icon name="check" className="text-green mr-2" />
            <span>{intl.formatMessage({ id: feature })}</span>
          </li>
        ))}
      </ul>

      <Separator className="my-4" />

      <VipCampaignEstimate
        campaign={campaign}
        impressions={impressions}
        showEstimate={showEstimate}
      />

      {campaign?.typologies.includes('spotify') &&
        campaign?.budget?.spotify && (
          <Info
            small
            label="Budget Spotify"
            value={
              <span className="font-semibold text-green">
                {formatCurrency(campaign.budget.spotify, locale)}
              </span>
            }
          />
        )}

      {campaign?.typologies.includes('spotifyAlgo') &&
        campaign?.budget?.spotifyAlgo && (
          <Info
            small
            label="Budget Spotify Algo"
            value={
              <span className="font-semibold text-green">
                {formatCurrency(campaign.budget.spotifyAlgo, locale)}
              </span>
            }
          />
        )}

      {campaign?.typologies.includes('tiktok') && campaign?.budget?.tiktok && (
        <Info
          small
          label="Budget TikTok"
          value={
            <span className="font-semibold text-green">
              {formatCurrency(campaign.budget.tiktok, locale)}
            </span>
          }
        />
      )}

      {campaign?.typologies.includes('tiktokTalents') &&
        campaign?.budget?.tiktokTalents && (
          <Info
            small
            label="Budget TikTok Talents"
            value={
              <span className="font-semibold text-green">
                {formatCurrency(getTikTokTalentsTotal(), locale)}
              </span>
            }
          />
        )}

      {campaign?.typologies.includes('youtube') &&
        campaign?.budget?.youtube && (
          <Info
            small
            label="Budget YouTube"
            value={
              <span className="font-semibold text-green">
                {formatCurrency(campaign.budget.youtube, locale)}
              </span>
            }
          />
        )}

      {campaign?.budget?.manager && (
        <Info
          small
          label={
            <span>
              {intl.formatMessage({ id: 'vip_campaign.field.manager.label' })}
            </span>
          }
          value={
            <span className="font-semibold text-green">
              {formatCurrency(CAMPAIGN_MANAGER_PRICE, locale)}
            </span>
          }
        />
      )}

      {campaign?.budget?.algo && (
        <Info
          small
          label={
            <span>
              {intl.formatMessage({ id: 'vip_campaign.field.algo.label' })}
            </span>
          }
          value={
            <span className="font-semibold text-green">
              {formatCurrency(CAMPAIGN_ALGO_PRICE, locale)}
            </span>
          }
        />
      )}

      {campaign?.budget?.boost && (
        <Info
          small
          label={
            <span>
              {intl.formatMessage({ id: 'vip_campaign.field.boost.label' })}
            </span>
          }
          value={
            <span className="font-semibold text-green">
              {formatCurrency(CAMPAIGN_BOOST_PRICE, locale)}
            </span>
          }
        />
      )}

      {campaign?.addOns?.length > 0 &&
        campaign?.addOns.map((addOn, index) => (
          <Info
            key={index}
            small
            label={<span>{addOn?.name}</span>}
            value={
              <span className="font-semibold text-green">
                {formatCurrency(addOn?.price, locale)}
              </span>
            }
          />
        ))}

      <Info
        small
        tooltipText={intl.formatMessage({
          id: 'vip_campaign.field.commission.tooltip',
        })}
        label={
          <span>
            {intl.formatMessage({ id: 'vip_campaign.field.commission.label' })}
          </span>
        }
        value={
          <div
            className={classNames({
              'inline-flex items-center gap-3 font-semibold': true,
              'text-green': !hasPlan(plan),
            })}
          >
            <span
              className={classNames({
                'strike-through opacity-90': hasPlan(plan),
              })}
            >
              {formatCurrency(getCommissions(), locale)}
            </span>

            {hasPlan(plan) && (
              <Badge small variant="green-light">
                {intl.formatMessage({ id: 'messages.commission.free' })}
              </Badge>
            )}
          </div>
        }
      />

      {campaign?.transaction && campaign.transaction?.creditsUsed > 0 && (
        <Info
          small
          label={
            <span>
              {intl.formatMessage({ id: 'messages.use_credits_discount' })}
            </span>
          }
          value={
            <span className="font-semibold">
              {'-'} {formatCurrency(campaign.transaction?.creditsUsed, locale)}
            </span>
          }
        />
      )}

      {campaign?.transaction && campaign.transaction?.discount?.amount > 0 && (
        <Info
          small
          label={
            <span>
              {intl.formatMessage({ id: 'messages.coupon_discount' })}
            </span>
          }
          value={
            <span className="font-semibold">
              {'-'}{' '}
              {formatCurrency(campaign.transaction?.discount?.amount, locale)}
            </span>
          }
        />
      )}

      {campaign?.transaction && campaign.transaction?.affiliate && (
        <Info
          small
          label={
            <span>{intl.formatMessage({ id: 'navigation.affiliate' })}</span>
          }
          value={
            <Badge
              variant="green-light"
              className="font-semibold"
              label={campaign.transaction?.affiliate}
            />
          }
        />
      )}

      {payment && (
        <>
          <Info
            small
            label={
              <span>{intl.formatMessage({ id: 'messages.subtotal' })}</span>
            }
            value={
              <span className="font-semibold text-green">
                {formatCurrency(payment?.total, locale)}
              </span>
            }
          />

          <Info
            small
            label={
              <span>{intl.formatMessage({ id: 'messages.price_vat' })}</span>
            }
            value={
              <span className="font-semibold text-green">
                {formatCurrency(payment?.vat, locale)}
              </span>
            }
          />

          <Info
            small
            className="border-b-0"
            label={
              <span>
                {intl.formatMessage({ id: 'messages.total' })}
                <span className="text-sm opacity-80 ml-2">
                  {intl.formatMessage({ id: 'messages.vat_included' })}
                </span>
              </span>
            }
            value={
              <span className="font-semibold text-green">
                {formatCurrency(
                  campaign.transaction?.amount || payment?.totalWithVAT,
                  locale
                )}
              </span>
            }
          />
        </>
      )}
    </Card>
  )
}

export default VipCampaignSummary
