import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@fnd/components/Button'
import Select from 'react-select'
import Toggle from 'react-toggle'
import {
  MATCH_POSITION_OPTIONS,
  DIGEST_FREQUENCY_OPTIONS,
  PLANS,
} from '@fnd/constants'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { toastFeedback } from '@fnd/core/libs/toast'
import { AVAILABLE_LANGUAGES } from '@fnd/screens/constants'
import classNames from 'classnames'
import { FieldTooltip } from '@fnd/components/Field/Field'

export const AccountPreferences = ({
  defaultConsent,
  defaultSettings,
  userPlan,
}) => {
  const [preferredLanguage, setPreferredLanguage] = useState()
  const [consent, setConsent] = useState(defaultConsent)
  const [settings, setSettings] = useState(defaultSettings)
  const [isLoading, setIsLoading] = useState('')

  const intl = useIntl()

  useEffect(() => {
    setPreferredLanguage(defaultSettings.preferred_language)
  }, [defaultSettings])

  const preferredLanguageChangeHandler = (option, { action }) => {
    if (action !== 'select-option') {
      return
    }

    setPreferredLanguage(option.value)
  }

  const updateUserSettings = async (key, value) => {
    try {
      setIsLoading(key)
      const { settings, consent } = await spotimatchEndpoints
        .updateUserSettings(key, value)
        .toPromise()

      toastFeedback(
        'success',
        intl.formatMessage({ id: 'feedback.success.user_settings_update' })
      )

      setSettings(settings)
      setConsent(consent)
      setIsLoading('')
    } catch (error) {
      console.log(error)
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.default' })
      )
      setIsLoading('')
    }
  }

  const getSettingInputClassNames = (value, key) =>
    classNames({
      button: true,
      'button-outline-primary': true,
      active: settings[key] === value,
      disabled: isLoading === key,
    })

  const availableLanguages = AVAILABLE_LANGUAGES.map(({ label, value }) => ({
    label: intl.formatMessage({ id: label }),
    value,
  }))

  return (
    <>
      <div className="my-6 account-section">
        <h5>
          <FormattedMessage id="profile.account.settings.general" />
        </h5>
        <div className="space-y-5">
          <div>
            <label htmlFor="settings_preferred_language">
              <FormattedMessage id="profile.account.settings.preferred_language" />
            </label>
            <p className="text-sm opacity-80 mb-4">
              <FormattedMessage id="profile.account.settings.preferred_language.help" />
            </p>

            <Select
              id="settings_preferred_language"
              name="preferred_language"
              onChange={preferredLanguageChangeHandler}
              options={availableLanguages}
              value={availableLanguages.find(
                ({ value }) => value === preferredLanguage
              )}
              className="select"
              classNamePrefix="select"
              isSearchable={false}
            />
          </div>
          <Button
            className="w-full md:w-auto"
            variant="primary"
            disabled={isLoading === 'preferred_language'}
            loading={isLoading === 'preferred_language'}
            onClick={() =>
              updateUserSettings('preferred_language', preferredLanguage)
            }
            label={<FormattedMessage id="profile.account.settings.save" />}
          />
        </div>
      </div>

      <div className="my-6 account-section">
        <h5>
          <FormattedMessage id="profile.account.settings.match" />
        </h5>

        <div className="space-y-5">
          <div className="flex rounded-md items-center">
            <div className="flex flex-col grow">
              <label htmlFor="settings_match_follow_artists">
                <FormattedMessage id="profile.account.settings.match.follow_artists" />
              </label>
              <p className="text-sm opacity-80">
                <FormattedMessage id="profile.account.settings.match.follow_artists.help" />
              </p>
            </div>
            <div>
              <Toggle
                disabled={isLoading === 'submission_accept_follow_artists'}
                checked={settings.submission_accept_follow_artists}
                onChange={() =>
                  updateUserSettings(
                    'submission_accept_follow_artists',
                    !settings.submission_accept_follow_artists
                  )
                }
              />
            </div>
          </div>

          {/* <div className="flex rounded-md items-center">
            <div className="flex flex-col grow">
              <label htmlFor="settings_match_like_songs">
                <FormattedMessage id="profile.account.settings.match.like_songs" />
              </label>
              <p className="text-sm opacity-80">
                <FormattedMessage id="profile.account.settings.match.like_songs.help" />
              </p>
            </div>
            <div>
              <Toggle
                disabled={isLoading === 'submission_accept_like_songs'}
                checked={settings.submission_accept_like_songs}
                onChange={() => updateUserSettings('submission_accept_like_songs', !settings.submission_accept_like_songs)}
              />
            </div>
          </div> */}

          {/* <div className="flex rounded-md items-center">
            <div className="flex flex-col grow">
              <label htmlFor="settings_match_follow_playlists">
                <FormattedMessage id="profile.account.settings.match.follow_playlists" />
              </label>
              <p className="text-sm opacity-80">
                <FormattedMessage id="profile.account.settings.match.follow_playlists.help" />
              </p>
            </div>
            <div>
              <Toggle
                disabled={isLoading === 'submission_send_follow_playlist'}
                checked={settings.submission_send_follow_playlist}
                onChange={() => updateUserSettings('submission_send_follow_playlist', !settings.submission_send_follow_playlist)}
              />
            </div>
          </div> */}

          <div className="flex rounded-md items-center">
            <div className="flex flex-col grow">
              <label htmlFor="settings_match_song_position">
                <FormattedMessage id="profile.account.settings.match.song_position" />
              </label>
              <p className="text-sm opacity-80">
                <FormattedMessage id="profile.account.settings.match.song_position.help" />
              </p>
            </div>
            <div>
              <div
                className={`button-group mb-5 ${
                  isLoading === 'submission_accept_song_position'
                    ? 'disabled'
                    : ''
                }`}
                data-toggle="submission_accept_song_position"
              >
                {MATCH_POSITION_OPTIONS(intl).map(({ label, value }) => (
                  <label
                    className={getSettingInputClassNames(
                      value,
                      'submission_accept_song_position'
                    )}
                    htmlFor={`submission_accept_song_position_${value}`}
                    key={`submission_accept_song_position_${value}`}
                  >
                    <input
                      onChange={() =>
                        updateUserSettings(
                          'submission_accept_song_position',
                          value
                        )
                      }
                      type="radio"
                      value={value}
                      id={`submission_accept_song_position_${value}`}
                      checked={
                        settings.submission_accept_song_position === value
                      }
                    />
                    {label}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="my-6 account-section">
        <h5>
          <FormattedMessage id="profile.account.settings.notifications" />
        </h5>

        <div className="space-y-5">
          <div className="flex rounded-md items-center">
            <div className="flex flex-col grow">
              <label htmlFor="settings_match_like_songs">
                <FormattedMessage id="profile.account.settings.web_notifications" />
              </label>
              <p className="text-sm opacity-80">
                <FormattedMessage id="profile.account.settings.web_notifications.help" />
              </p>
            </div>
            <div>
              <Toggle
                disabled={isLoading === 'web_notifications'}
                checked={settings.web_notifications}
                onChange={() =>
                  updateUserSettings(
                    'web_notifications',
                    !settings.web_notifications
                  )
                }
              />
            </div>
          </div>

          <div className="flex rounded-md items-center">
            <div className="flex flex-col grow">
              <label htmlFor="settings_match_follow_playlists">
                <FormattedMessage id="profile.account.settings.newsletter" />
              </label>
              <p className="text-sm opacity-80">
                <FormattedMessage id="profile.account.settings.newsletter.help" />
              </p>
            </div>
            <div>
              <Toggle
                disabled={isLoading === 'newsletter'}
                checked={consent?.newsletter?.given}
                onChange={() =>
                  updateUserSettings('newsletter', !consent?.newsletter?.given)
                }
              />
            </div>
          </div>

          <div className="flex rounded-md items-center">
            <div className="flex flex-col grow">
              <label htmlFor="settings_curator_digest_frequency">
                <FormattedMessage id="profile.account.settings.curator_digest_frequency" />
                {userPlan?.name === PLANS.FREE && (
                  <FieldTooltip
                    tooltipText={
                      <FormattedMessage id="messages.pro_plan.help" />
                    }
                  />
                )}
              </label>
              <p className="text-sm opacity-80">
                <FormattedMessage id="profile.account.settings.curator_digest_frequency.help" />
              </p>
            </div>
            <div>
              <div
                className={`button-group mb-5 ${
                  isLoading === 'curator_digest_frequency' ||
                  userPlan?.name === PLANS.FREE
                    ? 'disabled'
                    : ''
                }`}
                data-toggle="curator_digest_frequency"
              >
                {DIGEST_FREQUENCY_OPTIONS(intl).map(({ label, value }) => (
                  <label
                    className={getSettingInputClassNames(
                      value,
                      'curator_digest_frequency'
                    )}
                    htmlFor={`curator_digest_frequency_${value}`}
                    key={`curator_digest_frequency_${value}`}
                  >
                    <input
                      onChange={() =>
                        updateUserSettings('curator_digest_frequency', value)
                      }
                      type="radio"
                      value={value}
                      id={`curator_digest_frequency_${value}`}
                      checked={settings.curator_digest_frequency === value}
                    />
                    {label}
                  </label>
                ))}
              </div>
            </div>
          </div>

          <div className="flex rounded-md items-center">
            <div className="flex flex-col grow">
              <label htmlFor="settings_artist_digest_frequency">
                <FormattedMessage id="profile.account.settings.artist_digest_frequency" />
                {userPlan?.name === PLANS.FREE && (
                  <FieldTooltip
                    tooltipText={
                      <FormattedMessage id="messages.pro_plan.help" />
                    }
                  />
                )}
              </label>
              <p className="text-sm opacity-80">
                <FormattedMessage id="profile.account.settings.artist_digest_frequency.help" />
              </p>
            </div>
            <div>
              <div
                className={`button-group mb-5 ${
                  isLoading === 'artist_digest_frequency' ||
                  userPlan?.name === PLANS.FREE
                    ? 'disabled'
                    : ''
                }`}
                data-toggle="artist_digest_frequency"
              >
                {DIGEST_FREQUENCY_OPTIONS(intl).map(({ label, value }) => (
                  <label
                    className={getSettingInputClassNames(
                      value,
                      'artist_digest_frequency'
                    )}
                    htmlFor={`artist_digest_frequency_${value}`}
                    key={`artist_digest_frequency_${value}`}
                  >
                    <input
                      onChange={() =>
                        updateUserSettings('artist_digest_frequency', value)
                      }
                      type="radio"
                      value={value}
                      id={`artist_digest_frequency_${value}`}
                      checked={settings.artist_digest_frequency === value}
                    />
                    {label}
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AccountPreferences
