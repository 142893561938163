import React from 'react'
import Badge from '@fnd/components/Badge'
import Icon from '@fnd/components/Icon'
import { useIntl } from 'react-intl'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import classNames from 'classnames'

const renderItem = (item) => {
  if (item && item.length > 0) return item
  else if (item) return <Icon className="text-green text-xl" name="check" />
  else return <Icon className="text-red text-xl" name="times" />
}

export const ComparePlans = ({
  title,
  compareTitle,
  features,
  matchfy,
  competitor,
}) => {
  const classes = classNames({
    table: true,
    'plan-compare': true,
  })

  return (
    <table className={classes}>
      <thead>
        <tr>
          <th></th>
          <th className="text-lg text-center opacity-100">
            <Icon className="mr-1" name="fire" />
            {title}
          </th>
          <th className="text-center opacity-100">{compareTitle}</th>
        </tr>
      </thead>
      <tbody>
        {features.map((feature, index) => (
          <tr key={index}>
            <td>{feature}</td>
            <td className="text-center">{renderItem(matchfy[index])}</td>
            <td className="text-center">{renderItem(competitor[index])}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const CompareSection = () => {
  const intl = useIntl()

  const compareData = {
    title: 'Matchfy',
    compareTitle: 'Competitors',
    features: [
      intl.formatMessage({ id: 'compare.feature.1' }),
      intl.formatMessage({ id: 'compare.feature.2' }),
      intl.formatMessage({ id: 'compare.feature.3' }),
      intl.formatMessage({ id: 'compare.feature.4' }),
      intl.formatMessage({ id: 'compare.feature.5' }),
      intl.formatMessage({ id: 'compare.feature.6' }),
      intl.formatMessage({ id: 'compare.feature.7' }),
      intl.formatMessage({ id: 'compare.feature.8' }),
    ],
    matchfy: [
      intl.formatMessage({ id: 'compare.matchfy.1' }),
      intl.formatMessage({ id: 'compare.matchfy.2' }),
      intl.formatMessage({ id: 'compare.matchfy.3' }),
      intl.formatMessage({ id: 'compare.matchfy.4' }),
      intl.formatMessage({ id: 'compare.matchfy.5' }),
      intl.formatMessage({ id: 'compare.matchfy.6' }),
      intl.formatMessage({ id: 'compare.matchfy.7' }),
      intl.formatMessage({ id: 'compare.matchfy.8' }),
    ],
    competitor: [
      intl.formatMessage({ id: 'compare.competitor.1' }),
      intl.formatMessage({ id: 'compare.competitor.2' }),
      intl.formatMessage({ id: 'compare.competitor.3' }),
      intl.formatMessage({ id: 'compare.competitor.4' }),
      false,
      intl.formatMessage({ id: 'compare.competitor.6' }),
      false,
      intl.formatMessage({ id: 'compare.competitor.8' }),
    ],
  }

  return (
    <section className="compare-section">
      <SectionTitle
        tag="h3"
        title={intl.formatMessage({ id: 'compare.title' })}
        subtitle={intl.formatMessage({ id: 'compare.subtitle' })}
        badge={
          <Badge
            variant="primary-light"
            label={intl.formatMessage({ id: 'messages.compare' })}
            icon="balance-scale"
          />
        }
        center
      />

      <div className="w-full lg:w-3/4 mx-auto">
        <ComparePlans {...compareData} />
      </div>
    </section>
  )
}

export default CompareSection
