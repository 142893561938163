import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'
import Icon from '@fnd/components/Icon'
import { DEFAULT_ICON_TYPE } from '@fnd/constants'
import { AnimatePresence, motion } from 'framer-motion'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import { nanoid } from 'nanoid'

const transition = {
  type: 'tween',
  ease: 'easeOut',
  duration: 0.15,
}

export const TabLinks = ({
  className,
  inline,
  tabs,
  selectedTabIndex,
  showHover = true,
  showIndicator = true,
  variant,
}) => {
  const [buttonRefs, setButtonRefs] = useState([])

  useEffect(() => {
    setButtonRefs((prev) => prev.slice(0, tabs.length))
  }, [tabs.length])

  const navRef = useRef(null)
  const navRect = navRef.current?.getBoundingClientRect()

  const selectedRect = buttonRefs[selectedTabIndex]?.getBoundingClientRect()

  const [hoveredTabIndex, setHoveredTabIndex] = useState(null)
  const tabIndex = hoveredTabIndex !== null ? hoveredTabIndex : -1
  const hoveredRect = buttonRefs[tabIndex]?.getBoundingClientRect()

  const navigate = useNavigate()

  const classes = classNames({
    'tab-header': true,
    'tab-inline': inline,
    [`tab-${variant}`]: variant,
    [className]: className,
  })

  const handleTabClick = (item) => {
    if (item.onClick) {
      item.onClick()
    }

    if (item.to) {
      navigate(item.to)
    }
  }

  return (
    <nav
      ref={navRef}
      className={classes}
      onPointerLeave={() => setHoveredTabIndex(null)}
    >
      {tabs.map((item, i) => {
        return (
          <motion.button
            key={nanoid()}
            className={classNames('button', {
              hovered: hoveredTabIndex === i || selectedTabIndex === i,
              active: selectedTabIndex === i,
            })}
            ref={(el) => (buttonRefs[i] = el)}
            onPointerEnter={
              !isMobile
                ? () => {
                    setHoveredTabIndex(i)
                  }
                : null
            }
            onFocus={
              !isMobile
                ? () => {
                    setHoveredTabIndex(i)
                  }
                : null
            }
            onClick={() => handleTabClick(item)}
          >
            {item.icon && (
              <Icon
                className="icon"
                type={item.iconType ?? DEFAULT_ICON_TYPE}
                name={item.icon}
              />
            )}
            <span>{item.label}</span>
          </motion.button>
        )
      })}

      <AnimatePresence>
        {hoveredRect && navRect && showHover && (
          <motion.div
            key="hover"
            className="tab-hover"
            initial={{
              x: hoveredRect.left - navRect.left,
              y: hoveredRect.top - navRect.top,
              width: hoveredRect.width,
              height: hoveredRect.height,
              opacity: 0,
            }}
            animate={{
              x: hoveredRect.left - navRect.left,
              y: hoveredRect.top - navRect.top,
              width: hoveredRect.width,
              height: hoveredRect.height,
              opacity: 1,
            }}
            exit={{
              x: hoveredRect.left - navRect.left,
              y: hoveredRect.top - navRect.top,
              width: hoveredRect.width,
              height: hoveredRect.height,
              opacity: 0,
            }}
            transition={transition}
          />
        )}
      </AnimatePresence>

      {selectedRect && navRect && showIndicator && (
        <motion.div
          className="tab-indicator"
          initial={false}
          animate={{
            width: selectedRect.width * 0.8,
            x: `calc(${selectedRect.left - navRect.left}px + 10%)`,
            opacity: 1,
          }}
          transition={transition}
        />
      )}
    </nav>
  )
}

TabLinks.defaultProps = {
  className: '',
}
