import React, { memo } from 'react'
import { LogoIcon } from '@fnd/components/Logo/Logo'
import classNames from 'classnames'

const Spinner = ({ inline, message, variant, className }) => {
  const classes = classNames({
    loading: true,
    'loading-inline': inline,
    [`loading-${variant}`]: variant,
    [className]: className,
  })

  const SpinnerLogo = memo(() => {
    return (
      <div className="spinner-container">
        <div className="spinner" />
        <div className="spinner-logo">
          <LogoIcon />
        </div>
      </div>
    )
  })

  return (
    <div className={classes}>
      <SpinnerLogo />
      {message && <p className="spinner-message">{message}</p>}
    </div>
  )
}

export default Spinner
