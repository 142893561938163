import React from 'react'
import Icon from '@fnd/components/Icon'
import classNames from 'classnames'

export const InputCard = ({
  className,
  children,
  icon,
  iconType,
  image,
  label,
}) => {
  const classes = classNames({
    'input-card': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <div className="input-card-content">
        {image && (
          <div className="input-card-image">
            <img src={image} alt={label} />
          </div>
        )}

        {icon && <Icon className="input-icon" name={icon} type={iconType} />}

        <div className="flex flex-col gap-4 items-start w-full">
          {label && <span>{label}</span>}

          {children}
        </div>
      </div>
    </div>
  )
}

export default InputCard
