import React from 'react'
import { FormattedMessage } from 'react-intl'
import Avatar from '@fnd/components/Avatar'
import Badge from '@fnd/components/Badge'
import { CreditsModal } from '@fnd/components/Credits'
import { Icons } from '@fnd/components/Icons'
import { NavLink } from 'react-router-dom'
import { ROUTES, PROFILE_SIDEBAR as routes } from '@fnd/screens/constants'
import Icon from '@fnd/components/Icon'

export default function ProfileNavSidebar({ userProfile }) {
  const [avatar = {}] = userProfile.images

  return (
    <nav className="profile-sidebar">
      <div className="profile-sidebar-header">
        <NavLink
          className="flex items-center gap-2 mb-4"
          to={`${ROUTES.CURATORS}/${userProfile.id}`}
        >
          <Avatar
            size="small"
            src={avatar.url}
            label={userProfile.id}
            star={userProfile.star}
            hideStarBadge
          />

          {userProfile.spotify_username && (
            <h4 className="inline-flex items-center gap-1 text-base font-medium m-0">
              {userProfile.spotify_username}
              {userProfile?.star && (
                <span className="verified">
                  <Icons icon="verified" />
                </span>
              )}
            </h4>
          )}
        </NavLink>

        <div className="flex flex-wrap gap-1">
          {userProfile?.plan?.name && (
            <Badge
              small
              variant="primary-light"
              className="capitalize"
              label={userProfile.plan.name}
            />
          )}

          <CreditsModal
            small
            variant="light"
            label={<FormattedMessage id="messages.credit_balance" />}
          />
        </div>
      </div>
      <div className="profile-sidebar-content">
        <ul>
          {routes.map(
            (link) =>
              link.show && (
                <li key={link.to} id={`nav-profile-${link.to}`}>
                  <NavLink
                    relative={ROUTES.MY_PROFILE.ROOT}
                    className={({ isActive }) => (isActive ? ' active' : '')}
                    to={link.to}
                  >
                    <Icon name={link.icon} />
                    <FormattedMessage id={link.label} />
                  </NavLink>
                </li>
              )
          )}
        </ul>
      </div>
    </nav>
  )
}
