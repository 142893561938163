import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

const STATUS = {
  STARTED: 'Started',
  STOPPED: 'Stopped',
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

const twoDigits = (num) => String(num).padStart(2, '0')

export default function Countdown({ initialCount, limitCount, className }) {
  const [secondsRemaining, setSecondsRemaining] = useState(initialCount)
  const [status, setStatus] = useState(STATUS.STOPPED)

  const secondsToDisplay = secondsRemaining % 60
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60
  const minutesToDisplay = minutesRemaining % 60
  const hoursToDisplay = (minutesRemaining - minutesToDisplay) / 60

  const handleStart = () => {
    setStatus(STATUS.STARTED)
  }

  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1)
      } else {
        setStatus(STATUS.STOPPED)
      }
    },
    status === STATUS.STARTED ? 1000 : null
  )

  useEffect(() => {
    handleStart()
  }, [])

  const classes = classNames({
    countdown: true,
    'countdown-limit': secondsRemaining < limitCount,
    [className]: className,
  })

  if (secondsRemaining <= 0) return null

  return (
    <div className={classes}>
      <div className="countdown-item">
        <p>
          {twoDigits(hoursToDisplay)}
          <span>
            <FormattedMessage id="countdown.hh" />
          </span>
        </p>
      </div>
      <div className="countdown-item">
        <p>
          {twoDigits(minutesToDisplay)}
          <span>
            <FormattedMessage id="countdown.mm" />
          </span>
        </p>
      </div>
      <div className="countdown-item">
        <p>
          {twoDigits(secondsToDisplay)}
          <span>
            <FormattedMessage id="countdown.ss" />
          </span>
        </p>
      </div>
    </div>
  )
}

Countdown.defaultProps = {
  initialCount: 60 * 60,
  limitCount: 30 * 60,
}
