import React from 'react'
import { motion } from 'framer-motion'
import Icon from '@fnd/components/Icon'
import classNames from 'classnames'

export const SpinnerIcon = ({
  icon = 'music',
  className,
  animating = true,
}) => {
  const classes = classNames({
    'spinner-icon': true,
    [className]: className,
  })

  return (
    <motion.div
      className={classes}
      animate={animating ? { opacity: [0.1, 1, 0.1] } : { opacity: 1 }}
      transition={
        animating ? { duration: 2, repeat: Infinity, ease: 'easeInOut' } : {}
      }
    >
      <Icon name={icon} />
    </motion.div>
  )
}

export default SpinnerIcon
