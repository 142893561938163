const { localStorage } = window

function errorHandler(message) {
  return (error) => {
    console.group('Storage Error')
    console.warning(`Error occurred while trying to ${message}`)
    console.error(error)
    console.groupEnd()
  }
}

const TOKEN_ACCESS = 'spotify-token:access'
const TOKEN_EXPIRES = 'spotify-token:expires'
const STATE = 'spotify-state'

export const jwt = {
  get token() {
    try {
      const record = JSON.parse(localStorage.getItem(TOKEN_ACCESS))

      // if (!record) {
      //   return void 0;
      // }

      // const { token } = record;

      // if (expires < new Date().getTime()) {
      //   localStorage.removeItem(TOKEN_ACCESS);

      //   return void 0;
      // }

      return !!record && record.token
    } catch (error) {
      return errorHandler(`get ${TOKEN_ACCESS}`)(error)
    }
  },

  set token({ token, expires }) {
    try {
      const record = JSON.stringify({ token, expires })

      localStorage.setItem(TOKEN_ACCESS, record)
    } catch (error) {
      errorHandler(`set ${TOKEN_ACCESS}`)(error)
    }
  },

  get expires() {
    try {
      const record = JSON.parse(localStorage.getItem(TOKEN_ACCESS))

      if (!record) {
        return void 0
      }

      return record.expires
    } catch (error) {
      return errorHandler(`get ${TOKEN_EXPIRES}`)(error)
    }
  },

  set state(value) {
    try {
      localStorage.setItem(STATE, value)
    } catch (error) {
      errorHandler(`set ${STATE}`)(error)
    }
  },

  get state() {
    try {
      return localStorage.getItem(STATE)
    } catch (error) {
      return errorHandler(`get ${STATE}`)(error)
    }
  },

  clear() {
    try {
      localStorage.removeItem(TOKEN_ACCESS)
      localStorage.removeItem(STATE)

      return void 0
    } catch (error) {
      return errorHandler(`remove ${TOKEN_ACCESS}`)(error)
    }
  },
}

export default jwt
