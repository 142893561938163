import React from 'react'
import { Placeholder } from '@fnd/components/Placeholder'
import classNames from 'classnames'

export function PlaylistCover({ image, name, className, ...props }) {
  const classes = classNames({
    'playlist-cover': true,
    'relative cursor-pointer': true,
    [className]: className,
  })

  if (image) {
    return (
      <div {...props} className={classes}>
        <img
          src={image}
          alt={name}
          className="absolute w-full h-full object-cover"
        />
      </div>
    )
  } else {
    return <Placeholder className={classes} icon="music" {...props} />
  }
}

export default PlaylistCover
