import React, { useState, useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { useSubmitEmailVerification } from '@fnd/components/EmailVerifyPrompt/useEmailVerify'
import {
  isUserAuthorizedSelector,
  userProfileSelector,
  isUserLoadingSelector,
} from '@fnd/modules/User'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { ROUTES } from '@fnd/screens/constants'
import TracksPushedDetail from '@fnd/modules/TrackQueue/TracksPushedDetail'
import DownloadDetail from '@fnd/modules/Downloads/DownloadDetail'
import ProfileNavigation from './ProfileNavigation'
import ProfileOverview from './ProfileOverview'
import BillingInfo from './BillingInfo'
import PaymentsHistory from './PaymentsHistory'
import AccountPlan from './AccountPlan'
import Downloads from './Downloads'
import MyArtists from './MyArtists'
import MyActivity from './MyActivity'
import MyPlaylists, { MyPlaylistEdit, MyPlaylistAnalysis } from './MyPlaylists'
import MyAccelerates from './MyAccelerates/MyAccelerates'
import { PlaylistCheck } from '../PlaylistCheck'
import { PlaylistSubmissions } from '../Curator/Playlist'
import Settings from './Settings'
import Dashboard from './Dashboard'
import Management from './Management'
import VipCampaigns from './VipCampaigns/VipCampaigns'
import VipCampaignView from './VipCampaigns/VipCampaignView'
import Wanted from './Wanted'
import AccountPreferences from '../User/Account/AccountPreferences'

const navigationBreakpoint = 960

const useShowDesktopNavigation = () => {
  const [showDesktopNavigation, setShowDesktopNavigation] = useState(
    window.innerWidth > navigationBreakpoint
  )

  const setNavigation = () =>
    setShowDesktopNavigation(window.innerWidth > navigationBreakpoint)
  useEffect(() => {
    window.addEventListener('resize', setNavigation)
    return () => window.removeEventListener('resize', setNavigation)
  }, [])

  return showDesktopNavigation
}

export default function Profile() {
  const intl = useIntl()
  const {
    profile,
    isAuthorized,
    isUserLoading,
    updateUserContacts,
    updateUserGenres,
    updateUserVerified,
    updateUserBillingInfo,
    updateUserReferral,
  } = useUserContext(({ user, updateUserProfile }) => ({
    profile: userProfileSelector(user),
    isAuthorized: isUserAuthorizedSelector(user),
    isUserLoading: isUserLoadingSelector(user),
    updateUserContacts: (next) =>
      updateUserProfile({ contacts: { $set: next } }),
    updateUserGenres: (next) => updateUserProfile({ tags: { $set: next } }),
    updateUserVerified: (next) =>
      updateUserProfile({ verified: { $set: next } }),
    updateUserBillingInfo: (next) =>
      updateUserProfile({ billingInfo: { $set: next } }),
    updateUserReferral: (next) =>
      updateUserProfile({ referral: { $set: next } }),
  }))

  useSubmitEmailVerification(() => updateUserVerified(true))
  const showDesktopNavigation = useShowDesktopNavigation()

  const profileWrapperClasses = classNames({
    'profile-wrapper': true,
    [showDesktopNavigation ? 'desktop' : 'mobile']: true,
  })

  if (isUserLoading) {
    return <Spinner />
  }

  if (!isAuthorized && !isUserLoading) {
    return (
      <Wrapper>
        <div className={profileWrapperClasses}>
          <Empty
            login
            ghost
            icon="user-lock"
            message={intl.formatMessage({ id: 'navigation.login_to_continue' })}
          />
        </div>
      </Wrapper>
    )
  }

  return (
    <Wrapper className="profile">
      <div className={profileWrapperClasses}>
        <ProfileNavigation
          isDesktop={showDesktopNavigation}
          userProfile={profile}
        />
        <div className="profile-content">
          <Routes>
            <Route
              exact
              path={ROUTES.MY_PROFILE.OVERVIEW_EDIT}
              element={
                <ProfileOverview
                  userProfile={profile}
                  updateUserContacts={updateUserContacts}
                  updateUserGenres={updateUserGenres}
                  updateUserVerified={updateUserVerified}
                  mode="edit"
                />
              }
            />

            <Route
              path={ROUTES.MY_PROFILE.DASHBOARD}
              element={<Dashboard userProfile={profile} />}
            />

            <Route
              path={ROUTES.MY_PROFILE.OVERVIEW}
              element={
                <ProfileOverview
                  userProfile={profile}
                  updateUserContacts={updateUserContacts}
                  updateUserGenres={updateUserGenres}
                  updateUserReferral={updateUserReferral}
                  mode="view"
                />
              }
            />

            <Route
              exact
              path={ROUTES.MY_PROFILE.BILLING_INFO_EDIT}
              element={
                <BillingInfo
                  userProfile={profile}
                  updateUserBillingInfo={updateUserBillingInfo}
                  mode="edit"
                />
              }
            />

            <Route
              path={ROUTES.MY_PROFILE.BILLING_INFO}
              element={
                <BillingInfo
                  userProfile={profile}
                  updateUserBillingInfo={updateUserBillingInfo}
                  mode="view"
                />
              }
            />

            <Route
              path={ROUTES.MY_PROFILE.MY_ARTISTS}
              element={<MyArtists />}
            />

            <Route
              path={ROUTES.MY_PROFILE.MY_ACTIVITY}
              element={<MyActivity />}
            />

            <Route
              path={ROUTES.MY_PROFILE.MY_ACTIVITY_TRACK_DETAIL}
              element={<TracksPushedDetail />}
            />

            <Route
              path={ROUTES.MY_PROFILE.VIP_CAMPAIGNS}
              element={<VipCampaigns />}
            />

            <Route
              path={ROUTES.MY_PROFILE.VIP_CAMPAIGN_VIEW}
              element={<VipCampaignView />}
            />

            <Route
              path={ROUTES.MY_PROFILE.MY_PLAYLISTS}
              exact
              element={<MyPlaylists />}
            />

            <Route
              path={ROUTES.MY_PROFILE.MY_PLAYLIST_VIEW}
              exact
              element={<PlaylistSubmissions />}
            />

            <Route
              path={ROUTES.MY_PROFILE.PLAYLIST_CHECK}
              exact
              element={<PlaylistCheck />}
            />

            <Route
              path={ROUTES.MY_PROFILE.MY_PLAYLIST_EDIT}
              element={<MyPlaylistEdit />}
            />

            <Route
              path={ROUTES.MY_PROFILE.MY_PLAYLIST_ANALYSIS}
              element={<MyPlaylistAnalysis />}
            />

            <Route
              path={ROUTES.MY_PROFILE.MY_DOWNLOADS}
              element={<Downloads />}
            />

            <Route
              path={ROUTES.MY_PROFILE.MY_DOWNLOAD_VIEW}
              element={<DownloadDetail />}
            />

            <Route
              path={ROUTES.MY_PROFILE.MY_ACCELERATES}
              element={<MyAccelerates />}
            />

            <Route
              path={ROUTES.MY_PROFILE.PAYMENTS}
              element={<PaymentsHistory userProfile={profile} />}
            />

            <Route path={ROUTES.MY_PROFILE.PLAN} element={<AccountPlan />} />

            <Route
              path={ROUTES.MY_PROFILE.PREFERENCES}
              element={<AccountPreferences />}
            />

            <Route
              path="*/*"
              render={() => <Navigate to={ROUTES.MY_PROFILE.OVERVIEW} />}
            />

            <Route path={ROUTES.MY_PROFILE.SETTINGS} element={<Settings />} />

            <Route
              path={ROUTES.MY_PROFILE.MANAGEMENT}
              element={<Management />}
            />

            <Route path={ROUTES.MY_PROFILE.WANTED} element={<Wanted />} />
          </Routes>
        </div>
      </div>
    </Wrapper>
  )
}
