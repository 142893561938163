import React from 'react'
import { FormattedMessage } from 'react-intl'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import DownloadTable from '@fnd/modules/Downloads/DownloadTable'

export default function Downloads() {
  return (
    <ProfileWrapper
      icon="cloud-download-alt"
      title={<FormattedMessage id="navigation.my_downloads" />}
    >
      <DownloadTable />
    </ProfileWrapper>
  )
}
