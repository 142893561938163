import React, { useEffect } from 'react'
import classNames from 'classnames'
import { DashboardCard } from '@fnd/components/Card'
import { ProgressBar } from '@fnd/components/Progress'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useWantedStore } from '@fnd/store'
import { QUERIES } from '@fnd/constants'
import { useQuery } from '@tanstack/react-query'
import { formatNumber, calculatePercentage } from '@fnd/core/libs/currency'

export const WantedCounters = ({ className }) => {
  const { artistId, artist } = useWantedStore()

  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERIES.WANTED.COUNTERS, artistId],
    queryFn: () => spotimatchEndpoints.getWantedCounters({ artistId }),
    enabled: !!artistId,
    retry: false,
  })

  const createArtist = async () => {
    await spotimatchEndpoints.createArtist({
      data: {
        id: artist.id,
        name: artist.name,
        image: artist?.images?.[0]?.url,
        popularity: artist?.popularity,
        followers: artist?.followers?.total,
        genres: artist?.genres,
      },
    })
  }

  useEffect(() => {
    if (isError && !isLoading && artist) {
      createArtist()
    }
  }, [isError, isLoading, artist])

  const classes = classNames({
    [className]: className,
  })

  if (isError) return null

  return (
    <div className={classes}>
      <div className="grid md:grid-cols-3 gap-4">
        <DashboardCard
          label="Totale Richieste"
          loading={isLoading}
          progress={
            <ProgressBar
              value={calculatePercentage(
                data?.totalVotes,
                data?.artistThreshold
              )}
              className="mt-2"
              variant="orange"
            />
          }
          value={`${formatNumber(data?.totalVotes)} / ${formatNumber(
            data?.artistThreshold
          )}`}
        />

        <DashboardCard
          label="Richieste questa settimana"
          loading={isLoading}
          variation={data?.weekPercentageIncrease}
          value={`${formatNumber(data?.lastWeekVotes)}`}
        />

        <DashboardCard
          label="Richieste questo mese"
          loading={isLoading}
          variation={data?.monthPercentageIncrease}
          value={`${formatNumber(data?.lastMonthVotes)}`}
        />
      </div>
    </div>
  )
}

export default WantedCounters
