import React from 'react'
import classNames from 'classnames'
import Icon from '@fnd/components/Icon'
import { DEFAULT_ICON_TYPE } from '@fnd/constants'
import Tooltip from 'rc-tooltip'

function Info({
  className,
  icon,
  iconType = DEFAULT_ICON_TYPE,
  label,
  small,
  vertical,
  value,
  labelClasses,
  valueClasses,
  tooltipText,
  children,
}) {
  const classes = classNames({
    info: true,
    'info-small': small,
    'info-vertical': vertical,
    [className]: className,
  })

  const labelClass = classNames({
    'info-label': true,
    [labelClasses]: labelClasses,
  })

  const valueClass = classNames({
    'info-value': true,
    [valueClasses]: valueClasses,
  })

  const InfoTooltip = ({ tooltipText }) => {
    return (
      <Tooltip
        mouseEnterDelay={0.5}
        mouseLeaveDelay={3}
        placement="top"
        trigger={['click', 'hover']}
        overlay={<span>{tooltipText}</span>}
      >
        <span className="ml-2">
          <Icon name="question-circle" />
        </span>
      </Tooltip>
    )
  }

  return (
    <div className={classes}>
      <span className={labelClass}>
        {icon && <Icon type={iconType} className="icon mr-2" name={icon} />}
        {label}
        {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
      </span>
      <span className={valueClass}>
        {value}
        {children}
      </span>
    </div>
  )
}

export default Info
