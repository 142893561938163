import React from 'react'
import Badge from '@fnd/components/Badge'
import { useIntl } from 'react-intl'
import useTrackFeedbackStore from '@fnd/store/useTrackFeedbackStore'

export const SubmissionFeedback = ({ rejectionReason }) => {
  const intl = useIntl()
  const { setShowFeedback, setRejectionReason } = useTrackFeedbackStore()
  return (
    <Badge
      small
      icon="times"
      label={intl.formatMessage({
        id: `playlist_queue.${rejectionReason}.label`,
      })}
      variant="red-light"
      onClick={() => {
        setRejectionReason(rejectionReason)
        setShowFeedback(true)
      }}
    />
  )
}

export default SubmissionFeedback
