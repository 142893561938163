export const calculatePushRange = (popularity) => {
  const MAX_PUSH_START = 10000 * 2
  const MAX_PUSH_END = 1500 * 2
  const MIN_PUSH_START = 15000 * 2
  const MIN_PUSH_END = 3000 * 2

  popularity = Math.min(Math.max(popularity, 0), 100)

  const lowPushCount =
    MAX_PUSH_START + (MAX_PUSH_END - MAX_PUSH_START) * (popularity / 100)
  const highPushCount =
    MIN_PUSH_START + (MIN_PUSH_END - MIN_PUSH_START) * (popularity / 100)

  return {
    low: Math.ceil(lowPushCount / 100) * 100,
    high: Math.ceil(highPushCount / 100) * 100,
  }
}

export const getPopularityColor = (popularity) => {
  if (popularity >= 80) return 'purple'
  if (popularity >= 70) return 'blue'
  if (popularity >= 50) return 'green'
  if (popularity >= 40) return 'orange'
  if (popularity >= 10) return 'yellow'
  return 'red'
}
