import { make } from './endpoints'

const getBlockedUsers = (pageNumber) =>
  make.get(`/blocked-users/${pageNumber}`).toPromise()
const checkIfUserIsBlocked = (spotifyId) =>
  make.get(`/blocked-users/check/${spotifyId}`).toPromise()

const blockUser = (spotifyId) =>
  make.post(`/blocked-users/block/${spotifyId}`).toPromise()

const unblockUser = (spotifyId) =>
  make.delete(`/blocked-users/unblock/${spotifyId}`).toPromise()

const checkIfArtistIsBlocked = (spotifyId) =>
  make.get(`/blocked-users/check-artist/${spotifyId}`).toPromise()

export default {
  getBlockedUsers,
  checkIfUserIsBlocked,
  checkIfArtistIsBlocked,
  blockUser,
  unblockUser,
}
