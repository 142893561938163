import React from 'react'
import classNames from 'classnames'

export const NotificationBadge = ({ count, limit = 99 }) => {
  if (count === 0) {
    return null
  }

  const classes = classNames({
    'notification-badge': true,
    'double-digit': count >= 10 && count < limit,
    'over-limit': count > limit,
  })

  return <div className={classes}>{count > limit ? `${limit}+` : count}</div>
}

export default NotificationBadge
