import React from 'react'
import parse from 'html-react-parser'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import 'dayjs/locale/es'
import 'dayjs/locale/it'

dayjs.extend(relativeTime)

import { useIntl } from 'react-intl'

export const NotificationItem = ({ notification }) => {
  const { content, type } = notification
  const intl = useIntl()
  const { locale } = intl

  const getIcon = () => {
    switch (type) {
      case 'accelerate':
        return 'rocket'
      case 'analysis':
        return 'chart-bar'
      case 'limit':
        return 'exclamation-triangle'
      case 'submission':
        return 'chart-line'
      case 'submission_accept':
        return 'check'
      case 'plan':
        return 'star'
      case 'push':
        return 'bell'
      case 'vip':
        return 'crown'
      default:
        return 'bell'
    }
  }

  const getTime = () => {
    return dayjs(notification.created_at).locale(locale).fromNow()
  }

  const NotificationCta = () => {
    if (notification.metadata && notification.metadata.cta) {
      return (
        <div className="notification-item-cta">
          <Button
            small
            icon="eye"
            variant="outline-primary"
            label={intl.formatMessage({ id: notification.metadata.cta.label })}
            className="notification-item-cta"
            to={notification.metadata.cta.url}
          />
        </div>
      )
    }
  }

  return (
    <div className="notification-item">
      <div className="notification-item-inner">
        <div className="notification-item-icon">
          <Icon name={getIcon()} />
        </div>
        <div className="notification-item-content">
          {parse(content)}
          <div className="notification-item-time mt-1">{getTime()}</div>
        </div>
      </div>
      <NotificationCta />
    </div>
  )
}

export default NotificationItem
