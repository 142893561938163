import dayjs from 'dayjs'

export const {
  MATCHFY_CONFIG: MODE,
  MATCHFY_SPOTIFY_REDIRECT_URL: SPOTIFY_REDIRECT_URL,
  MATCHFY_API_URL: API_URL,
  MATCHFY_SPOTIFY_API_URL,
  MATCHFY_APP_URL: APP_URL,

  MATCHFY_STRIPE_PUBLIC_KEY: STRIPE_PUBLIC_KEY,
  MATCHFY_STRIPE_SECRET_KEY: STRIPE_SECRET_KEY,
  MATCHFY_STRIPE_AGENCY_ID: STRIPE_AGENCY_ID,
  MATCHFY_STRIPE_ARTIST_ID: STRIPE_ARTIST_ID,
  MATCHFY_STRIPE_ANALYSIS_ID: STRIPE_ANALYSIS_ID,
  MATCHFY_STRIPE_PROFILE_FEATURED_ID: STRIPE_PROFILE_FEATURED_ID,
  MATCHFY_GOOGLE_MAPS_API_KEY: GOOGLE_MAPS_API_KEY,

  MATCHFY_IUBENDA_SITE_ID: IUBENDA_SITE_ID,
  MATCHFY_IUBENDA_COOKIE_POLICY_ID: IUBENDA_COOKIE_POLICY_ID,

  MATCHFY_INTERCOM_APP_ID: INTERCOM_APP_ID,
  MATCHFY_SENTRY_DSN: SENTRY_DSN,
  MATCHFY_HOTJAR_ID: HOTJAR_ID,
  MATCHFY_GA4_ID: GA4_ID,

  MATCHFY_GHOST_KEY: GHOST_KEY,

  MATCHFY_MAINTENANCE_MODE: MAINTENANCE_MODE,
} = import.meta.env

export const SPOTIFY_API_URL = MATCHFY_SPOTIFY_API_URL
export const DEVELOPMENT = MODE === 'development'
export const PRODUCTION = MODE === 'production'

export const MAINTENANCE = MAINTENANCE_MODE === 'true'

export const COMPANY_NAME = 'PlanetX'
export const VAT_NUMBER = '04551600986'

export const APP_NAME = 'Matchfy'
export const APP_THEME_COLOR = '#7866ff'

export const PLANS = {
  FREE: 'free',
  CURATOR: 'curator',
  ARTIST: 'artist',
  AGENCY: 'agency',
  VIP: 'vip',
}

export const USER_TYPES = {
  STAR: 'star',
}

export const PLAN_CONFIG = {
  free: {
    title: 'Curator / Promoter',
    icon: 'list-music',
    color: 'tertiary',
    slug: 'free',
  },
  artist: {
    title: 'Artist',
    icon: 'user-music',
    color: 'secondary',
    slug: 'artist',
  },
  agency: {
    title: 'Agency',
    icon: 'cloud-music',
    color: 'primary',
    slug: 'agency',
  },
  vip: {
    title: 'VIP',
    icon: 'gem',
    color: 'gold',
    slug: 'vip',
  },
}

export const PLAN_PRICES = {
  free: {
    price: 10,
    salePrice: 0,
  },
  artist: {
    price: 349,
    salePrice: 147,
    vipPrice: 47,
  },
  agency: {
    price: 1449,
    salePrice: 397,
    vipPrice: 197,
  },
  vip: {
    price: 50,
  },
}

export const STRIPE_PLAN_SUBSCRIPTIONS = {
  artist: STRIPE_ARTIST_ID,
  agency: STRIPE_AGENCY_ID,
}

export const STRIPE_PRODUCTS = {
  ANALYSIS: STRIPE_ANALYSIS_ID,
  PROFILE_FEATURED: STRIPE_PROFILE_FEATURED_ID,
}

export const STRIPE_CONFIG = {
  PUBLIC_KEY: STRIPE_PUBLIC_KEY,
  SECRET_KEY: STRIPE_SECRET_KEY,
  CURRENCY: 'eur',
}

export const EVENTS = {
  VERSION: 'version',
  USER_NEEDS_UPDATE: 'user_needs_update',
  ERROR_429: 'error_429',
}

export const MATCH_CONFIG = {
  ITEMS_RANGE: [5, 10],
  DELAY_RANGE: [300, 3000],
  SCORE_FETCH_THROTTLE: 500,
  PER_PAGE: 20,
  REFETCH_MAX_ITEMS: 350,
}

export const CB_BLOG_API_URL = 'https://blog.clockbeats.com/ghost/api/content'
export const MATCHFY_BLOG_API_URL = 'https://blog.matchfy.io/ghost/api/content'
export const MATCHFY_DISTRO_URL = 'https://distro.matchfy.io/albums'
export const SPOTIFY_AUTH_URL = 'https://accounts.spotify.com/authorize'
export const SPOTIFY_OPEN_CLIENT_URL = 'https://open.spotify.com'
export const SANITY_PROJECT_ID = 'bizc9bgc'
export const SANITY_API_URL = `https://${SANITY_PROJECT_ID}.api.sanity.io/v2023-03-07/data/query/production?query=`
export const SANITY_CDN_URL = `https://${SANITY_PROJECT_ID}.cdn.sanity.io`

export const IUBENDA_TERMS =
  'https://www.iubenda.com/api/terms-and-conditions/42480010/no-markup'
export const IUBENDA_PRIVACY =
  'https://www.iubenda.com/api/privacy-policy/42480010/no-markup'

export const IUBENDA_CONFIG = {
  lang: 'en',
  invalidateConsentWithoutLog: true,
  consentOnContinuedBrowsing: true,
  enableTcf: true,
  googleAdditionalConsentMode: true,
  perPurposeConsent: true,
  siteId: IUBENDA_SITE_ID,
  cookiePolicyId: IUBENDA_COOKIE_POLICY_ID,
  applyStyles: true,
  banner: {
    acceptButtonDisplay: true,
    customizeButtonDisplay: true,
    position: 'float-center',
    acceptButtonColor: '#5e4af9',
    backgroundOverlay: true,
    acceptButtonCaptionColor: 'white',
    customizeButtonColor: '#DADADA',
    customizeButtonCaptionColor: '#4D4D4D',
    textColor: '#fafafa',
    backgroundColor: '#1c1c1c',
    closeButtonDisplay: false,
    listPurposes: true,
  },
  callback: {},
}

export const INTERCOM_CONFIG = {
  app_id: INTERCOM_APP_ID,
  hide_default_launcher: true,
}

export const SPOTIFY_ID_LENGTH = 22

export const DEFAULT_CURATOR_PLAYLIST_URL = `${SPOTIFY_OPEN_CLIENT_URL}/playlist/03Evmy89ahKnAYkKGRt7Sk?si=_jl0TaalRiiHhuk7zyYyig`

export const TUTORIAL_URL = 'https://www.youtube.com/watch?v=JhvymWL_4wg'
export const TIKTOK_CODE_TUTORIAL_URL =
  'https://www.youtube.com/watch?v=BwlWcr76K1Y'
export const TIKTOK_SOUND_TUTORIAL_URL =
  'https://www.tiktok.com/music/OCCHI-LUCIDI-7306990675446450177'

export const DEBUG_REQUESTS = false

export const DEFAULT_ICON_TYPE = 'duotone'

export const CURRENCY = 'EUR'
export const CURRENCY_SYMBOL = '€'
export const VAT_AMOUNT = 22
export const CREDIT_DISCOUNT_PERCENTAGE = 0.8

export const DEFAULT_USER_CREDITS = 50

export const NOTIFICATION_CLEAR_TIMEOUT = 3000
export const NOTIFICATION_FETCH_TIMEOUT = 3 * 60 * 1000 // 3 minutes

export const USER_PROFILE_FETCH_TIMEOUT = 1 * 60 * 1000 // 1 minute

export const TRACK_USER_ACTIVITY_THROTTLE = 60000 // 1 minute
export const TRACK_USER_DASHBOARD_ANALYTICS_THROTTLE = 4 * 60 * 60 * 1000 // 4 hours
export const TRACK_USER_DASHBOARD_ANALYTICS_COOLDOWN = 10 * 60 * 1000 // 10 minutes

export const CHAT_MESSAGES_PER_PAGE = 50
export const CHAT_SYNC_INTERVAL = 10000 // 10 seconds

export const PAYMENT_REDIRECT_TIMEOUT = 3000

export const CURATOR_PROMOTION_MIN_WEEK = 1
export const CURATOR_PROMOTION_MAX_WEEK = 10
export const CURATOR_PROMOTION_COST_PER_WEEK = 10

export const CURATOR_RANGE = {
  HIGHEST: 0.8,
  HIGH: 0.6,
  MEDIUM: 0.4,
  LOW: 0.2,
  LOWEST: 0.1,
}

export const USER_ROYALTIES_PERCENTAGE = 0.95

export const MONTHLY_TRACK_PUSH_LIMIT = 3

export const ANALYSIS_TRACKS_LIMIT = 60
export const ANALYSIS_TRACKS_SLICES = 5
export const AFFILIATE_MAX_USAGE = 10
export const PROFILE_COMPLETE_BONUS = 5
export const PROFILE_UPGRADE_DISCOUNT = 70
export const GOOGLE_MAP_SCRIPT_BASE_URL =
  'https://maps.googleapis.com/maps/api/js'
export const VIP_CAMPAIGN_COMMISSION_PERCENTAGE = 5

export const CALENDLY_WIDGET_URL = 'https://calendly.com/alessia-matchfy/30min'

export const WHISTLEBLOWING_URL =
  'https://safespeak.io/report-form/9b01d7af-c6ff-4451-9b9b-7c1ca6e33d05'

export const MAGAZINE_PREVIEW_LIMIT = 8
export const TIKTOK_PREVIEW_LIMIT = 11
export const RADIO_PREVIEW_LIMIT = 20
export const PLAYLIST_FEATURED_PREVIEW_LIMIT = 20

export const AUTO_ACCEPT_MIN_MATCH_RATE = 65
export const AUTO_ACCEPT_MAX_MATCH_RATE = 95
export const QUERY_STALE_TIME = 1000 * 60 * 60 * 24 // 24 hours

export const TIKTOK_PROFILE_TYPES = {
  USER: 'user',
  AGENCY: 'agency',
}

export const DAILY_UPLOADED_SONGS = 100000
export const TRUSTPILOT_LINK = 'https://www.trustpilot.com/review/matchfy.io'

export const PLATFORM_LAUNCH_DATA = '2022-03-01'
export const DAYS_SINCE_LAUNCH = dayjs().diff(dayjs('2019-04-25'), 'day')
export const COUNTER_DURATION = 3
export const COUNTERS = {
  AGENCY_PLAN_COUNT: DAYS_SINCE_LAUNCH * 2,
  ARTIST_PLAN_COUNT: DAYS_SINCE_LAUNCH * 7,
  ACCELERATE: DAYS_SINCE_LAUNCH / 7,
  ARTISTS: 2000 + DAYS_SINCE_LAUNCH * 30,
  ANALYSIS: DAYS_SINCE_LAUNCH / 2,
  CURATORS: DAYS_SINCE_LAUNCH / 10,
  FAKE_PLAYLISTS: DAYS_SINCE_LAUNCH,
  PLAYS: DAYS_SINCE_LAUNCH * 500,
  PLAYLISTS: 7000000 + DAYS_SINCE_LAUNCH * 150,
  SUBMISSIONS: 5000000 + DAYS_SINCE_LAUNCH * 300,
}

export const MATCH_POSITION_OPTIONS = (intl) => [
  {
    label: intl.formatMessage({
      id: 'profile.account.settings.match.song_position.first',
    }),
    value: 'first',
  },
  {
    label: intl.formatMessage({
      id: 'profile.account.settings.match.song_position.last',
    }),
    value: 'last',
  },
]

export const DIGEST_FREQUENCY_OPTIONS = (intl) => [
  {
    label: intl.formatMessage({
      id: 'profile.account.settings.digest_frequency.always',
    }),
    value: 'always',
  },
  {
    label: intl.formatMessage({
      id: 'profile.account.settings.digest_frequency.daily',
    }),
    value: 'daily',
  },
  {
    label: intl.formatMessage({
      id: 'profile.account.settings.digest_frequency.weekly',
    }),
    value: 'weekly',
  },
  {
    label: intl.formatMessage({
      id: 'profile.account.settings.digest_frequency.never',
    }),
    value: 'never',
  },
]

export const SUBMISSION_FEEDBACK = [
  {
    id: 'not_matching_theme',
    icon: 'ban',
    iconColor: 'red',
    label: 'playlist_queue.not_matching_theme.label',
    description: 'playlist_queue.not_matching_theme.description',
  },
  {
    id: 'low_production_quality',
    icon: 'exclamation-triangle',
    iconColor: 'orange',
    label: 'playlist_queue.low_production_quality.label',
    description: 'playlist_queue.low_production_quality.description',
  },
  {
    id: 'popularity_appeal',
    icon: 'star',
    iconColor: 'gold',
    label: 'playlist_queue.popularity_appeal.label',
    description: 'playlist_queue.popularity_appeal.description',
  },
  {
    id: 'excessive_submissions',
    icon: 'inbox-in',
    iconColor: 'blue',
    label: 'playlist_queue.excessive_submissions.label',
    description: 'playlist_queue.excessive_submissions.description',
  },
  {
    id: 'lack_of_originality',
    icon: 'lightbulb',
    iconColor: 'green',
    label: 'playlist_queue.lack_of_originality.label',
    description: 'playlist_queue.lack_of_originality.description',
  },
]

export const PLAYLISTS_PER_PAGE = 20
export const SUBMISSIONS_PER_PAGE = 50
export const CURATOR_SUBMISSIONS_PER_PAGE = 10
export const PER_PAGE = 50
export const TABLE_PER_PAGE_OPTIONS = [20, 50, 100]
export const MAXIMUM_GENRES_PER_PLAYLIST = 10
export const MAXIMUM_GENRES_PER_MAGAZINE = 10
export const MAX_ITEMS_REPEATER_FIELD = 6
export const GENRES_QUERY_LIMIT = 10

export const QUERIES = {
  ANALYSIS: {
    GET: 'analysis.get'
  },
  AUTOCOMPLETE: 'autocomplete',
  CHAT: {
    COUNT: 'chat.count',
    LIST: 'chat.list'
  },
  MAGAZINE: {
    LIST: 'magazine.list'
  },
  PLAYLIST: {
    ANALYSIS: 'playlist.analysis',
    CHARTS: 'playlist.charts',
    GENRES: 'playlists.genres',
    GENRES_CHECK_UPDATE: 'playlists.genres_check_update',
    LIST: 'playlist.list',
    PUSHED: 'playlists.pushed',
    QUEUE: 'playlist.queue',
    SPOTIFY_DATA: 'playlist.spotify_data'
  },
  PLAYLIST_CHECK: {
    MONITORED: 'playlist_check.monitored'
  },
  PLAYLIST_FEATURED: {
    LIST: 'playlist_featured.list'
  },
  PROMO: {
    LIST: 'promo.list'
  },
  RADIO: {
    LIST: 'radio.list'
  },
  SPOTIFY: {
    LIST: 'spotify.list'
  },
  SUBMISSION: {
    POPULAR: 'submissions.popular'
  },
  TIKTOK: {
    LIST: 'tiktok.list'
  },
  TOKEN: {
    GET: 'token.get'
  },
  TRACK_PLAYS: {
    LIST: 'track_plays.list'
  },
  TRACK_PUSHED: {
    DETAIL: 'track_pushed.detail',
    LIST: 'track_pushed.list'
  },
  USER: {
    BLOCKED: 'user.blocked',
    STARS: 'user.stars'
  },
  VIP_CAMPAIGN: {
    DETAIL: 'vip_campaign.detail',
    LIST: 'vip_campaign.list'
  },
  WANTED: {
    COUNTERS: 'wanted.counters',
    LIST: 'wanted.list'
  }
}

export const BREAKPOINTS = {
  XS: 575,
  SM: 767,
  MD: 959,
  LG: 1279,
  XL: 1439,
}

export const WANTED_URL = 'https://wanted.live'
export const SPOTIFY_SUPPORT_URL =
  'https://support.spotify.com/us/contact-spotify-support/'
export const DISTRIBUTION_TUTORIAL_URL_IT =
  'https://www.youtube.com/embed/wFCoHQw_nlk'
export const DISTRIBUTION_TUTORIAL_URL_EN =
  'https://www.youtube.com/embed/6XmwkEw0jUw'
