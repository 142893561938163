import { make } from './endpoints'
import { PER_PAGE } from '@fnd/constants'

export const getWantedCounters = (params) => {
  const { artistId } = params
  return make.get(`/wanted/${artistId}/counters`).toPromise()
}

export const createArtist = (data) => {
  return make.post('/wanted/artist', { body: data }).toPromise()
}

export const getWantedList = (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { artistId, areaLevel } = params

  const query = {
    limit,
    offset,
  }

  if (areaLevel && areaLevel?.length > 0) {
    query.areaLevel = areaLevel
  }

  return make.get(`/wanted/${artistId}/list`, { query }).toPromise()
}

export default {
  createArtist,
  getWantedCounters,
  getWantedList,
}
