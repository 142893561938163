import React from 'react'
import {
  isUserAuthorizedSelector,
  userProfileSelector,
  useUserContext,
  userPlanSelector,
  isUserLoadingSelector,
} from '@fnd/modules/User'
import Empty from '@fnd/components/Empty'
import { useIntl } from 'react-intl'
import { SignButton } from '@fnd/components/Sign'
import AuthVerify from '@fnd/components/Auth/AuthVerify'
import Spinner from '@fnd/components/Spinner'
import classNames from 'classnames'

export default function Auth({
  children,
  className,
  fallback,
  emptyState = false,
  login = false,
  loginLabel,
  loginIcon,
  loginVariant = 'primary',
  loginClasses,
  redirect,
  plans,
  verify,
}) {
  const { profile, plan, isAuthorized, isUserLoading } = useUserContext(
    ({ user }) => ({
      profile: userProfileSelector(user),
      plan: userPlanSelector(user),
      isAuthorized: isUserAuthorizedSelector(user),
      isUserLoading: isUserLoadingSelector(user),
    })
  )

  const intl = useIntl()

  if (isAuthorized && !isUserLoading && !window.intercomSettings?.name) {
    window.intercomSettings = {
      ...window.intercomSettings,
      'Spotify ID': profile.spotify_id,
      frontend_version: profile.frontend_version,
      name: profile.display_name ?? profile.spotify_username ?? '',
    }
  }

  if (isUserLoading) {
    return <Spinner />
  }

  if (!isAuthorized && login && !fallback && !emptyState) {
    const loginProps = {
      label: loginLabel ?? intl.formatMessage({ id: 'home.main.start_now' }),
      icon: loginIcon,
      variant: loginVariant,
      className: loginClasses,
      redirect,
    }

    return <SignButton {...loginProps} />
  }

  if (!isAuthorized && !fallback && emptyState) {
    return (
      <Empty
        login
        icon="user-lock"
        className={classNames(className)}
        message={intl.formatMessage({ id: 'navigation.login_to_continue' })}
      />
    )
  }

  if (
    !isAuthorized ||
    (plans && plans.length > 0 && !plans.includes(plan?.name))
  ) {
    return fallback ? fallback : null
  }

  if (verify) {
    return <AuthVerify>{children}</AuthVerify>
  }

  return <>{children}</>
}
