import React from 'react'
import Badge from '@fnd/components/Badge'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import Modal, { ModalHeader } from '@fnd/components/Modal'
import Avatar from '@fnd/components/Avatar'
import { ConversationStart } from '@fnd/components/Chat'
import BlockUser from '@fnd/components/BlockUser'
import Button from '@fnd/components/Button'
import CuratorContacts from './CuratorContacts'
import { buildSpotifyUserUrl } from '@fnd/core/libs/platforms'
import { ROUTES } from '@fnd/screens/constants'

export function CuratorDetailPopup({ curator, className, handleModalClose }) {
  const intl = useIntl()

  const classes = classNames({
    'tiktok-detail': true,
    [className]: className,
  })

  const { id, display_name, avatar, contacts } = curator

  const refreshState = () => {
    if (handleModalClose) {
      handleModalClose()
    }
  }

  return (
    <Modal key={id} isOpen={true} onClose={() => handleModalClose()}>
      <ModalHeader title={display_name} onClose={() => handleModalClose()} />
      <div className="p-4">
        <div className={classes}>
          <div className="modal-detail-header">
            <Avatar
              src={avatar}
              alt={display_name}
              className="w-20 h-20 flex-shrink-0 rounded-full"
            />

            <div className="flex flex-col items-start w-full">
              <h3 className="text-xl sm:mt-2 sm:mb-1">{display_name}</h3>

              <div className="modal-detail-actions">
                <Badge
                  variant="light"
                  value={
                    <Button
                      variant="link-plain"
                      to={ROUTES.CURATOR_DETAIL.replace(':curatorId', id)}
                      icon="user"
                      target="_blank"
                      rel="noopener noreferrer"
                      label={intl.formatMessage({ id: 'navigation.profile' })}
                    />
                  }
                />

                <Badge
                  variant="light"
                  value={
                    <Button
                      variant="link-plain"
                      to={buildSpotifyUserUrl(id)}
                      target="_blank"
                      icon="spotify"
                      iconType="brands"
                      label={intl.formatMessage({ id: 'navigation.spotify' })}
                    />
                  }
                />

                <Badge
                  variant="light"
                  value={<ConversationStart inline userId={id} />}
                />

                <Badge
                  variant="light"
                  value={
                    <BlockUser
                      variant="link-plain"
                      blockedBtnClasses="text-primary"
                      icon="ban"
                      checkOnlineStatus
                      spotifyId={id}
                      showTitle={false}
                      showLabel={true}
                      callback={refreshState}
                    />
                  }
                />
              </div>
            </div>
          </div>

          <CuratorContacts contacts={contacts} />
        </div>
      </div>
    </Modal>
  )
}

export default CuratorDetailPopup
