import { make } from './endpoints'
import { PER_PAGE } from '@fnd/constants'

export const getTikToks = (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { genres, trackId, artistsId } = params
  return make
    .get(`/tiktok`, { query: { limit, offset, genres, trackId, artistsId } })
    .toPromise()
}

export const checkTikTokQueue = (tikTokId, trackId) =>
  make.get(`/tiktok/${tikTokId}/queue`, { query: { trackId } }).toPromise()

export const pushTikTokQueue = (tikTokId, trackId) =>
  make.post(`/tiktok/${tikTokId}/queue`, { body: { trackId } }).toPromise()

export default {
  getTikToks,
  checkTikTokQueue,
  pushTikTokQueue,
}
