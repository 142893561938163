import React from 'react'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import classNames from 'classnames'
import { ROUTES } from '@fnd/screens/constants'
import { PLANS } from '@fnd/constants'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { isUserAuthorizedSelector } from '@fnd/modules/User'

export function AccountUpgrade({
  plan,
  message,
  className,
  small = false,
  icon = 'user-lock',
  btnLabel = 'Upgrade',
  btnVariant = 'primary',
  btnClasses,
}) {
  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  const classes = classNames({
    'account-upgrade': true,
    [className]: className,
  })

  const buttonClasses = classNames({
    'mt-4': true,
    [btnClasses]: btnClasses,
  })

  return (
    <div className={classes}>
      <Empty login small={small} icon={icon} message={message}>
        {isAuthorized && (
          <Button
            variant={btnVariant}
            className={buttonClasses}
            to={`${ROUTES.PRICING}?plan=${plan ? plan : PLANS.ARTIST}`}
          >
            {btnLabel}
          </Button>
        )}
      </Empty>
    </div>
  )
}

export default AccountUpgrade
