import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useMetadataStore = create(
  persist(
    (set) => ({
      metadata: {
        utm_source: '',
        utm_medium: '',
        utm_campaign: '',
        gclid: '',
      },
      setMetadata: (metadata) => set({ metadata }),
    }),
    {
      name: 'metadata-storage',
    }
  )
)

export default useMetadataStore
