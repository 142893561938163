import AuthVerify from '@fnd/components/Auth/AuthVerify'
import { SectionHeader } from '@fnd/components/Section'
import className from 'classnames'
import { motion } from 'framer-motion'
import React from 'react'

export default function ProfileWrapper({
  image,
  title,
  breadcrumbs,
  actions,
  badge,
  subtitle,
  icon,
  sticky,
  children,
  verify,
  back = false,
  bodyClasses,
}) {
  const animations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  }

  const ProfileContent = () => {
    if (verify) {
      return <AuthVerify>{children}</AuthVerify>
    }
    return children
  }

  const profileBodyClasses = className({
    'profile-body': true,
    [bodyClasses]: bodyClasses,
  })

  return (
    <motion.div {...animations}>
      <SectionHeader
        back={back}
        title={title}
        badge={badge}
        breadcrumbs={breadcrumbs}
        actions={actions}
        subtitle={subtitle}
        image={image}
        icon={icon}
        sticky={sticky}
      />
      <div className={profileBodyClasses}>
        <ProfileContent />
      </div>
    </motion.div>
  )
}
