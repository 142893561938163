import React, { useState, useEffect } from 'react'
import { Modal } from '@fnd/components/Modal'
import Icon from '@fnd/components/Icon'
import { SUBMISSION_FEEDBACK } from '@fnd/constants'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import useTrackFeedbackStore from '@fnd/store/useTrackFeedbackStore'

export default function TrackFeedbackModal() {
  const intl = useIntl()
  const [feedback, setFeedback] = useState(null)
  const { showFeedback, setShowFeedback, rejectionReason } =
    useTrackFeedbackStore()

  useEffect(() => {
    const reason = SUBMISSION_FEEDBACK.find(
      (option) => option.id === rejectionReason
    )
    setFeedback(reason ?? null)
  }, [rejectionReason])

  if (!rejectionReason || !feedback) return null

  return (
    <Modal
      className="modal-xs modal-feedback"
      title={intl.formatMessage({ id: 'playlist_queue.feedback.title' })}
      isOpen={showFeedback}
      onClose={() => setShowFeedback(false)}
    >
      <div className="p-5 feedback-item">
        {feedback.icon && feedback.iconColor && (
          <Icon
            className={classNames({
              [`bg-${feedback.iconColor}-light`]: true,
              [`text-${feedback.iconColor}`]: true,
            })}
            name={feedback.icon}
          />
        )}

        <div className="flex flex-col">
          <h6>{intl.formatMessage({ id: feedback.label })}</h6>
          <p>{intl.formatMessage({ id: feedback.description })}</p>
        </div>
      </div>
    </Modal>
  )
}
