import React from 'react'
import classNames from 'classnames'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import { useTabs, TabLinks } from '@fnd/components/Tabs'
import WantedMap from './WantedMap'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useWantedStore } from '@fnd/store'
import { QUERIES, WANTED_URL } from '@fnd/constants'
import { useQuery } from '@tanstack/react-query'
import { formatNumber } from '@fnd/core/libs/currency'
import { useIntl } from 'react-intl'

export const areaLevels = {
  COUNTRY: 'COUNTRY',
  ADMINISTRATIVE_AREA_LEVEL_1: 'ADMINISTRATIVE_AREA_LEVEL_1',
  ADMINISTRATIVE_AREA_LEVEL_2: 'ADMINISTRATIVE_AREA_LEVEL_2',
  LOCALITY: 'LOCALITY',
  SUBLOCALITY: 'SUBLOCALITY',
}

export const WantedTable = ({ className }) => {
  const { artistId, areaLevel, setAreaLevel } = useWantedStore()
  const intl = useIntl()

  const filters = [
    {
      id: 'all',
      label: intl.formatMessage({ id: 'messages.all.female' }),
      onClick: () => setAreaLevel(),
    },
    {
      id: areaLevels.LOCALITY,
      label: intl.formatMessage({ id: 'messages.cities' }),
      onClick: () => setAreaLevel(areaLevels.LOCALITY),
    },
    {
      id: areaLevels.ADMINISTRATIVE_AREA_LEVEL_1,
      label: intl.formatMessage({ id: 'messages.regions' }),
      onClick: () => setAreaLevel(areaLevels.ADMINISTRATIVE_AREA_LEVEL_1),
    },
    {
      id: areaLevels.ADMINISTRATIVE_AREA_LEVEL_2,
      label: intl.formatMessage({ id: 'messages.provinces' }),
      onClick: () => setAreaLevel(areaLevels.ADMINISTRATIVE_AREA_LEVEL_2),
    },
    {
      id: areaLevels.COUNTRY,
      label: intl.formatMessage({ id: 'messages.countries' }),
      onClick: () => setAreaLevel(areaLevels.COUNTRY),
    },
  ]

  const filterTabs = {
    tabs: filters,
    initialTabId: areaLevel,
  }

  const tabFilters = useTabs(filterTabs)

  const {
    data: wantedList,
    isLoading,
    isError,
  } = useQuery({
    queryKey: [QUERIES.WANTED.LIST, artistId, areaLevel],
    queryFn: () => spotimatchEndpoints.getWantedList({ artistId, areaLevel }),
    enabled: !!artistId,
    retry: false,
  })

  const classes = classNames({
    'grid grid-cols-1 md:grid-cols-2 gap-4': true,
    [className]: className,
  })

  if (isError || (!isLoading && wantedList?.data?.length === 0)) {
    return (
      <Empty
        className="col-span-12 my-0"
        message={intl.formatMessage({ id: 'empty_state.no_wanted_data' })}
      >
        <Button
          to={`${WANTED_URL}?artistId=${artistId}`}
          target="_blank"
          rel="noopener noreferrer"
          label={intl.formatMessage({ id: 'empty_state.no_wanted_data.cta' })}
          className="mt-3 md:min-w-[180px]"
          variant="orange-light"
        />
      </Empty>
    )
  }

  return (
    <div className={classes}>
      <WantedMap
        className="rounded-lg border-outline overflow-hidden"
        locations={wantedList?.data}
      />
      <div className="flex flex-col gap-4">
        <TabLinks
          {...tabFilters.tabProps}
          inline
          variant="toggle"
          className="grid grid-cols-2 md:flex tab-light w-full"
          selectedTabIndex={
            areaLevel
              ? filters.findIndex((filter) => filter?.id === areaLevel)
              : 0
          }
          showIndicator={false}
          showHover={false}
        />

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>{intl.formatMessage({ id: 'messages.position' })}</th>
                <th>{intl.formatMessage({ id: 'messages.location' })}</th>
                <th>{intl.formatMessage({ id: 'messages.requests' })}</th>
              </tr>
            </thead>
            <tbody>
              {wantedList?.data?.map((item, index) => (
                <tr key={item?.id}>
                  <td>{index + 1}</td>
                  <td>{item?.name}</td>
                  <td>{formatNumber(item?.voteCount || 0)}</td>
                </tr>
              ))}

              {isLoading && (
                <tr>
                  <td colSpan={3}>
                    <Spinner inline className="py-3" />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default WantedTable
