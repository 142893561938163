import { CAMPAIGN_TYPOLOGY_OPTIONS } from './config'

export const getCampaignOptions = (intl) => {
  return CAMPAIGN_TYPOLOGY_OPTIONS(intl).reduce((acc, option) => {
    acc[option.value] = option
    return acc
  }, {})
}

export const getCampaignOption = (typology, intl) => {
  return getCampaignOptions(intl)[typology]
}

export const calculateSpotifyStreamRanges = (value, step, popularity) => {
  const popularityFactor = 0.5 + popularity / 100

  const getAdjustedRange = (min, max) => ({
    min: Math.round(min * popularityFactor),
    max: Math.round(max * popularityFactor),
  })

  if (value <= 200) {
    return getAdjustedRange(0, 2000)
  } else if (value <= 450) {
    return getAdjustedRange(500, 6000)
  } else if (value <= 950) {
    return getAdjustedRange(1000, 15000)
  } else if (value <= 1450) {
    return getAdjustedRange(3000, 30000)
  } else if (value <= 1950) {
    return getAdjustedRange(6000, 50000)
  } else if (value <= 2450) {
    return getAdjustedRange(10000, 70000)
  } else if (value <= 2950) {
    return getAdjustedRange(15000, 100000)
  } else {
    const baseAdditionalStreams = 30000 * Math.floor((value - 2950) / step)
    const adjustedAdditionalStreams = Math.round(
      baseAdditionalStreams * popularityFactor
    )
    const minStreams = Math.max(15000, Math.round(value * 5 * popularityFactor))
    return getAdjustedRange(minStreams, 100000 + adjustedAdditionalStreams)
  }
}
