import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useAffiliateStore = create(
  persist(
    (set, get) => ({
      affiliate: '',
      setAffiliate: (affiliate) => set({ affiliate }),
      canUseAffiliate: async () => {
        try {
          const { affiliate } = get()
          if (!affiliate?.code || affiliate?.code === '') return false
          return await spotimatchEndpoints.getAffiliateByCode(affiliate.code)
        } catch (error) {
          set({ affiliate: '' })
        }
      },
    }),
    {
      name: 'affiliate-storage',
    }
  )
)

export default useAffiliateStore
