import React from 'react'
import {
  userProfileSelector,
  isUserAuthorizedSelector,
  isUserLoadingSelector,
  useUserContext,
} from '@fnd/modules/User'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import { ROUTES } from '@fnd/screens/constants'

export const AuthVerify = ({ children, className }) => {
  const intl = useIntl()

  const { profile, isAuthorized } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
    isAuthorized: isUserAuthorizedSelector(user),
    isUserLoading: isUserLoadingSelector(user),
  }))

  if (!isAuthorized) {
    return (
      <Empty
        login
        icon="envelope-open-text"
        className={className ? className : ''}
        message={intl.formatMessage({ id: 'navigation.login_to_continue' })}
      />
    )
  }

  if (!profile.verified) {
    return (
      <Empty
        className={className ? className : ''}
        icon="envelope-open-text"
        message={<FormattedMessage id="confirm_email.lock" />}
      >
        <Button
          className="mt-4"
          to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.OVERVIEW}`}
        >
          <FormattedMessage id="confirm_email.cta" />
        </Button>
      </Empty>
    )
  }

  return <>{children}</>
}

export default AuthVerify
