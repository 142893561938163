import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import Button from '@fnd/components/Button'
import { usePlaylistStore } from '@fnd/store'
import { MAXIMUM_GENRES_PER_PLAYLIST } from '@fnd/constants'
import Genres from '@fnd/modules/Analysis/Search/Genres'
import Alert from '@fnd/components/Alert'

export const PlaylistSelectGenres = ({ className, closeModalFn }) => {
  const intl = useIntl()
  const [genres, setGenres] = useState([])
  const { currentPlaylist, genresManualUpdate } = usePlaylistStore()

  const handleGenresUpdate = async () => {
    try {
      await genresManualUpdate(genres)
      closeModalFn()
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    if (currentPlaylist?.genres.length > 0) {
      setGenres(currentPlaylist.genres)
    }
  }, [currentPlaylist])

  const classes = classNames({
    'field-input-container': true,
    'field-icon-before': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      {currentPlaylist?.genres.length === 0 && (
        <Alert className="mb-4">
          <FormattedMessage
            id="profile.my_playlists.settings_popup.settings.playlist_genres.disclaimer"
            values={{
              limit: MAXIMUM_GENRES_PER_PLAYLIST,
            }}
          />
        </Alert>
      )}

      <Genres
        label={intl.formatMessage({
          id: 'profile.my_playlists.settings_popup.settings.playlist_genres.select',
        })}
        className="w-full"
        genres={genres}
        maxLimit={MAXIMUM_GENRES_PER_PLAYLIST}
        onGenreSelect={(genres) => setGenres(genres)}
      />

      {currentPlaylist && currentPlaylist?.tags?.length > 0 && (
        <div className="w-full flex flex-col gap-2 mt-3">
          <span>
            {intl.formatMessage({
              id: 'profile.my_playlists.settings_popup.settings.playlist_genres.suggested_genres',
            })}
          </span>

          <div className="tags">
            {currentPlaylist?.tags.map((genre) => {
              return (
                <span
                  key={genre}
                  className="tag p-0 capitalize cursor-pointer"
                  onClick={() => setGenres([...genres, genre])}
                >
                  <span className="px-4">{genre}</span>
                </span>
              )
            })}
          </div>
        </div>
      )}

      <Button
        className="button-wide mt-3"
        disabled={genres.length === 0}
        onClick={handleGenresUpdate}
      >
        <FormattedMessage id="profile.my_playlists.settings_popup.settings.playlist_genres.save_genres" />
      </Button>
    </div>
  )
}

export default PlaylistSelectGenres
