import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { PLANS } from '@fnd/constants'
import Auth from '@fnd/components/Auth'
import { AccountUpgrade } from '@fnd/modules/User/Account/AccountUpgrade'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import WantedDashboard from '@fnd/components/Wanted'

export default function Wanted() {
  const intl = useIntl()

  return (
    <ProfileWrapper
      icon="id-badge"
      title={<FormattedMessage id="navigation.wanted" />}
    >
      <Auth
        login
        plans={[PLANS.ARTIST, PLANS.AGENCY]}
        fallback={
          <AccountUpgrade
            icon="music-slash"
            btnVariant="gradient"
            plan={PLANS.ARTIST}
            message={intl.formatMessage(
              { id: 'profile.upgrade.distribution' },
              {
                plan: PLANS.ARTIST,
              }
            )}
          />
        }
      >
        <WantedDashboard />
      </Auth>
    </ProfileWrapper>
  )
}
