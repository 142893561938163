import React, { useState, useEffect } from 'react'
import Button from '@fnd/components/Button'
import classNames from 'classnames'
import { MAX_ITEMS_REPEATER_FIELD } from '@fnd/constants'
import { useIntl } from 'react-intl'

export const FieldRepeater = ({
  title,
  label,
  buttonLabel = 'Add new contact',
  isDisabled,
  iconBefore,
  iconAfter,
  errors,
  onBlur,
  onChange,
  onFocus,
  containerClassName,
  fieldsConfig,
  maxItems = MAX_ITEMS_REPEATER_FIELD,
}) => {
  const initialValue = fieldsConfig.reduce(
    (acc, { name }) => ({ ...acc, [name]: '' }),
    {}
  )

  const [items, setItems] = useState([])
  const [error, setError] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    onChange(items)
  }, [items])

  const handleItemChange = (index, fieldName, value) => {
    setError(false)
    const newItems = [...items]
    newItems[index][fieldName] = value
    setItems(newItems)
  }

  const addItem = () => {
    if (
      items.some((item) =>
        fieldsConfig.some((field) => item[field.name] === '' && field.required)
      )
    ) {
      setError(intl.formatMessage({ id: 'field_repeater.empty_fields_error' }))
      return
    }

    if (items.length >= maxItems) {
      setError(
        intl.formatMessage(
          { id: 'field_repeater.max_items_error' },
          { maxItems }
        )
      )
      return
    }

    setItems([...items, initialValue])
  }

  const removeItem = (index) => {
    setError(false)
    const newItems = [...items]
    newItems.splice(index, 1)
    setItems(newItems)
  }

  const classes = classNames({
    [containerClassName]: containerClassName,
    'field-input-container': true,
    'field-icon-before': iconBefore,
    'field-icon-after': iconAfter,
    disabled: isDisabled,
  })

  return (
    <div className={classes}>
      {title && <h5>{title}</h5>}
      {label && <label>{label}</label>}

      <div className="space-y-5">
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <div
              className="flex flex-col gap-4 justify-start items-start"
              key={index}
            >
              <div className="grid grid-cols-7 gap-3 w-full">
                {fieldsConfig.map(({ name, placeholder, type, hint }) => (
                  <div
                    className="flex flex-col col-span-3"
                    key={`${index}-${name}`}
                  >
                    <div className="input-wrapper w-full">
                      <input
                        type={type}
                        value={item[name]}
                        name={name}
                        onChange={(e) =>
                          handleItemChange(index, name, e.target.value)
                        }
                        onBlur={onBlur}
                        onFocus={onFocus}
                        placeholder={placeholder}
                        disabled={isDisabled}
                      />

                      {iconBefore && (
                        <span className="emoticon-left">{iconBefore}</span>
                      )}

                      {iconAfter && (
                        <span className="emoticon-right">{iconAfter}</span>
                      )}
                    </div>
                    {hint && (
                      <div className="hints-container">
                        <p className="info-message m-0 mt-2">{hint}</p>
                      </div>
                    )}
                  </div>
                ))}

                <Button
                  key={`remove-${index}`}
                  type="button"
                  icon="trash"
                  variant="red-light"
                  disabled={isDisabled}
                  onClick={() => removeItem(index)}
                />
              </div>
            </div>
          ))}

        <div className="flex gap-4 items-center w-full">
          <Button
            type="button"
            icon="plus"
            label={buttonLabel}
            variant="primary"
            disabled={isDisabled}
            onClick={addItem}
          />
          {error && <p className="text-sm text-red-500">{error}</p>}
        </div>
      </div>

      {errors && Array.isArray(errors) && (
        <div className="errors-container">
          {errors.map((error) => (
            <p key={error} className="error-message">
              {error}
            </p>
          ))}
        </div>
      )}
    </div>
  )
}

export default FieldRepeater
