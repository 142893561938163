import React, { useCallback, useState } from 'react'
import ReactGA from 'react-ga4'
import { isEmpty } from 'lodash-es'
import Modal from 'react-modal'
import { generateSpotifySignLink } from '@fnd/core/spotify/endpoints'
import {
  isUserAuthorizedSelector,
  isUserLoadingSelector,
  useUserContext,
} from '@fnd/modules/User'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import { SPOTIFY_REDIRECT_URL } from '../../constants'
import Emoji from '../Emoji'
import { useAppStore } from '@fnd/store'
import classNames from 'classnames'
import { LogoIcon } from '@fnd/components/Logo/Logo'
import { ROUTES } from '@fnd/screens/constants'
import parse from 'html-react-parser'
import { useIntl } from 'react-intl'

function SignButton({
  className,
  variant,
  icon,
  label,
  redirect,
  type = 'web',
}) {
  const intl = useIntl()
  const { setRedirect } = useAppStore()
  const [isOpen, setIsOpen] = useState(false)
  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
    isLoading: isUserLoadingSelector(user),
    error: !isEmpty(user.error) ? user.error.message : void 0,
  }))

  const termsLabel = intl.formatMessage({ id: 'navigation.terms' })
  const termsLink = `<a href="${ROUTES.TERMS}" class="underline" target="_blank" rel="noopener noreferrer">${termsLabel}</a>`
  const privacyLabel = intl.formatMessage({ id: 'navigation.privacy' })
  const privacyLink = `<a href="${ROUTES.PRIVACY}" class="underline" target="_blank" rel="noopener noreferrer">${privacyLabel}</a>`

  const handleAuthorize = useCallback(() => {
    if (redirect) {
      setRedirect(redirect)
    } else {
      setRedirect(window.location.pathname)
    }

    ReactGA.event({
      category: 'Login',
      action: 'User Logged in',
    })

    window.location.href = generateSpotifySignLink(SPOTIFY_REDIRECT_URL, type)
    setIsOpen(false)
  }, [])

  if (isAuthorized) {
    return null
  }

  const classes = classNames({
    [className]: className,
  })

  return (
    <>
      <Button
        variant={variant}
        className={classes}
        onClick={() => setIsOpen(true)}
      >
        {!label && (
          <>
            <Emoji symbol="🤟" className="mr-1" aria-label="rock" />
            Login
          </>
        )}
        {icon && <Icon name={icon} className="mr-1" />}
        {label && label}
      </Button>

      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          shouldCloseOnOverlayClick
          overlayClassName="modal show"
          className="modal-dialog modal-center modal-xs"
        >
          <div className="modal-content">
            <div className="flex flex-col items-center justify-center py-8 px-5">
              <div className="flex flex-col gap-4 items-center justify-center mb-8">
                <LogoIcon className="w-24 h-24" />
                <h1 className="text-2xl md:text-3xl font-semibold text-center text-balance">
                  {intl.formatMessage({ id: 'messages.welcome_to_matchfy' })}
                </h1>
              </div>

              <Button large onClick={handleAuthorize}>
                {!label && (
                  <>
                    <Emoji symbol="🤟" className="mr-1" aria-label="rock" />
                    Login{' '}
                    <span className="with-spotify">
                      {intl.formatMessage({ id: 'messages.with_spotify' })}
                    </span>
                  </>
                )}
                {icon && <Icon name={icon} className="mr-1" />}
                {label && label}
              </Button>

              <p className="text-sm opacity-80 text-center text-balance max-w-[90%] mt-6">
                {parse(
                  intl.formatMessage(
                    { id: 'messages.login_terms' },
                    { termsLink, privacyLink }
                  )
                )}
              </p>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default SignButton

SignButton.defaultProps = {
  label: '',
  variant: 'outline',
  className: '',
}
