import { make } from './endpoints'

export const getVipCampaigns = () => make.get(`/vip-campaign`).toPromise()

export const getVipCampaign = (id) =>
  make.get(`/vip-campaign/${id}`).toPromise()

export const createVipCampaign = (payload) =>
  make.post(`/vip-campaign/`, { body: { payload } }).toPromise()

export const requestVipCampaign = (payload) =>
  make.post(`/vip-campaign/request`, { body: { payload } }).toPromise()

export const deleteVipCampaign = () => make.delete(`/vip-campaign/`).toPromise()

export default {
  createVipCampaign,
  deleteVipCampaign,
  getVipCampaign,
  getVipCampaigns,
  requestVipCampaign,
}
