import React from 'react'
import {
  ArchiveSubmission,
  AcceptSubmission,
  RemoveSubmission,
  RejectSubmission,
} from './Actions'

function PlaylistQueueActions({
  archived,
  playlistId,
  status,
  submitter,
  track,
  handled_by,
}) {
  const queueActionProps = {
    playlistId,
    track,
    submitter,
  }

  if (archived) {
    return null
  }

  return (
    <div className="table-actions">
      {status === 'pending' && (
        <>
          <AcceptSubmission {...queueActionProps} />
          <RejectSubmission {...queueActionProps} />
        </>
      )}

      {status === 'accepted' && (
        <>
          <RemoveSubmission {...queueActionProps} />
          <ArchiveSubmission {...queueActionProps} />
        </>
      )}

      {status === 'rejected' && (
        <>
          {handled_by === 'auto' && <AcceptSubmission {...queueActionProps} />}
          <ArchiveSubmission {...queueActionProps} />
        </>
      )}
    </div>
  )
}

export default PlaylistQueueActions
