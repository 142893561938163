import React from 'react'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import TracksPushedTable from '@fnd/modules/TrackQueue/TracksPushedTable'
import { FormattedMessage } from 'react-intl'

export default function MyActivity() {
  return (
    <ProfileWrapper
      icon="chart-line"
      title={<FormattedMessage id="navigation.my_activity" />}
    >
      <TracksPushedTable />
    </ProfileWrapper>
  )
}
