import React from 'react'
import Icon from '@fnd/components/Icon'
import classNames from 'classnames'

export function Variation({ value, invert, unit, className }) {
  const classes = classNames({
    badge: true,
    'badge-variation': true,
    'badge-positive': (value > 0 && !invert) || (value < 0 && invert),
    'badge-neutral': value === 0,
    'badge-negative': (value < 0 && !invert) || (value > 0 && invert),
    [className]: className,
  })

  const getIcon = () => {
    if (value > 0) {
      return 'arrow-up'
    } else if (value < 0) {
      return 'arrow-down'
    }
    return 'equals'
  }

  return (
    <div className={classes}>
      <Icon className="badge-icon" name={getIcon()} />
      {value && value}
      {unit && unit}
    </div>
  )
}

export default Variation

Variation.defaultProps = {
  invert: false,
  unit: '%',
}
