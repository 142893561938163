import React, { useEffect, useState } from 'react'
import { Client as ConversationsClient } from '@twilio/conversations'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Empty from '@fnd/components/Empty'
import {
  ConversationHeader,
  ConversationInput,
  ConversationMessages,
} from '@fnd/components/Chat'
import { CHAT_MESSAGES_PER_PAGE, QUERIES } from '@fnd/constants'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { useChatStore } from '@fnd/store/useChatStore'
import { toastFeedback } from '@fnd/core/libs/toast'
import { useQueryClient } from '@tanstack/react-query'

export const Conversation = ({ activeSid, mobile, user, className }) => {
  const [token, setToken] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [identity] = useState(user.spotify_id)
  const [totalMessages, setTotalMessages] = useState(0)

  const {
    conversations,
    getConversationMessages,
    setConversation,
    setConversationMessages,
    setLoadedConversations,
    updateConversationTimestamp,
    updateConversationUnread,
    syncConversations,
    success,
    error,
    resetFeedback,
  } = useChatStore()

  const queryClient = useQueryClient()
  const intl = useIntl()

  const fetchMessages = async () => {
    if (!conversations[activeSid]) return

    const currentMessages = getConversationMessages()
    const total = await conversations[activeSid].getMessagesCount()
    setTotalMessages(total)

    if (currentMessages && currentMessages.length >= total) {
      setHasMore(false)
      setIsLoading(false)
      return
    }

    setIsLoading(true)

    const anchor =
      currentMessages && currentMessages.length > 0
        ? currentMessages[0].index - 1
        : undefined

    const messages = await conversations[activeSid].getMessages(
      CHAT_MESSAGES_PER_PAGE,
      anchor,
      'backwards'
    )

    if (messages.items.length > 0) {
      messages.items[0].blockStart = true
      messages.items[messages.items.length - 1].blockEnd = true
    }

    setConversationMessages(activeSid, messages.items, 'prepend')
    setLoadedConversations(activeSid)

    await conversations[activeSid].setAllMessagesRead()

    queryClient.invalidateQueries(QUERIES.CHAT.COUNT)

    setIsLoading(false)
  }

  useEffect(() => {
    const fetchToken = async () => {
      const { token } = await spotimatchEndpoints.fetchToken(identity)
      setToken(token)
    }

    fetchToken()
  }, [identity])

  useEffect(() => {
    setHasMore(true)

    if (token) {
      const conversationsClient = new ConversationsClient(token)
      conversationsClient.on('stateChanged', async (state) => {
        if (state === 'initialized') {
          if (activeSid) {
            const chat = await conversationsClient.getConversationBySid(
              activeSid
            )
            setConversation(activeSid, chat)
            updateConversationUnread(activeSid)
            syncConversations()
          }
        }
      })
    }
  }, [activeSid, token])

  useEffect(() => {
    if (conversations[activeSid]) {
      fetchMessages()

      const handleMessageAdded = (message) => {
        setConversationMessages(activeSid, [message], 'append')
        updateConversationTimestamp(activeSid)
      }

      const handleConversationUpdated = async () => {
        syncConversations()
      }

      conversations[activeSid].on('messageAdded', handleMessageAdded)
      conversations[activeSid].on('updated', handleConversationUpdated)

      return () => {
        if (conversations[activeSid]) {
          conversations[activeSid].off('messageAdded', handleMessageAdded)
          conversations[activeSid].off('updated', handleConversationUpdated)
        }
      }
    }
  }, [activeSid, conversations])

  useEffect(() => {
    if (error && !isLoading) {
      toastFeedback('error', intl.formatMessage({ id: error }))
    } else if (!error && !isLoading && success) {
      toastFeedback('success', intl.formatMessage({ id: success }))
    }

    resetFeedback()
  }, [success, error])

  const handleLoadMore = async () => {
    if (isLoading || !hasMore) return

    await fetchMessages()
  }

  const classes = classNames({
    chat: true,
    [className]: className,
  })

  if (!activeSid)
    return (
      <div className={classes}>
        <Empty
          small
          ghost
          icon="inbox"
          className="h-full min-h-[50vh]"
          message={intl.formatMessage({ id: 'empty_state.no_active_chat' })}
        />
      </div>
    )

  return (
    <div className={classes}>
      <ConversationHeader mobile={mobile} />

      <ConversationMessages
        onLoadMore={handleLoadMore}
        isLoading={isLoading}
        messages={getConversationMessages()}
        mobile={mobile}
        total={totalMessages}
        user={user}
      />

      <ConversationInput mobile={mobile} />
    </div>
  )
}

export default Conversation
