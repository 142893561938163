import React, { Children, cloneElement } from 'react'
import classNames from 'classnames'

const Accordion = ({ children, className }) => {
  const classes = classNames({
    'accordion-wrap': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      {Children.map(children, (child) => {
        return cloneElement(child)
      })}
    </div>
  )
}

export default Accordion
