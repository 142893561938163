import React from 'react'
import { FormattedMessage } from 'react-intl'
import { NavLink } from 'react-router-dom'
import Icon from '@fnd/components/Icon'
import { ROUTES, PROFILE_SIDEBAR as routes } from '@fnd/screens/constants'

export default function ProfileNavMobile() {
  return (
    <ul className="nav-mobile">
      {routes.map(
        (link) =>
          link.show && (
            <li id={`nav-profile-${link.to}`} key={link.to}>
              <NavLink
                relative={ROUTES.MY_PROFILE.ROOT}
                className={({ isActive }) => (isActive ? ' active' : '')}
                to={link.to}
              >
                <Icon name={link.icon} />
                <FormattedMessage id={link.label} />
              </NavLink>
            </li>
          )
      )}
    </ul>
  )
}
