import React, { forwardRef } from 'react'
import classNames from 'classnames'
import ImageUpload from './ImageUpload'
import Select from './Select'
import Time from './Time'
import Icon from '@fnd/components/Icon'
import Slider from '@fnd/components/Slider'
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input'
import Tooltip from 'rc-tooltip'

export const FieldTooltip = ({ tooltipText }) => {
  return (
    <Tooltip
      mouseEnterDelay={0.5}
      mouseLeaveDelay={3}
      placement="top"
      trigger={['click', 'hover']}
      overlay={<span>{tooltipText}</span>}
    >
      <span className="ml-2">
        <Icon name="question-circle" />
      </span>
    </Tooltip>
  )
}

const InputField = ({
  type,
  fetchFn,
  maxLimit,
  multi,
  options,
  inputProps,
}) => {
  switch (type) {
    case 'phone':
      return <PhoneInput format={formatPhoneNumberIntl} {...inputProps} />
    case 'select':
      return (
        <Select
          fetchFn={fetchFn}
          maxLimit={maxLimit}
          multi={multi}
          options={options}
          {...inputProps}
        />
      )
    case 'slider':
      return <Slider {...inputProps} />
    case 'textarea':
      return (
        <textarea
          className={inputProps.className}
          {...inputProps}
          value={inputProps.value || ''}
        />
      )
    case 'time':
      return <Time {...inputProps} />
    case 'file':
      return <ImageUpload {...inputProps} />
    case 'text':
    default:
      return (
        <input className={inputProps.className} type={type} {...inputProps} />
      )
  }
}

const Field = forwardRef((props, ref) => {
  const {
    title,
    description,
    type = 'text',
    id,
    className,
    label,
    name,
    placeholder,
    value,
    isDisabled,
    iconBefore,
    iconAfter,
    errors,
    hints,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    onInput,
    tooltipText,
    prefix,
    suffix,
    fetchFn,
    maxLength,
    maxLimit,
    multi = false,
    options,
    ...rest
  } = props

  const classes = classNames({
    'field-input-container': true,
    'field-icon-after': iconAfter,
    'field-error': errors,
    [`field-${type}`]: true,
    disabled: isDisabled,
    [className]: className,
  })

  const prefixClasses = classNames({
    content: true,
    'content-prefix': true,
    'content-left': true,
    'has-prefix': iconBefore,
  })

  const suffixClasses = classNames({
    content: true,
    'content-suffix': true,
    'content-right': true,
    'has-suffix': iconAfter,
  })

  const inputClasses = classNames({
    'has-prefix': prefix || iconBefore,
    'has-suffix': suffix || iconAfter,
    'flex-grow': type === 'select',
  })

  const inputWrapperClasses = classNames({
    'input-wrapper': true,
    'overflow-visible':
      ['select'].includes(type) ||
      type === 'time' ||
      type === 'slider' ||
      type === 'file',
  })

  const inputProps = {
    id,
    value,
    name,
    onChange,
    onBlur,
    onFocus,
    onKeyDown,
    onInput,
    maxLength,
    placeholder,
    disabled: isDisabled,
    ref,
    ...rest,
  }

  return (
    <div className={classes}>
      {title && (
        <h5>
          {title}
          {tooltipText && <FieldTooltip tooltipText={tooltipText} />}
        </h5>
      )}

      {label && (
        <label htmlFor={id}>
          {label}
          {description && <p>{description}</p>}
          {tooltipText && !title && <FieldTooltip tooltipText={tooltipText} />}
        </label>
      )}

      <div className={inputWrapperClasses}>
        {iconBefore && (
          <span className="content content-emoticon">{iconBefore}</span>
        )}
        {prefix && <div className={prefixClasses}>{prefix}</div>}

        <InputField
          type={type}
          fetchFn={fetchFn}
          maxLimit={maxLimit}
          multi={multi}
          options={options}
          inputProps={{ ...inputProps, className: inputClasses }}
        />

        {suffix && <div className={suffixClasses}>{suffix}</div>}

        {iconAfter && (
          <span className="content content-right content-emoticon">
            {iconAfter}
          </span>
        )}
      </div>

      {hints && (
        <div className="hints-container">
          {hints.map((hint) => (
            <p key={hint} className="info-message">
              {hint}
            </p>
          ))}
        </div>
      )}

      {errors && Array.isArray(errors) && (
        <div className="errors-container">
          {errors.map((error) => (
            <p key={error} className="error-message">
              {error}
            </p>
          ))}
        </div>
      )}
    </div>
  )
})

export default Field
