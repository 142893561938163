import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import Button from '@fnd/components/Button'
import { motion, AnimatePresence } from 'framer-motion'
import { ModalHeader } from '@fnd/components/Modal'
import { FormattedMessage } from 'react-intl'
import { nanoid } from 'nanoid'
import classNames from 'classnames'

export default function ConfirmationPopup({
  isOpen,
  onReject,
  onConfirm,
  title,
  icon,
  text,
  overlayClose,
  confirmText,
  rejectText,
  children,
  className,
  bodyClasses,
  loading,
}) {
  const classes = classNames({
    'modal-info': true,
    [className]: className,
  })

  const modalBodyClasses = classNames({
    'modal-body': true,
    [bodyClasses]: bodyClasses,
  })

  const handleEscape = (event) => {
    if (event.keyCode === 27) {
      onReject()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscape)

    return () => {
      document.removeEventListener('keydown', handleEscape)
    }
  }, [])

  const handleConfirm = async () => {
    if (onConfirm) {
      await onConfirm()
    }
    document.body.classList.remove('lock-scroll')
  }

  useEffect(() => {
    if (isOpen) document.body.classList.add('lock-scroll')
    else document.body.classList.remove('lock-scroll')
  }, [isOpen])

  const ModalContent = () => {
    return ReactDOM.createPortal(
      <AnimatePresence mode="wait">
        {isOpen && (
          <motion.div
            id={`modal-${nanoid()}`}
            key={`modal-${nanoid()}`}
            className="modal-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="modal-overlay"
              onClick={overlayClose ? onReject : null}
            />
            <motion.div
              className={classes}
              initial={{ y: '100%' }}
              animate={{ y: '0%' }}
              exit={{ y: '100%' }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
              {title && (
                <ModalHeader icon={icon} title={title} onClose={onReject} />
              )}
              <div className={modalBodyClasses}>
                {text && <p>{text}</p>}
                {children}
              </div>
              <div className="modal-footer">
                <Button variant="inverse" onClick={onReject}>
                  {rejectText && rejectText}
                  {!rejectText && <FormattedMessage id="messages.cancel" />}
                </Button>
                <Button
                  onClick={() => handleConfirm()}
                  loading={loading}
                  disabled={loading}
                >
                  {confirmText && confirmText}
                  {!confirmText && <FormattedMessage id="messages.confirm" />}
                </Button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>,
      document.getElementById('root')
    )
  }

  return <ModalContent />
}
