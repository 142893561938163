import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from '@fnd/screens/constants'
import { FormattedMessage } from 'react-intl'

function CuratorPageButton({ isTrackInPlaylist, trackId }) {
  const activityPage = `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ACTIVITY}`
  const activityTrackPage = `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ACTIVITY_TRACK_DETAIL}`

  const activityTrackDetail = trackId
    ? activityTrackPage.replace(':trackId', trackId)
    : activityPage

  return (
    <div>
      <p>
        {isTrackInPlaylist ? (
          <span className="font-medium">
            <FormattedMessage id="match.playlist_popup.submit.track_already_in" />
          </span>
        ) : (
          <span>
            <FormattedMessage id="match.playlist_popup.submit.track_added" />
          </span>
        )}
      </p>

      <Link to={activityTrackDetail} className="button button-primary mt-3">
        <FormattedMessage id="match.playlist_popup.submit.see_button" />
      </Link>
    </div>
  )
}

export default CuratorPageButton
