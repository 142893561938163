import React, { useMemo, useState } from 'react'
import BillingInfoView from './BillingInfoView'
import BillingInfoEdit from './BillingInfoEdit'

const defaultBillingInfo = {
  firstName: '',
  lastName: '',
  companyName: '',
  country: '',
  city: '',
  address: '',
  provinceState: '',
  zipCode: '',
  vatNumber: '',
  pec: '',
  email: '',
  isVatCompany: false,
  codiceFiscale: '',
  codiceUnivoco: '',
}

export const getBillingInfo = (billingInfo) => {
  return !billingInfo
    ? defaultBillingInfo
    : { ...defaultBillingInfo, ...billingInfo }
}

export default function BillingInfo({
  mode,
  hideTitle,
  userProfile,
  updateUserBillingInfo,
}) {
  const billingInfo = useMemo(
    () => getBillingInfo(userProfile.billingInfo),
    [userProfile.billingInfo]
  )
  const [view, setView] = useState(mode)

  if (view === 'edit') {
    return (
      <BillingInfoEdit
        billingInfo={billingInfo}
        hideTitle={hideTitle}
        updateUserBillingInfo={updateUserBillingInfo}
        onSuccess={() => setView('view')}
        onCancel={() => setView('view')}
      />
    )
  }

  return (
    <BillingInfoView
      billingInfo={billingInfo}
      hideTitle={hideTitle}
      switchMode={() => setView('edit')}
    />
  )
}

BillingInfo.defaultProps = {
  hideTitle: false,
}
