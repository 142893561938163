import classNames from 'classnames'
import React from 'react'
import { DEFAULT_ICON_TYPE } from '@fnd/constants'
import { useIntl } from 'react-intl'

const Icon = (props) => {
  const { name, className, type, text } = props
  const intl = useIntl()
  const icon = `fa${type[0]} fa-fw fa-${name}`

  const classes = classNames({
    icon: true,
    [className]: className,
  })

  return (
    <span
      className={classes}
      data-hover={
        text.length &&
        intl.formatMessage({
          id: text,
        })
      }
    >
      <i className={icon} />
    </span>
  )
}

Icon.defaultProps = {
  className: '',
  name: '',
  text: '',
  type: DEFAULT_ICON_TYPE,
}

export default Icon
