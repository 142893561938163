import { make } from './endpoints'

export const getDownload = (id) => make.get(`downloads/${id}/view`)

export const getDownloads = (locale) => make.get(`downloads?locale=${locale}`)

export const getDownloadAttachment = (id) => make.get(`downloads/${id}/file`)

export default {
  getDownload,
  getDownloads,
  getDownloadAttachment,
}
