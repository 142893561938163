import React, { useState, useMemo } from 'react'
import Button from '@fnd/components/Button'
import { Modal } from '@fnd/components/Modal'
import Alert from '@fnd/components/Alert'
import Icon from '@fnd/components/Icon'
import { QUERIES, SUBMISSION_FEEDBACK } from '@fnd/constants'
import { TrackItem, trackImg } from '@fnd/components/PlaylistDetails/TrackItem'
import SubmitterInfo from './SubmitterInfo'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { toastFeedback } from '@fnd/core/libs/toast'
import { useQueueStore, useQueueFeedbackStore } from '@fnd/store'
import { useQueryClient } from '@tanstack/react-query'

export default function PlaylistQueueFeedback({ playlistId }) {
  const intl = useIntl()
  const { showFeedback, setShowFeedback, submitter, track } =
    useQueueFeedbackStore()

  const FeedbackOptions = useMemo(
    () => () => {
      const [feedback, setFeedback] = useState('not_matching_theme')
      const [loading, setLoading] = useState(false)
      const { removeTrackFromQueue, setLoadingTrackId, resetLoadingTrackId } =
        useQueueStore()

      const { submitter, setShowFeedback, track } = useQueueFeedbackStore()

      const intl = useIntl()

      const queryClient = useQueryClient()
      const queryKey = `${QUERIES.PLAYLIST.QUEUE}.${playlistId}`

      const handleFeedback = async () => {
        setLoading(true)
        setLoadingTrackId(track.id)

        try {
          await spotimatchEndpoints
            .trackQueueFeedback(playlistId, track.id, submitter.id, feedback)
            .toPromise()

          await removeTrackFromQueue(playlistId, track.id, feedback)

          queryClient.setQueryData([queryKey], (oldData) => {
            if (!oldData) return oldData

            const newPages = oldData.pages.map((page) => {
              const newQueue = page.data.map((submission) => {
                if (submission.track.id === track.id) {
                  return {
                    ...submission,
                    status: 'rejected',
                  }
                }

                return submission
              })

              return {
                ...page,
                data: newQueue,
              }
            })

            return {
              ...oldData,
              pages: newPages,
            }
          })
        } catch (error) {
          console.log(error)
          toastFeedback(
            'error',
            intl.formatMessage({ id: 'feedback.error.default' })
          )
        }

        setLoading(false)
        setShowFeedback(false)
        resetLoadingTrackId()
      }

      return (
        <>
          {SUBMISSION_FEEDBACK.map((option) => (
            <label
              className={classNames({
                'radio-button': true,
                active: feedback === option.id,
              })}
              htmlFor={option.id}
              key={option.id}
            >
              <input
                onChange={() => setFeedback(option.id)}
                type="radio"
                value={option.id}
                name="filter"
                id={option.id}
                checked={feedback === option.id}
              />

              {option.icon && option.iconColor && (
                <Icon
                  className={classNames({
                    [`bg-${option.iconColor}-light`]: true,
                    [`text-${option.iconColor}`]: true,
                  })}
                  name={option.icon}
                />
              )}

              <div className="flex flex-col">
                <h6>{intl.formatMessage({ id: option.label })}</h6>
                <p>{intl.formatMessage({ id: option.description })}</p>
              </div>
            </label>
          ))}

          <Alert className="block text-center mt-4 mb-4">
            {intl.formatMessage({ id: 'playlist_queue.feedback.notes' })}
          </Alert>

          <Button
            variant="gradient"
            className="w-full"
            loading={loading}
            onClick={() => handleFeedback()}
            label={intl.formatMessage({ id: 'playlist_queue.feedback.cta' })}
          />
        </>
      )
    },
    []
  )

  if (!track || !submitter) return null

  return (
    <Modal
      className="modal-md modal-feedback"
      title={intl.formatMessage({ id: 'playlist_queue.feedback.title' })}
      isOpen={showFeedback}
      onClose={() => setShowFeedback(false)}
    >
      <div className="p-5">
        <Alert variant="ghost mb-4">
          <SubmitterInfo submitter={submitter} />
        </Alert>

        <TrackItem
          className="mb-4"
          key={track.id}
          previewUrl={track.preview_url}
          name={track.name.substring(0, 40)}
          link={track.external_urls.spotify}
          artist={track.artists[0]}
          image={trackImg(track) ? trackImg(track).url : ''}
        />

        <FeedbackOptions />
      </div>
    </Modal>
  )
}
