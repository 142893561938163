export const CAMPAIGN_MANAGER_PRICE = 49
export const CAMPAIGN_ALGO_PRICE = 297
export const CAMPAIGN_ALGO_STEP = 250
export const CAMPAIGN_BOOST_PRICE = 49

export const CAMPAIGN_FORM_TYPOLOGIES = ['podcast', 'instagram']
export const CAMPAIGN_EXCLUSIVE_TYPOLOGIES = [
  'tiktokTalents',
  'instagram',
  'podcast',
]
export const CAMPAIGN_TYPOLOGY_WITH_TARGET = [
  'tiktok',
  'tiktokTalents',
  'youtube',
]

export const CAMPAIGN_TYPOLOGY_OPTIONS = (intl) => [
  {
    icon: 'spotify',
    iconType: 'brand',
    label: 'Spotify Playlist Promo',
    value: 'spotify',
    subtitle: intl.formatMessage({ id: 'vip_campaign.spotify.subtitle' }),
    enabled: true,
  },
  {
    icon: 'spotify',
    iconType: 'brand',
    label: 'Spotify Boost Promo',
    value: 'spotifyAlgo',
    subtitle: intl.formatMessage({ id: 'vip_campaign.spotify_algo.subtitle' }),
    new: true,
    enabled: false,
  },
  {
    icon: 'tiktok',
    iconType: 'brand',
    label: 'TikTok Ads',
    value: 'tiktok',
    subtitle: intl.formatMessage({ id: 'vip_campaign.tiktok.subtitle' }),
    enabled: true,
  },
  {
    icon: 'user-music',
    label: 'TikTok Talents',
    value: 'tiktokTalents',
    subtitle: intl.formatMessage({
      id: 'vip_campaign.tiktok_talents.subtitle',
    }),
    enabled: true,
  },
  {
    icon: 'youtube',
    iconType: 'brand',
    label: 'YouTube Ads',
    value: 'youtube',
    subtitle: intl.formatMessage({ id: 'vip_campaign.youtube.subtitle' }),
    enabled: true,
  },
  {
    icon: 'instagram',
    iconType: 'brand',
    label: 'Instagram',
    value: 'instagram',
    subtitle: intl.formatMessage({ id: 'vip_campaign.instagram.subtitle' }),
    enabled: true,
  },
  {
    icon: 'microphone',
    label: 'Podcast',
    value: 'podcast',
    subtitle: intl.formatMessage({ id: 'vip_campaign.podcast.subtitle' }),
    enabled: true,
  },
]

export const CAMPAIGN_AGE_OPTIONS = (intl) => [
  {
    label: intl.formatMessage({ id: 'messages.all.female' }),
    value: 'all',
  },
  {
    label: '18 - 24',
    value: '18-24',
  },
  {
    label: '25 - 34',
    value: '25-34',
  },
  {
    label: '35 - 44',
    value: '35-44',
  },
  {
    label: '45 - 54',
    value: '45-54',
  },
  {
    label: '55 - 64',
    value: '55-64',
  },
  {
    label: '65+',
    value: '65+',
  },
]

export const CAMPAIGN_GENDER_OPTIONS = (intl) => [
  {
    label: intl.formatMessage({ id: 'messages.all.male' }),
    value: 'all',
  },
  {
    label: intl.formatMessage({ id: 'messages.gender.male' }),
    value: 'male',
  },
  {
    label: intl.formatMessage({ id: 'messages.gender.female' }),
    value: 'female',
  },
]

export const CAMPAIGN_DURATION_OPTIONS = (intl) => [
  {
    label: `7 ${intl.formatMessage({ id: 'messages.days' })}`,
    value: 7,
  },
  {
    label: `14 ${intl.formatMessage({ id: 'messages.days' })}`,
    value: 14,
  },
  {
    label: `21 ${intl.formatMessage({ id: 'messages.days' })}`,
    value: 21,
  },
  {
    label: `30 ${intl.formatMessage({ id: 'messages.days' })}`,
    value: 30,
  },
]

export const CAMPAIGN_COUNTRY_OPTIONS = (intl) => [
  {
    label: intl.formatMessage({ id: 'country.it' }),
    value: 'it',
  },
  {
    label: intl.formatMessage({ id: 'country.uk' }),
    value: 'uk',
  },
  {
    label: intl.formatMessage({ id: 'country.de' }),
    value: 'de',
  },
  {
    label: intl.formatMessage({ id: 'country.fr' }),
    value: 'fr',
  },
  {
    label: intl.formatMessage({ id: 'country.es' }),
    value: 'es',
  },
  {
    label: intl.formatMessage({ id: 'country.us' }),
    value: 'us',
  },
  {
    label: intl.formatMessage({ id: 'country.south_america' }),
    value: 'south_america',
    subtitle: 'Coming soon',
    disabled: true,
  },
  {
    label: intl.formatMessage({ id: 'country.india' }),
    value: 'india',
    subtitle: 'Coming soon',
    disabled: true,
  },
  {
    label: intl.formatMessage({ id: 'country.africa' }),
    value: 'africa',
    subtitle: 'Coming soon',
    disabled: true,
  },
]

export const CAMPAIGN_BUDGET_OPTIONS = [
  {
    id: 'spotify',
    iconType: 'brand',
    icon: 'spotify',
    label: 'Spotify Playlist Promo',
    min: 50,
    step: 50,
    max: 10000,
  },
  {
    id: 'spotifyAlgo',
    iconType: 'brand',
    icon: 'spotify',
    label: 'Spotify Boost Promo',
    min: 250,
    step: 250,
    max: 5000,
  },
  {
    id: 'tiktok',
    iconType: 'brand',
    icon: 'tiktok',
    label: 'TikTok Ads',
    min: 50,
    step: 50,
    max: 10000,
  },
  {
    id: 'tiktokTalents',
    icon: 'user-music',
    label: 'TikTok Talents',
    hide: true,
  },
  {
    id: 'youtube',
    icon: 'youtube',
    iconType: 'brand',
    label: 'YouTube Ads',
    min: 50,
    step: 50,
    max: 10000,
  },
  {
    id: 'instagram',
    iconType: 'brand',
    icon: 'instagram',
    label: 'Instagram',
    min: 500,
    step: 100,
    max: 10000,
  },
  {
    id: 'podcast',
    icon: 'microphone',
    label: 'Podcast',
    min: 500,
    step: 100,
    max: 10000,
  },
]

export const TIKTOK_TALENTS_OPTIONS = [
  {
    label: '10 Talents',
    value: 10,
  },
  {
    label: '15 Talents',
    value: 15,
  },
  {
    label: '30 Talents',
    value: 30,
  },
  {
    label: '50 Talents',
    value: 50,
  },
  {
    label: '70 Talents',
    value: 70,
  },
  {
    label: '115 Talents',
    value: 115,
  },
  {
    label: '150 Talents',
    value: 150,
  },
]
