import React, { useMemo } from 'react'
import { useUserContext } from '@fnd/modules/User'
import { PlanPriceAnalysis, PlanPriceDefault, PlanPriceVip } from './prices'
import { useQuery } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'

const PricingPlanPrice = ({
  price,
  salePrice,
  save,
  layout,
  displayPrice,
  countdown,
}) => {
  const { user } = useUserContext()

  const {
    data: {
      availableCount: availableAnalysis,
      isArtistAnalysisFree: _isArtistAnalysisFree,
    },
  } = useQuery({
    queryKey: ['availableAnalysis'],
    queryFn: () => spotimatchEndpoints.getAvailableAnalysis(),
    initialData: { availableCount: 0, isArtistAnalysisFree: false },
    enabled: user.isAuthorized && layout === 'analysis',
  })

  const isArtistAnalysisFree = useMemo(() => {
    return user.isAuthorized && _isArtistAnalysisFree
  }, [user, _isArtistAnalysisFree])

  if (!displayPrice) {
    return <div className="plan-price"></div>
  }
  return (
    <div className="plan-price">
      {layout === 'default' && (
        <PlanPriceDefault
          price={price}
          salePrice={salePrice}
          countdown={countdown}
          duration="y"
          displayMonthly
          save={save}
        />
      )}

      {layout === 'vip' && <PlanPriceVip price={price} />}

      {layout === 'analysis' && (
        <PlanPriceAnalysis
          price={price}
          isArtistAnalysisFree={isArtistAnalysisFree}
          availableAnalysis={availableAnalysis}
        />
      )}
    </div>
  )
}

export default PricingPlanPrice
