import React from 'react'
import Avatar from '@fnd/components/Avatar'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Card from '@fnd/components/Card'
import { Placeholder } from '@fnd/components/Placeholder'
import { ProgressBar } from '@fnd/components/Progress'
import { ROUTES } from '@fnd/screens/constants'
import classNames from 'classnames'
import { useIntl } from 'react-intl'

function ArtistCard({ artist, className }) {
  const intl = useIntl()
  const artistUrl = `https://open.spotify.com/artist/${artist.id}`

  const classes = classNames({
    'border-outline bg-secondary py-3 px-5': true,
    [className]: className,
  })

  return (
    <Card className={classes} contentClasses="flex items-center gap-4">
      <div className="flex items-center gap-3">
        {artist.images.length ? (
          <a href={artistUrl} target="_blank" rel="noreferrer">
            <Avatar
              src={artist.images[0].url}
              alt={artist.name}
              className="avatar rounded-full"
            />
          </a>
        ) : (
          <Placeholder avatar className="w-14 h-14 p-0" icon="user-music" />
        )}

        <div className="flex flex-col">
          {artist.name && (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="font-medium"
              href={artistUrl}
            >
              {artist.name}
            </a>
          )}

          {artist.popularity && (
            <Badge
              variant="light"
              icon="star"
              className="mt-1"
              label={intl.formatMessage({ id: 'messages.popularity' })}
              value={`${artist.popularity}%`}
            />
          )}
        </div>
      </div>

      <Button
        variant="ghost"
        icon="cog"
        to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ARTISTS}`}
      />
    </Card>
  )
}

export default ArtistCard
