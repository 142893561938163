import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

const Wrapper = ({ className, children, id }) => {
  const classes = classNames({
    wrapper: true,
    'container mx-auto': true,
    [className]: className,
  })

  const animations = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  }

  return (
    <motion.div id={id} className={classes} {...animations}>
      {children}
    </motion.div>
  )
}

export default Wrapper
