import React, { useState, useEffect } from 'react'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import { usePlaylistStore } from '@fnd/store'
import { toastFeedback } from '@fnd/core/libs/toast'
import { useIntl } from 'react-intl'
import { ROUTES } from '@fnd/screens/constants'

export default function PlaylistAnalysisRequest({
  hasAnalysis,
  playlistId,
  watching,
}) {
  const { success, error, resetFeedback, toggleWatch } = usePlaylistStore()
  const [isLoading, setIsLoading] = useState(false)
  const intl = useIntl()

  const handleRequest = async () => {
    setIsLoading(true)
    resetFeedback()
    await toggleWatch(playlistId, watching)
    setIsLoading(false)
  }

  useEffect(() => {
    if (success) {
      toastFeedback('success', intl.formatMessage({ id: success }))
    } else if (error) {
      toastFeedback('error', intl.formatMessage({ id: error }))
    }
    setIsLoading(false)
  }, [success, error])

  return (
    <>
      {watching && !success && !error && !hasAnalysis && (
        <Alert variant="primary-light" className="text-center">
          <p className="md:text-lg">
            {intl.formatMessage({ id: 'playlist_check.request.info' })}
          </p>
        </Alert>
      )}

      {success && watching && (
        <Alert icon="check" variant="success" className="text-center">
          <p className="md:text-lg">
            {intl.formatMessage({ id: 'playlist_check.request.sent' })}
          </p>
        </Alert>
      )}

      {success && !watching && (
        <Alert icon="undo" variant="info" className="text-center">
          <p className="md:text-lg">
            {intl.formatMessage({ id: 'playlist_check.request.unsent' })}
          </p>
        </Alert>
      )}

      {error && (
        <Alert
          icon="exclamation-triangle"
          variant="danger"
          className="text-center"
        >
          <p className="md:text-lg">
            {intl.formatMessage({ id: 'playlist_check.request.error' })}
          </p>
        </Alert>
      )}

      <div className="flex flex-col gap-3">
        <Button
          icon={watching ? 'undo' : 'eye'}
          variant={watching ? 'light' : 'primary'}
          label={
            watching
              ? intl.formatMessage({ id: 'playlist_check.cta.unwatch' })
              : intl.formatMessage({ id: 'playlist_check.cta.watch' })
          }
          loading={isLoading}
          onClick={handleRequest}
        />

        {
          <Button
            variant="outline-primary"
            icon="arrow-right"
            to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.PLAYLIST_CHECK}?tab=monitored`}
            label={intl.formatMessage({ id: 'playlist_check.cta.profile' })}
          />
        }
      </div>
    </>
  )
}
