import React from 'react'
import { ProgressBar } from '@fnd/components/Progress'
import { useIntl } from 'react-intl'
import { getPopularityColor } from '@fnd/core/libs/popularity'
import classNames from 'classnames'

function TrackPopularityBar({ popularity, className }) {
  const intl = useIntl()

  const classes = classNames({
    [className]: className,
  })

  return (
    <div className={classes}>
      <ProgressBar
        variant={getPopularityColor(popularity)}
        label={intl.formatMessage({ id: 'messages.track_popularity' })}
        valueLabel={`${popularity}%`}
        max={100}
        value={popularity}
      />
    </div>
  )
}

export default TrackPopularityBar
