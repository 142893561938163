import React from 'react'
import { FormattedMessage } from 'react-intl'
import TransactionTable from '@fnd/modules/User/PaymentsHistory/Transactions/TransactionTable'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'

export default function PaymentsHistory({ userProfile }) {
  return (
    <ProfileWrapper
      icon="euro-sign"
      title={<FormattedMessage id="navigation.payments_history" />}
      subtitle={
        <FormattedMessage
          id="profile.payments_history.credit_transactions.balance"
          values={{
            creditsNumber: userProfile.referral.score,
            maxCreditsNumber: 500,
          }}
        />
      }
    >
      <TransactionTable className="mt-4" />
    </ProfileWrapper>
  )
}
