import { rgb } from 'd3-color'
import { interpolateHcl } from 'd3-interpolate'
import { scaleSequential } from 'd3-scale'

export const createColorScale = (colorHex, numberOfColors) => {
  const startColor = rgb(colorHex)

  const colorScale = scaleSequential(
    [0, numberOfColors - 1],
    interpolateHcl(startColor, '#fff')
  )

  const colors = []
  for (let i = 0; i < numberOfColors; i++) {
    colors.push(colorScale(i))
  }
  return colors
}

export const parseData = (data) => {
  return data.map((d) => {
    return {
      x: d.date,
      y: d.value,
    }
  })
}
