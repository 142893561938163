import React, { useState, useEffect } from 'react'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import { ProgressBar } from '@fnd/components/Progress'
import Separator from '@fnd/components/Separator'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { ROUTES } from '@fnd/screens/constants'
import { useIntl } from 'react-intl'
import { useAppStore } from '@fnd/store'

export default function ProfileCompletion() {
  const { profileComplete, setProfileComplete } = useAppStore()
  const [percentage, setPercentage] = useState()
  const [billing, setBilling] = useState(false)
  const intl = useIntl()

  const { profile, updateUser } = useUserContext(
    ({ user, updateUserProfile }) => ({
      profile: userProfileSelector(user),
      updateUser: (next) => updateUserProfile(next),
    })
  )

  const getProgressColor = (progress) => {
    if (progress >= 100) {
      return 'green'
    }

    if (progress >= 75) {
      return 'blue'
    }

    if (progress >= 50) {
      return 'yellow'
    }

    if (progress >= 25) {
      return 'orange'
    }

    return 'red'
  }

  const calculatePercentage = () => {
    let completeCount = 0

    Object.keys(profile.contacts).forEach((key) => {
      if (profile.contacts[key] && profile.contacts[key].length > 0) {
        completeCount++
      }
    })

    if (profile?.billingInfo) completeCount++

    return Math.round(
      (completeCount / Object.keys(profile.contacts).length) * 100
    )
  }

  const completeUserProfile = async () => {
    try {
      spotimatchEndpoints
        .completeProfile()
        .toPromise()
        .then((response) => {
          updateUser({
            referral: { $set: response.referral },
          })
        })
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const newPercentage = calculatePercentage()
    setPercentage(newPercentage)
    setBilling(profile?.billingInfo ? true : false)
  }, [profile])

  useEffect(() => {
    if (!profileComplete && percentage >= 100) {
      completeUserProfile()
      setProfileComplete(true)
    }
  }, [percentage])

  return (
    <>
      {!billing && (
        <Alert
          className="mb-8"
          cta={
            <Button
              small
              label={intl.formatMessage({
                id: 'profile_completion.billing.cta',
              })}
              to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.SETTINGS}/?tab=${ROUTES.MY_PROFILE.BILLING_INFO}`}
            />
          }
        >
          <h3 className="text-lg mb-1">
            {intl.formatMessage({ id: 'profile_completion.billing.title' })}
          </h3>
          <p className="font-light opacity-80">
            {intl.formatMessage({
              id: 'profile_completion.billing.description',
            })}
          </p>
        </Alert>
      )}

      <div>
        <h3 className="text-xl mb-1">
          {intl.formatMessage({ id: 'profile_completion.title' })}
        </h3>
        <p className="font-light mb-4">
          {intl.formatMessage({ id: 'profile_completion.description' })}
        </p>
        <ProgressBar
          compact
          valueLabel={`${percentage}%`}
          value={percentage}
          icon={percentage >= 100 ? 'check' : 'hourglass'}
          variant={getProgressColor(percentage)}
        />

        <Separator className="mt-6" />
      </div>
    </>
  )
}
