import React, { useEffect, useRef, useCallback } from 'react'
import { loadGoogleMapScript, isBrowser } from '@fnd/core/libs/helpers'
import { GOOGLE_MAP_SCRIPT_BASE_URL } from '@fnd/constants'

export function usePlaces(props) {
  const {
    ref,
    onPlaceSelected,
    apiKey,
    libraries = 'places',
    inputAutocompleteValue = 'new-password',
    options: {
      types = ['address'],
      componentRestrictions,
      fields = ['address_components', 'formatted_address'],
      bounds,
      ...options
    } = {},
    googleMapsScriptBaseUrl = GOOGLE_MAP_SCRIPT_BASE_URL,
    language,
  } = props
  const inputRef = useRef(null)
  const event = useRef(null)
  const autocompleteRef = useRef(null)
  const observerHack = useRef(null)
  const languageQueryParam = language ? `&language=${language}` : ''
  const googleMapsScriptUrl = `${googleMapsScriptBaseUrl}?libraries=${libraries}&key=${apiKey}${languageQueryParam}`

  const handleLoadScript = useCallback(
    () => loadGoogleMapScript(googleMapsScriptBaseUrl, googleMapsScriptUrl),
    [googleMapsScriptBaseUrl, googleMapsScriptUrl]
  )

  useEffect(() => {
    const config = {
      ...options,
      fields,
      types,
      bounds,
    }

    if (componentRestrictions) {
      config.componentRestrictions = componentRestrictions
    }

    if (autocompleteRef.current || !inputRef.current || !isBrowser) return

    if (ref && !ref.current) ref.current = inputRef.current

    const handleAutoComplete = () => {
      if (typeof google === 'undefined')
        return console.error(
          'Google has not been found. Make sure your provide apiKey prop.'
        )

      if (!window.google.maps?.places)
        return console.error('Google maps places API must be loaded.')

      if (!(inputRef.current instanceof HTMLInputElement))
        return console.error('Input ref must be HTMLInputElement.')

      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        config
      )

      if (autocompleteRef.current) {
        event.current = autocompleteRef.current.addListener(
          'place_changed',
          () => {
            const place = autocompleteRef.current.getPlace()
            if (!place?.address_components) return

            const addressObject = place.address_components.reduce(
              (acc, component) => {
                if (component.types.includes('street_number')) {
                  return { ...acc, streetNumber: component.long_name }
                }
                if (component.types.includes('route')) {
                  return { ...acc, street: component.long_name }
                }
                if (component.types.includes('locality')) {
                  return { ...acc, city: component.long_name }
                }
                if (component.types.includes('administrative_area_level_1')) {
                  return { ...acc, region: component.long_name }
                }
                if (component.types.includes('administrative_area_level_2')) {
                  return { ...acc, provinceState: component.short_name }
                }
                if (component.types.includes('country')) {
                  return { ...acc, country: component.short_name }
                }
                if (component.types.includes('postal_code')) {
                  return { ...acc, zipCode: component.long_name }
                }
                return acc
              },
              {}
            )

            if (onPlaceSelected && autocompleteRef && autocompleteRef.current) {
              onPlaceSelected(
                addressObject,
                place,
                inputRef.current,
                autocompleteRef.current
              )
            }
          }
        )
      }
    }

    if (apiKey) {
      handleLoadScript().then(() => handleAutoComplete())
    } else {
      handleAutoComplete()
    }

    return () => (event.current ? event.current.remove() : undefined)
  }, [])

  // Autofill workaround adapted from https://stackoverflow.com/questions/29931712/chrome-autofill-covers-autocomplete-for-google-maps-api-v3/49161445#49161445
  useEffect(() => {
    // TODO find out why react 18(strict mode) hangs the page loading
    if (
      !React?.version?.startsWith('18') &&
      isBrowser &&
      window.MutationObserver &&
      inputRef.current &&
      inputRef.current instanceof HTMLInputElement
    ) {
      observerHack.current = new MutationObserver(() => {
        observerHack.current.disconnect()

        if (inputRef.current) {
          inputRef.current.autocomplete = inputAutocompleteValue
        }
      })
      observerHack.current.observe(inputRef.current, {
        attributes: true,
        attributeFilter: ['autocomplete'],
      })
    }
  }, [inputAutocompleteValue])

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setFields(fields)
    }
  }, [fields])

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setBounds(bounds)
    }
  }, [bounds])

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setComponentRestrictions(componentRestrictions)
    }
  }, [componentRestrictions])

  useEffect(() => {
    if (autocompleteRef.current) {
      autocompleteRef.current.setOptions(options)
    }
  }, [options])

  return {
    ref: inputRef,
    autocompleteRef,
  }
}

export default usePlaces
