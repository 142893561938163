import React from 'react'
import Empty from '@fnd/components/Empty'
import { FormattedMessage } from 'react-intl'
import { Accordion, AccordionItem } from '@fnd/components/Accordion'
import TracksPushedTable from '@fnd/modules/TrackQueue/TracksPushedTable'
import classNames from 'classnames'
import TikTokTalentsResults from './Results/TikTokTalentsResults'
import VipCampaignResults from './Results/VipCampaignResults'

export default function VipCampaignReport({ campaign, className }) {
  const accordionData = [
    {
      id: 'youtube',
      title: 'YouTube',
      open: true,
      icon: 'youtube',
      iconType: 'brands',
      children: (
        <VipCampaignResults
          results={campaign?.results?.['youtube']}
          startDate={campaign?.created_at}
          endDate={campaign?.updated_at}
          status={campaign?.status}
        />
      ),
    },
    {
      id: 'tiktok',
      title: 'TikTok',
      open: true,
      icon: 'tiktok',
      iconType: 'brands',
      children: (
        <VipCampaignResults
          results={campaign?.results?.['tiktok']}
          startDate={campaign?.created_at}
          endDate={campaign?.updated_at}
          status={campaign?.status}
        />
      ),
    },
    {
      id: 'tiktokTalents',
      title: 'TikTok Talents',
      open: true,
      icon: 'user-music',
      children: (
        <TikTokTalentsResults
          results={campaign?.results?.['tiktokTalents']}
          startDate={campaign?.created_at}
          endDate={campaign?.updated_at}
          status={campaign?.status}
        />
      ),
    },
    {
      id: 'spotify',
      title: 'Spotify',
      open: true,
      icon: 'spotify',
      iconType: 'brands',
      children: <TracksPushedTable campaignId={campaign?._id} />,
    },
  ]

  const classes = classNames({
    [className]: className,
  })

  return (
    <div className={classes}>
      {campaign.status === 'pending' && (
        <Empty
          icon="hourglass"
          className="m-0"
          message={
            <FormattedMessage id="profile.vip_campaigns.report.pending" />
          }
        ></Empty>
      )}

      {campaign.status === 'paid' && (
        <Empty
          icon="check-circle"
          className="m-0"
          message={<FormattedMessage id="profile.vip_campaigns.report.paid" />}
        ></Empty>
      )}

      {(campaign.status === 'running' || campaign.status === 'ended') && (
        <div>
          <Accordion>
            {accordionData
              .filter((a) => campaign.typologies.includes(a.id))
              .map((item, index) => {
                return (
                  <AccordionItem
                    key={index}
                    label={item.title}
                    defaultOpen={item.open}
                    icon={item.icon}
                    iconType={item.iconType}
                  >
                    {item.children}
                  </AccordionItem>
                )
              })}
          </Accordion>
        </div>
      )}
    </div>
  )
}
