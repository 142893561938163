import React, { memo } from 'react'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import classNames from 'classnames'
import { useNavigate } from 'react-router-dom'

const SectionHeader = ({
  breadcrumbs,
  title,
  image,
  back = false,
  badge,
  subtitle,
  icon,
  actions,
  sticky,
  className,
}) => {
  const navigate = useNavigate()

  const classes = classNames({
    'section-header': true,
    'with-actions': actions,
    sticky: sticky,
    [className]: className,
  })

  return (
    <div className={classes}>
      {breadcrumbs && breadcrumbs}
      <div className="section-header-content">
        <div className="flex items-center gap-2">
          {back && (
            <Button
              small
              variant="ghost"
              className="mr-2"
              icon="arrow-left"
              onClick={() => navigate(-1)}
            ></Button>
          )}

          <h3 className="inline-flex items-center gap-2">
            {icon && !back && <Icon className="mr-2" name={icon} />}

            {image && (
              <div className="relative w-10 h-10 rounded overflow-hidden mr-2">
                <img
                  src={image}
                  alt={title}
                  className="absolute w-full h-full object-cover"
                />
              </div>
            )}

            {title}
          </h3>

          {subtitle && <h4>{subtitle}</h4>}

          {badge && badge}
        </div>

        {actions && <div className="section-header-actions">{actions}</div>}
      </div>
    </div>
  )
}

export default SectionHeader
