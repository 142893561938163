import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { CURRENCY_SYMBOL } from '@fnd/constants'

const PlanPriceVip = ({ price }) => {
  const intl = useIntl()

  return (
    <div className="flex flex-col">
      <p className="font-light opacity-75 mb-2">
        <FormattedMessage id="plans.card.start_from" />
      </p>
      <h3>
        {intl.formatMessage({ id: 'messages.from' })} {CURRENCY_SYMBOL}
        {price}
        {' / '}
        {intl.formatMessage({ id: 'search_select.track.label' })}
      </h3>
    </div>
  )
}

export default PlanPriceVip
