import React from 'react'
import { useIntl } from 'react-intl'
import Icon from '@fnd/components/Icon'
import Info from '@fnd/components/Info'
import { formatCurrency, formatNumber } from '@fnd/core/libs/currency'
import classNames from 'classnames'

const VipCampaignEstimate = ({
  campaign,
  className,
  impressions,
  showEstimate = false,
}) => {
  const intl = useIntl()
  const { locale } = intl

  const classes = classNames({
    [className]: className,
  })

  if (!showEstimate) return null

  return (
    <div className={classes}>
      {campaign?.typologies.includes('spotify') && (
        <>
          <Info
            small
            label={
              <span className="flex items-center gap-2">
                <Icon name="spotify" type="brands" />
                {intl.formatMessage({ id: 'vip_campaign.estimate.playlists' })}
              </span>
            }
            value={
              <span className="font-semibold">
                {formatNumber(impressions?.spotify?.playlists?.min, locale)}
                {' - '}
                {formatNumber(impressions?.spotify?.playlists?.max, locale)}
              </span>
            }
          />

          <Info
            small
            label={
              <span className="flex items-center gap-2">
                <Icon name="play" />
                Streams
              </span>
            }
            value={
              <span className="font-semibold">
                {formatNumber(impressions?.spotify?.streams?.min, locale)}
                {' - '}
                {formatNumber(impressions?.spotify?.streams?.max, locale)}
              </span>
            }
          />

          {campaign?.budget?.algo && (
            <Info
              small
              label={
                <span className="flex items-center gap-2">
                  <Icon name="spotify" type="brands" />
                  {intl.formatMessage({
                    id: 'vip_campaign.estimate.vip_plays',
                  })}
                </span>
              }
              value={
                <span className="font-semibold">
                  30 {intl.formatMessage({ id: 'messages.days' })}
                </span>
              }
            />
          )}
        </>
      )}

      {campaign?.typologies.includes('tiktok') && (
        <Info
          small
          label={
            <span className="flex items-center gap-2">
              <Icon name="tiktok" type="brands" />
              {intl.formatMessage({ id: 'vip_campaign.estimate.views' })}
            </span>
          }
          value={
            <span className="font-semibold">
              {formatNumber(impressions?.tiktok?.views?.min, locale)}
              {' - '}
              {formatNumber(impressions?.tiktok?.views?.max, locale)}
            </span>
          }
        />
      )}

      {campaign?.typologies.includes('tiktokTalents') && (
        <>
          <Info
            small
            label={
              <span className="flex items-center gap-2">
                <Icon name="tiktok" type="brands" />
                {intl.formatMessage({ id: 'vip_campaign.estimate.reach' })}
              </span>
            }
            value={
              <span className="font-semibold">
                {formatNumber(impressions?.tiktokTalents?.reach?.min, locale)}
                {' - '}
                {formatNumber(impressions?.tiktokTalents?.reach?.max, locale)}
              </span>
            }
          />

          <Info
            small
            label={
              <span className="flex items-center gap-2">
                <Icon name="tiktok" type="brands" />
                {intl.formatMessage({
                  id: 'vip_campaign.field.tiktokTalents.label',
                })}
              </span>
            }
            value={
              <span className="font-semibold">
                {formatNumber(impressions?.tiktokTalents?.talents, locale)}
              </span>
            }
          />

          <Info
            small
            label={
              <span className="flex items-center gap-2">
                <Icon name="tiktok" type="brands" />
                {intl.formatMessage({
                  id: 'vip_campaign.estimate.costPerTalent',
                })}
              </span>
            }
            value={
              <span className="font-semibold">
                {formatCurrency(
                  impressions?.tiktokTalents?.costPerTalent,
                  locale
                )}
              </span>
            }
          />
        </>
      )}

      {campaign?.typologies.includes('youtube') && (
        <Info
          small
          label={
            <span className="flex items-center gap-2">
              <Icon name="youtube" type="brands" />
              {intl.formatMessage({ id: 'vip_campaign.estimate.views' })}
            </span>
          }
          value={
            <span className="font-semibold">
              {formatNumber(impressions?.youtube?.views?.min, locale)}
              {' - '}
              {formatNumber(impressions?.youtube?.views?.max, locale)}
            </span>
          }
        />
      )}
    </div>
  )
}

export default VipCampaignEstimate
