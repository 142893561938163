import { make } from './endpoints'
import { PER_PAGE } from '@fnd/constants'

export const getPlaylistFeatured = (params) => {
  const page = params?.pageParam || 0
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt(page * limit)
  return make
    .get(`/playlist-featured`, { query: { limit, offset } })
    .toPromise()
}

export default {
  getPlaylistFeatured,
}
