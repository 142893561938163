import React, { useState, useEffect } from 'react'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import { TrackItem, trackImg } from './TrackItem'
import { usePlaylistStore } from '@fnd/store'
import { useIntl } from 'react-intl'

function TracksDetails({ tracks = [], playlistId }) {
  const [trackList, setTrackList] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { loadTracks } = usePlaylistStore()
  const intl = useIntl()

  const fetchTracks = async () => {
    if (playlistId) {
      const trackData = await loadTracks(playlistId)
      const tracks = trackData?.map((item) => item.track)
      setTrackList(tracks)
    }
  }

  useEffect(() => {
    if (tracks.length > 0) {
      setTrackList(tracks)
    } else if (playlistId) {
      fetchTracks()
    }

    setIsLoading(false)
  }, [playlistId])

  if (isLoading) return <Spinner />

  if (!trackList || trackList.length === 0) {
    return (
      <Empty
        icon="list-music"
        className="m-0"
        message={intl.formatMessage({ id: 'empty_state.no_track_found' })}
      />
    )
  }

  return (
    <div className="track-grid">
      {trackList && trackList.length > 0
        ? trackList.map((track, index) => {
            if (!track || !track?.id) return null

            return (
              <TrackItem
                key={`${track.id}${index}`}
                previewUrl={track?.preview_url}
                name={track?.name ? track.name.substring(0, 40) : ''}
                link={track?.external_urls?.spotify}
                artist={track?.artists?.[0]}
                image={trackImg(track) ? trackImg(track).url : ''}
              />
            )
          })
        : null}
    </div>
  )
}

export default TracksDetails
