import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { isEmpty } from 'lodash-es'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import { getMediaUrl } from '@fnd/core/libs/media'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '@fnd/screens/constants'
import { toastFeedback } from '@fnd/core/libs/toast'

function DownloadTable() {
  const [downloads, setDownloads] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { locale, formatMessage } = useIntl()

  const fetchDownloads = async () => {
    try {
      setIsLoading(true)
      const _downloads = await spotimatchEndpoints
        .getDownloads(locale)
        .toPromise()

      setDownloads(_downloads)
      setIsLoading(false)
    } catch (error) {
      setDownloads([])
      setIsLoading(false)
      toastFeedback('error', formatMessage({ id: error.response.data.message }))
    }
  }

  const handleDownloadFile = async (download) => {
    spotimatchEndpoints
      .getDownloadAttachment(download._id)
      .toPromise()
      .then(() => {
        const link = document.createElement('a')
        link.href = getMediaUrl(download.attachment)
        link.setAttribute('download', download.attachment.split('/').pop())
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    fetchDownloads()
  }, [locale])

  if (isLoading) {
    return <Spinner />
  }

  if (!isLoading && isEmpty(downloads)) {
    return <Empty />
  }

  return (
    <div className="table-responsive">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th className="w-1/12">#</th>
              <th className="w-3/4">
                <FormattedMessage id="profile.my_downloads.table.name" />
              </th>
              <th className="text-right"></th>
            </tr>
          </thead>
          <tbody>
            {downloads &&
              downloads.length > 0 &&
              downloads.map((download, index) => (
                <tr key={download._id}>
                  <td>{index + 1}</td>
                  <td>{download.title}</td>
                  <td className="text-right">
                    <div className="flex justify-end gap-2">
                      {download.attachment !== '' && (
                        <>
                          <Button
                            onClick={() => handleDownloadFile(download)}
                            variant="primary"
                            icon="download"
                          >
                            <FormattedMessage id="profile.my_downloads.table.download" />
                          </Button>
                        </>
                      )}
                      <Button
                        to={generatePath(
                          `/${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_DOWNLOAD_VIEW}`,
                          {
                            downloadId: download._id,
                          }
                        )}
                        variant="primary"
                        icon="eye"
                      >
                        <FormattedMessage id="profile.my_downloads.table.view" />
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default DownloadTable
