import { make } from './endpoints'
import { saveAs } from 'file-saver'
import { PER_PAGE } from '@fnd/constants'

export const getUserPlaylists = (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)

  return make
    .get(`/playlists/`, {
      query: { limit, offset },
    })
    .toPromise()
}

export const getPlaylistQueue = (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { playlistId, handled_by, status, archived } = params

  return make
    .get(`/playlists/${playlistId}/queue`, {
      query: { limit, offset, handled_by, status, archived },
    })
    .toPromise()
}

export const getPlaylistsFeatured = (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  const { genres, trackId, artistsId } = params

  return make
    .get(`/playlists/featured`, {
      query: { limit, offset, genres, trackId, artistsId },
    })
    .toPromise()
}

export const getPlaylistsPushed = (trackId) =>
  make.get(`/playlists/pushed`, { query: { trackId } })

export const checkQueueLimits = ({ playlistId, trackId, userId }) =>
  make.post(`/playlists/${playlistId}/queue`, { body: { trackId, userId } })

export const getFakeScore = (playlistId) => {
  return make.get(`/playlists/${playlistId}/fake-score`).toPromise()
}

export const putTrackIntoQueue = ({ playlistId, track, trackId }) =>
  make
    .put(`/playlists/${playlistId}/queue`, {
      body: {
        trackId,
        track,
      },
    })
    .toPromise()

export const addTrackToPlaylist = (playlistId, trackId, submitterId) =>
  make.post(`/playlists/${playlistId}/track/add`, {
    body: {
      trackId,
      submitterId,
    },
  })

export const removeTrackFromPlaylist = (playlistId, trackId, submitterId) =>
  make.post(`/playlists/${playlistId}/track/remove`, {
    body: {
      trackId,
      submitterId,
    },
  })

export const archiveTrackFromQueue = (playlistId, trackId) =>
  make.patch(`/playlists/${playlistId}/queue/${trackId}/archive`)

export const removeTrackFromQueue = (playlistId, trackId) =>
  make.patch(`/playlists/${playlistId}/queue/${trackId}/reject`)

export const trackQueueFeedback = (
  playlistId,
  trackId,
  submitterId,
  feedback
) =>
  make.post(`/playlists/${playlistId}/queue/${trackId}/feedback`, {
    body: {
      feedback,
      submitterId,
    },
  })

export const getTrackQueueStatus = (playlistId, trackId) =>
  make.get(`/playlists/${playlistId}/queue/${trackId}/status`)

export const deleteMissingPlaylist = (playlistId) =>
  make.delete(`playlists/${playlistId}`)

export const getPlaylistGenres = (playlistId) =>
  make.get(`playlists/${playlistId}/genres`)

export const genresUpdateRequest = (playlistId) =>
  make.put(`playlists/${playlistId}/genres-update`)

export const genresManualUpdate = (playlistId, genres) =>
  make.post(`playlists/${playlistId}/genres-update`, { body: { genres } })

export const checkGenresRecentlyUpdated = (playlistId) =>
  make.get(`playlists/${playlistId}/is-recently-updated`)

export const getExportPlaylists = () => make.get('playlists/export')

export const getExportPlaylistByOrderId = (orderId) =>
  make.get(`playlists/export/${orderId}`)

export const exportPlaylistsByIdsIntoCSV = (playlistIds, fromHistory = false) =>
  make
    .get('/playlists/export/csv', {
      query: { playlists: playlistIds.join(','), fromHistory },
      responseType: 'blob',
    })
    .toPromise()
    .then((response) => saveAs(response, 'playlists.csv'))

export const togglePlaylistVerified = (playlistId, isVerified) =>
  make.put(`/playlists/${playlistId}/verify`, { body: { isVerified } })

export const enableAutoSubmit = (playlistId, minMatchRate) =>
  make.put(`/playlists/${playlistId}/auto-submit/enable`, {
    body: { minMatchRate },
  })

export const disableAutoSubmit = (playlistId) =>
  make.put(`/playlists/${playlistId}/auto-submit/disable`)

export const createPlaylist = (playlistData) =>
  make.post('/playlists/create', { body: playlistData })

export const getMatchScore = (playlistId, trackId) =>
  make.get(`/playlists/${playlistId}/${trackId}/score`).toPromise()

export const fetchPlaylistData = () => make.put('/playlists/fetch')

export default {
  addTrackToPlaylist,
  archiveTrackFromQueue,
  checkGenresRecentlyUpdated,
  checkQueueLimits,
  createPlaylist,
  deleteMissingPlaylist,
  disableAutoSubmit,
  enableAutoSubmit,
  exportPlaylistsByIdsIntoCSV,
  fetchPlaylistData,
  genresManualUpdate,
  genresUpdateRequest,
  getExportPlaylistByOrderId,
  getExportPlaylists,
  getMatchScore,
  getFakeScore,
  getPlaylistGenres,
  getPlaylistQueue,
  getPlaylistsFeatured,
  getPlaylistsPushed,
  getTrackQueueStatus,
  getUserPlaylists,
  putTrackIntoQueue,
  removeTrackFromPlaylist,
  removeTrackFromQueue,
  togglePlaylistVerified,
  trackQueueFeedback,
}
