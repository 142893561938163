import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react'
import Spinner from '@fnd/components/Spinner'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import { GOOGLE_MAPS_API_KEY } from '@fnd/constants'
import { ThemeContext } from '@fnd/context/ThemeProvider'
import { themes } from '@fnd/core/libs/theme'
import {
  mapsLightTheme,
  mapsDarkTheme,
} from '@fnd/components/GoogleMaps/themes'
import { formatNumber } from '@fnd/core/libs/currency'
import { useIntl } from 'react-intl'

const mapContainerStyle = {
  width: '100%',
  height: '500px',
}

export const WantedMap = ({ className, locations }) => {
  const { currentTheme } = useContext(ThemeContext)
  const theme = themes.find((t) => t.id === currentTheme)

  const intl = useIntl()
  const [map, setMap] = useState(null)
  const [bounds, setBounds] = useState(null)
  const markersRef = useRef([])
  const markerClustererRef = useRef(null)

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  })

  const onLoad = useCallback((mapInstance) => {
    setMap(mapInstance)
  }, [])

  const createMarkers = useCallback(
    (mapInstance) => {
      if (!locations || locations?.length === 0) return
      const labelColor = currentTheme === 'dark' ? 'white' : '#1a1a1a'
      const bounds = new window.google.maps.LatLngBounds()

      markersRef.current.forEach((marker) => marker.setMap(null))
      markersRef.current = []

      if (markerClustererRef.current) {
        markerClustererRef.current.clearMarkers()
      }

      const markers = locations.map((location) => {
        const position = new window.google.maps.LatLng(
          location?.latitude,
          location?.longitude
        )
        const voteCount = location?.voteCount || 0
        bounds.extend(position)

        const marker = new window.google.maps.Marker({
          position,
          label: {
            text: String(voteCount),
            color: labelColor,
            fontSize: '12px',
            fontWeight: 'bold',
          },
        })

        const requestLabel =
          voteCount === 1
            ? intl.formatMessage({ id: 'messages.request' })
            : intl.formatMessage({ id: 'messages.requests' })

        const infoWindow = new window.google.maps.InfoWindow({
          content: `
        <div class="map-info-window">
          <strong>${location.name}</strong><br>
          ${formatNumber(voteCount)} ${requestLabel}
        </div>
      `,
        })

        marker.addListener('click', () => {
          infoWindow.open({
            anchor: marker,
            map: mapInstance,
            shouldFocus: false,
          })
        })

        markersRef.current.push(marker)
        return marker
      })

      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')
      const size = 40
      canvas.width = size
      canvas.height = size

      // Draw the circle
      ctx.fillStyle = theme?.palette?.wantedPrimary
      ctx.beginPath()
      ctx.arc(size / 2, size / 2, size / 2, 0, 2 * Math.PI)
      ctx.fill()

      // Convert the canvas to a data URL
      const iconUrl = canvas.toDataURL()

      // Create the custom renderer for the cluster
      const customClusterRenderer = {
        render: ({ markers, position }) => {
          const totalVotes = markers.reduce((acc, marker) => {
            const markerLabel = marker.getLabel().text
            return acc + parseInt(markerLabel, 10)
          }, 0)

          return new window.google.maps.Marker({
            position,
            label: {
              text: String(totalVotes),
              color: labelColor,
              fontSize: '16px',
              fontWeight: 'bold',
            },
            icon: {
              url: iconUrl,
              scaledSize: new window.google.maps.Size(size, size),
              anchor: new window.google.maps.Point(size / 2, size / 2),
            },
          })
        },
      }

      markerClustererRef.current = new MarkerClusterer({
        markers,
        map: mapInstance,
        renderer: customClusterRenderer,
      })

      setBounds(bounds)
    },
    [locations, currentTheme, intl, theme?.palette?.wantedPrimary]
  )

  useEffect(() => {
    if (map && isLoaded) {
      createMarkers(map)
    }
  }, [map, isLoaded, createMarkers, locations])

  useEffect(() => {
    if (map && bounds) {
      map.fitBounds(bounds)
    }
  }, [map, bounds])

  const mapOptions = useMemo(
    () => ({
      styles: currentTheme === 'dark' ? mapsDarkTheme : mapsLightTheme,
    }),
    [currentTheme]
  )

  if (!isLoaded)
    return (
      <div className="flex justify-center items-center h-full">
        <Spinner inline />
      </div>
    )

  return (
    <div className={className}>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        onLoad={onLoad}
        options={mapOptions}
      />
    </div>
  )
}

export default WantedMap
