import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { create } from 'zustand'
import { CHAT_SYNC_INTERVAL, DEVELOPMENT, QUERIES } from '@fnd/constants'
import debounce from 'lodash/debounce'

const updateConversationList = (currentList, newConversations) => {
  return newConversations.reduce(
    (acc, conversation) => {
      const existingIndex = acc.findIndex((c) => c.sid === conversation.sid)
      if (existingIndex > -1) {
        acc[existingIndex] = conversation
      } else {
        acc.push(conversation)
      }
      return acc
    },
    [...currentList]
  )
}

export const useChatStore = create((set, get) => ({
  activeSid: null,
  activeConversation: null,
  conversationList: [],
  archiveList: [],
  blockedList: [],
  conversations: {},
  conversationMessages: {},
  loadedConversations: [],
  updatedConversations: [],
  conversationTotal: 0,
  success: false,
  error: false,
  setActiveSid: (sid) => set({ activeSid: sid }),
  setActiveConversation: (conversation) =>
    set({ activeConversation: conversation }),
  setConversationTotal: (total) => set({ conversationTotal: total }),
  setConversationList: (conversations) => {
    const currentConversations = get().conversationList
    const updatedConversations = updateConversationList(
      currentConversations,
      conversations
    )
    set({ conversationList: updatedConversations })
  },
  setArchiveList: (conversations) => {
    const currentConversations = get().archiveList
    const updatedConversations = updateConversationList(
      currentConversations,
      conversations
    )
    set({ archiveList: updatedConversations })
  },
  setConversations: (conversations) => set({ conversations }),
  setConversation: (conversationSid, conversation) => {
    set((state) => ({
      conversations: {
        ...state.conversations,
        [conversationSid]: conversation,
      },
    }))
  },
  getActiveConversation: () => {
    if (!get().activeSid) return null
    const activeSid = get().activeSid
    return get().conversations[activeSid]
  },
  getConversationMessages: (conversationSid) => {
    if (!conversationSid) {
      const activeSid = get().activeSid
      return get().conversationMessages[activeSid]
    }

    return get().conversationMessages[conversationSid]
  },
  setConversationMessages: (conversationSid, messages, mode = 'prepend') => {
    const currentMessages = get().conversationMessages[conversationSid] || []
    const extractedMessages = get().extractMessagesData(messages)

    const updatedMessages =
      mode === 'prepend'
        ? [...extractedMessages, ...currentMessages]
        : [...currentMessages, ...extractedMessages]

    set((state) => ({
      conversationMessages: {
        ...state.conversationMessages,
        [conversationSid]: updatedMessages,
      },
    }))
  },
  setLoadedConversations: (conversationSid) => {
    if (get().loadedConversations.includes(conversationSid)) return

    set((state) => ({
      loadedConversations: [...state.loadedConversations, conversationSid],
    }))
  },
  resetConversation: () => {
    const conversationSid = get().activeSid
    set({ conversation: null })

    set((state) => {
      const messages = { ...state.conversationMessages }
      delete messages[conversationSid]

      return {
        conversationMessages: messages,
      }
    })
  },
  resetFeedback: () => set({ success: false, error: false }),
  extractMessagesData: (messages) => {
    return messages.map((msg) => ({
      sid: msg.sid,
      body: msg.body,
      author: msg.author,
      dateCreated: msg.dateCreated,
      attributes: msg.attributes,
      index: msg.index,
      blockStart: msg.blockStart,
      blockEnd: msg.blockEnd,
    }))
  },
  moveConversation: (sourceListName, destinationListName, conversationSid) => {
    const sourceList = get()[sourceListName]
    const destinationList = get()[destinationListName]
    const conversationIndex = sourceList.findIndex(
      (c) => c.sid === conversationSid
    )

    if (conversationIndex === -1) return

    const conversation = sourceList[conversationIndex]
    const updatedSourceList = sourceList.filter(
      (c) => c.sid !== conversationSid
    )
    const updatedDestinationList = updateConversationList(destinationList, [
      conversation,
    ])

    updatedDestinationList.sort(
      (a, b) => new Date(b.dateUpdated) - new Date(a.dateUpdated)
    )
    updatedSourceList.sort(
      (a, b) => new Date(b.dateUpdated) - new Date(a.dateUpdated)
    )

    set({
      [sourceListName]: updatedSourceList,
      [destinationListName]: updatedDestinationList,
    })
  },
  syncConversations: async () => {
    const updatedConversations = get().updatedConversations
    if (updatedConversations.length === 0) return

    const conversations = get().conversations
    const updateData = updatedConversations.map((chatSid) => {
      const conversation = conversations[chatSid]
      return {
        sid: conversation.sid,
        dateUpdated: conversation?.dateUpdated,
        lastMessage: conversation?.lastMessage?.index,
        lastReadMessageIndex: conversation?.lastReadMessageIndex,
      }
    })

    debounce(async () => {
      try {
        await spotimatchEndpoints.syncConversations(updateData)
      } catch (error) {
        console.error(error)
      }
    }, CHAT_SYNC_INTERVAL)()
  },
  updateConversationListDetails: (conversationSid, updateDetails) => {
    let list, listKey
    const conversationList = get().conversationList
    const archiveList = get().archiveList
    const updatedConversations = get().updatedConversations

    const listSearch = conversationList.find((c) => c.sid === conversationSid)
    const archiveSearch = archiveList.find((c) => c.sid === conversationSid)

    if (!listSearch && !archiveSearch) return

    if (listSearch) {
      list = conversationList
      listKey = 'conversationList'
    }

    if (archiveSearch) {
      list = archiveList
      listKey = 'archiveList'
    }

    const conversationIndex = list.findIndex((c) => c.sid === conversationSid)

    // Merge the existing conversation details with the update details
    list[conversationIndex] = {
      ...list[conversationIndex],
      ...updateDetails,
    }

    // Sort the conversation list by date updated
    list.sort((a, b) => new Date(b.dateUpdated) - new Date(a.dateUpdated))

    // Add conversation SID to updated conversations if not already present
    if (!updatedConversations.includes(conversationSid)) {
      updatedConversations.push(conversationSid)
    }

    set({ [listKey]: list, updatedConversations })
  },
  updateConversationTimestamp: async (conversationSid) => {
    get().updateConversationListDetails(conversationSid, {
      dateUpdated: new Date().toISOString(),
    })
  },
  updateConversationUnread: async (conversationSid, count = 0) => {
    get().updateConversationListDetails(conversationSid, {
      unreadCount: count,
    })
  },
  isConversationArchived: (conversationSid) => {
    const searchArchive = get().archiveList.find(
      (c) => c.sid === conversationSid
    )
    return !!searchArchive
  },
  archiveConversation: async (queryClient, conversationSid) => {
    try {
      const res = await spotimatchEndpoints.archiveConversation(conversationSid)

      if (res) {
        queryClient.invalidateQueries(QUERIES.CHAT.LIST)
        get().moveConversation(
          'conversationList',
          'archiveList',
          conversationSid
        )
        set(() => ({ success: 'feedback.success.chat_archive' }))
      }
    } catch (error) {
      console.error(error)
      set(() => ({ error: 'feedback.error.default' }))
    }
  },
  unarchiveConversation: async (queryClient, conversationSid) => {
    try {
      const res = await spotimatchEndpoints.unarchiveConversation(
        conversationSid
      )

      if (res) {
        queryClient.invalidateQueries(QUERIES.CHAT.LIST)
        get().moveConversation(
          'archiveList',
          'conversationList',
          conversationSid
        )
        set(() => ({ success: 'feedback.success.chat_unarchive' }))
      }
    } catch (error) {
      console.error(error)
      set(() => ({ error: 'feedback.error.default' }))
    }
  },
  isCurrentUserBlocked: () => {
    const activeConversation = get().activeConversation
    if (!activeConversation) return false

    const blockedUsers = get().blockedList
    return blockedUsers.includes(activeConversation.spotify_id)
  },
  blockUser: (userId) => {
    const blockedUsers = get().blockedList
    set(() => ({ blockedList: [...blockedUsers, userId] }))
  },
  unblockUser: (userId) => {
    const blockedUsers = get().blockedList
    set(() => ({ blockedList: blockedUsers.filter((id) => id !== userId) }))
  },
  deleteConversation: async (queryClient, conversationSid) => {
    if (!DEVELOPMENT) return

    try {
      const res = await spotimatchEndpoints.deleteConversation(conversationSid)

      if (res) {
        get().resetConversation()
        queryClient.invalidateQueries(QUERIES.CHAT.LIST)
        set(() => ({ success: 'feedback.success.delete' }))

        set((state) => {
          const messages = { ...state.conversationMessages }
          delete messages[conversationSid]

          return {
            conversationMessages: messages,
          }
        })
      }
    } catch (error) {
      console.error(error)
      set(() => ({ error: 'feedback.error.default' }))
    }
  },
}))

export default useChatStore
