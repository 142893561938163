import { create } from 'zustand'

export const useWantedStore = create((set) => ({
  artistId: null,
  areaLevel: null,
  setArtistId: (artistId) => set({ artistId }),
  setAreaLevel: (areaLevel) => set({ areaLevel }),
  artist: null,
  setArtist: (artist) => set({ artist }),
}))

export default useWantedStore
