import axios from 'axios'
import { from, of } from 'rxjs'
import { mergeMap, tap } from 'rxjs/operators'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import {
  API_URL,
  DEBUG_REQUESTS,
  EVENTS,
  PRODUCTION,
  SPOTIFY_API_URL,
  TRACK_USER_ACTIVITY_THROTTLE,
  TRACK_USER_DASHBOARD_ANALYTICS_THROTTLE,
} from '@fnd/constants'
import eventEmitter from './eventEmitter'
import serialize from './serialize'
import { spotifyStorage } from '../spotify'
import { throttle } from 'lodash'

export const refreshToken = () => {
  return axios
    .get(`${API_URL}/auth/refresh`, {
      headers: {
        'X-Type': 'web',
      },
    })
    .then((res) => {
      const { access_token: token, expires_at: expires } = res.data
      spotifyStorage.token = { token, expires }
      return token
    })
    .catch((err) => {
      spotimatchEndpoints
        .logout()
        .toPromise()
        .then(() => {
          spotifyStorage.clear()
          window.location.reload(false)
        })

      return Promise.reject(err)
    })
}

const trackOnlineTime = throttle(
  spotimatchEndpoints.trackOnlineTime,
  TRACK_USER_ACTIVITY_THROTTLE
)
const trackDashboardAnalytics = throttle(
  spotimatchEndpoints.trackDashboardAnalytics,
  TRACK_USER_DASHBOARD_ANALYTICS_THROTTLE
)

const URLS_WITHOUT_TRACKING_ENABLED = [
  'notifications/digest',
  'audit-logs/track/time-online',
  'audit-logs/track/dashboard-analytics',
  '/open',
  '/chat/count',
  'users/me',
]

axios.interceptors.request.use(
  async (config) => {
    if (config.baseURL !== SPOTIFY_API_URL) {
      const { state } = spotifyStorage
      const canTrackActivity = !URLS_WITHOUT_TRACKING_ENABLED.some((url) =>
        config.url.includes(url)
      )
      if (state && config.baseURL === API_URL && canTrackActivity) {
        trackOnlineTime()
        trackDashboardAnalytics()
      }

      return {
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${spotifyStorage.state}`,
        },
      }
    }

    const { expires } = spotifyStorage
    let { token } = spotifyStorage

    if (Date.now() > expires) {
      token = await refreshToken()
    }
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      },
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => {
    if (response.headers['x-version']) {
      eventEmitter.emit(EVENTS.VERSION, response.headers['x-version'])
    }

    if (response.headers['x-user-needs-update']) {
      eventEmitter.emit(EVENTS.USER_NEEDS_UPDATE)
    }

    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export const logger = (response) => {
  if (PRODUCTION || !DEBUG_REQUESTS) {
    return
  }

  console.group(
    `%cStatus ${response.status}`,
    `color: ${response.status > 200 ? 'red' : 'green'}`
  )
  console.log('URL:', response.config.url)
  console.log('headers:', response.headers)
  console.log('data:', response.data)
  console.groupEnd()
}

export default function request({
  uri = '',
  middleware = [],
  headers: _headers,
}) {
  const make =
    (method) =>
      (urn = '', params = {}) => {
        const { body, query, multipart = false } = params
        const queryString = query ? serialize(query) : ''
        const headers = {
          Accept: 'application/json',
          'Content-Type': multipart ? '' : 'application/json',
          ..._headers,
        }

        const options = {
          baseURL: uri,
          url: `${urn}${queryString}`,
          headers,
          ...params,
          method,
          data: body,
        }

        return of(options).pipe(
          mergeMap((config) => from(axios(config))),
          tap(logger),
          ...middleware
        )
      }

  return {
    get: make('GET'),
    put: make('PUT'),
    post: make('POST'),
    patch: make('PATCH'),
    delete: make('DELETE'),
  }
}
