import React, { useEffect, useState } from 'react'
import Badge from '@fnd/components/Badge'
import { PlanCard } from '@fnd/components/Plans'
import {
  isUserAuthorizedSelector,
  useUserContext,
  userProfileSelector,
} from '@fnd/modules/User'
import SectionTitle from '@fnd/components/SectionTitle'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSearchParams } from 'react-router-dom'
import { PLANS } from '@fnd/constants'
import { useQuery } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Spinner from '../Spinner'

const Plans = ({ className, showTitle = false, titleTag = 'h1' }) => {
  const intl = useIntl()
  const [searchParams] = useSearchParams()

  const { data, isLoading, isError } = useQuery({
    queryKey: ['plans'],
    queryFn: () => spotimatchEndpoints.getPlans(intl.locale),
  })

  const [defaultOpen, setDefaultOpen] = useState(
    data?.find((plan) => plan.defaultOpen)?.slug || null
  )

  useEffect(() => {
    const planParam = searchParams.get('plan')
    if (planParam) {
      setDefaultOpen(planParam)
    }
  }, [searchParams])

  const { isUserAuthorized } = useUserContext(
    ({ user, updateUserProfile }) => ({
      isUserAuthorized: isUserAuthorizedSelector(user),
      updateUser: (next) => updateUserProfile(next),
    })
  )

  const { plan } = useUserContext(({ user }) => userProfileSelector(user))

  const disablePlanDowngrade = (planName) => {
    const plans = Object.values(PLANS)

    if (typeof plan === 'undefined') return false
    return plans.indexOf(planName) <= plans.indexOf(plan.name)
  }

  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  const classes = classNames({
    [className]: className,
  })

  return (
    <div className={classes}>
      {showTitle && (
        <SectionTitle
          tag={titleTag}
          title={intl.formatMessage({ id: 'plans.header.title' })}
          subtitle={intl.formatMessage({ id: 'plans.page.subtitle' })}
          badge={
            <Badge
              variant="primary-light"
              icon="cloud-music"
              label={intl.formatMessage({ id: 'plans.page.title' })}
            />
          }
          className="mb-8"
          center
        />
      )}

      {isLoading && <Spinner />}
      {!isLoading && (
        <div className="plans-grid">
          {isError && <div>Error</div>}
          {data &&
          data.map((_plan) => (
            <>
              <PlanCard
                key={_plan.order}
                title={_plan.name}
                slug={_plan.slug}
                defaultOpen={defaultOpen === _plan.slug}
                icon={_plan.icon}
                color={_plan.colorClass}
                mostPopular={_plan.mostPopular}
                features={_plan.features}
                caption={_plan.caption}
                save={_plan.save}
                price={_plan.price}
                salePrice={_plan.salePrice}
                priceMonthly={_plan.priceMonthly}
                salePriceMonthly={_plan.salePriceMonthly}
                isActive={
                  (typeof plan !== 'undefined'
                    ? plan.name.toLowerCase()
                    : '') === _plan.slug
                }
                layout={_plan.slug === PLANS.VIP ? 'vip' : 'default'}
                isAuthorized={isAuthorized}
                disabled={!isUserAuthorized}
                displayMonthly
                displayPrice
                disabledDowngrade={disablePlanDowngrade(_plan.slug)}
                socialProof={
                  _plan.chooseFrom > 0 ? (
                    <FormattedMessage
                      id="plans.card.artist.social_proof"
                      values={{ plans: _plan.chooseFrom }}
                    />
                  ) : null
                }
              />
            </>
          ))}
        </div>
      )}
    </div>
  )
}

export default Plans
