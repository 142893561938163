import React, { useEffect, useState } from 'react'
import Modal from 'react-modal'
import classNames from 'classnames'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import { ModalHeader } from '@fnd/components/Modal'
import { FormattedMessage, useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import parse from 'html-react-parser'

const TikTokRequest = ({
  className,
  icon = 'external-link',
  variant = 'primary',
  small,
  triggerClasses,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState(null)
  const [isOpen, setIsOpen] = useState(false)
  const intl = useIntl()

  useEffect(() => {
    const fetchData = async () => {
      const data = await spotimatchEndpoints.getUserRequests()
      if (data.length > 0) {
        const request = data.find((request) => request.typology === 'tiktok')
        if (request) {
          setStatus(request.status)
        }
      }
    }

    const tiktokExists = async () => {
      const data = await spotimatchEndpoints.getRequestExists('tiktok')
      if (data && data.exists) {
        setStatus('exists')
      }
    }

    fetchData()
    tiktokExists()
  }, [])

  const classes = classNames({
    'payment-modal': true,
    [className]: className,
  })

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleRequestSend = async () => {
    try {
      setIsLoading(true)
      const data = await spotimatchEndpoints.sendRequest('tiktok')
      if (data) {
        setStatus(data.status)
      }
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      setStatus('exists')
    }
  }

  return (
    <div className={classes}>
      <Button
        small={small}
        className={triggerClasses ?? null}
        icon={icon}
        variant={variant}
        disabled={false}
        label={<FormattedMessage id="tiktok.publish.trigger_cta" />}
        onClick={() => setIsOpen(!isOpen)}
      />

      {isOpen ? (
        <Modal
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(!isOpen)}
          shouldCloseOnOverlayClick
          overlayClassName="modal show"
          className="modal-dialog modal-center modal-sm"
        >
          <div className="modal-content">
            <ModalHeader
              title={<FormattedMessage id="tiktok.publish.title" />}
              onClose={handleClose}
            />

            <div className="p-6 modal-body">
              <p className="mb-3">
                {parse(
                  intl.formatMessage({ id: 'tiktok.publish.description.1' })
                )}
              </p>
              <p>
                {parse(
                  intl.formatMessage({ id: 'tiktok.publish.description.2' })
                )}
              </p>

              {!status && (
                <Button
                  loading={isLoading}
                  className="mt-4"
                  variant="primary"
                  label={<FormattedMessage id="messages.send_request" />}
                  onClick={handleRequestSend}
                />
              )}

              {status === 'exists' && (
                <Alert variant="warning" className="mt-4">
                  <FormattedMessage id="tiktok.publish.exists" />
                </Alert>
              )}

              {status === 'pending' && (
                <Alert variant="warning" className="mt-4">
                  <FormattedMessage id="tiktok.publish.pending" />
                </Alert>
              )}

              {status === 'rejected' && (
                <Alert variant="danger" className="mt-4">
                  <FormattedMessage id="tiktok.publish.rejected" />
                </Alert>
              )}

              {status === 'approved' && (
                <Alert variant="success" className="mt-4">
                  <FormattedMessage id="tiktok.publish.approved" />
                </Alert>
              )}
            </div>
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

TikTokRequest.defaultProps = {
  className: '',
  small: false,
}

export default TikTokRequest
