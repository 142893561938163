import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { spotifyEndpoints } from '@fnd/core/spotify'
import dayjs from 'dayjs'
import Icon from '@fnd/components/Icon'
import { Reorder } from 'framer-motion'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { isVerifiedUserSelector } from '@fnd/modules/User'
import Button from '@fnd/components/Button'
import ConfirmationPopup from '@fnd/components/ConfirmationPopup'
import { Accordion, AccordionItem } from '@fnd/components/Accordion'
import MyPlaylistAddTrackForm from './MyPlaylistAddTrackForm'
import MyPlaylistDetailsForm from './MyPlaylistDetailsForm'
import Spinner from '@fnd/components/Spinner'
import TrackPlayer from '@fnd/components/Track/'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import { toastFeedback } from '@fnd/core/libs/toast'
import { getDateFormat } from '@fnd/core/libs/helpers'
import { useIntl } from 'react-intl'

const formatDuration = (millis) => {
  const minutes = Math.floor(millis / 60000)
  const seconds = ((millis % 60000) / 1000).toFixed(0)
  return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
}

const TrackItem = ({ track, addedAt, index, action }) => {
  const intl = useIntl()
  const { locale } = intl
  const image = track?.album?.images?.pop()?.url || ''

  return (
    <>
      <td>
        <TrackPlayer cover={image} audioSrc={track.preview_url} />
      </td>
      <td>{index}</td>
      <td>{track.name}</td>
      <td>{track.album.name}</td>
      <td>{track.artists.map(({ name }) => name).join(', ')}</td>
      <td>{formatDuration(track.duration_ms)}</td>
      <td>{track.popularity}%</td>
      <td>{dayjs(addedAt).format(getDateFormat(locale))}</td>
      <td>{action && <Button icon="trash-alt" onClick={action} />}</td>
    </>
  )
}

const useReceivePlaylistDetails = (playlistId) => {
  const [playlistState, setPlaylistState] = useState({
    isLoading: true,
    playlist: {},
  })
  const patch = (next) =>
    setPlaylistState((currentState) => ({ ...currentState, ...next }))
  useEffect(() => {
    patch({ isLoading: true })
    spotifyEndpoints
      .getPlaylistWithTracks(playlistId)
      .toPromise()
      .then((playlist) => playlist && patch({ playlist, isLoading: false }))
  }, [playlistId])

  return [playlistState, patch]
}

export default function MyPlaylistEdit() {
  const intl = useIntl()
  const { playlistId } = useParams()
  const [sourceIndex, setSourceIndex] = useState()
  const [destIndex, setDestIndex] = useState()
  const [active, setActive] = useState()
  const [{ playlist, isLoading }, updatePlaylistState] =
    useReceivePlaylistDetails(playlistId)
  const [tracks, setTracks] = useState([])
  const [confirmationPopupCallback, setConfirmationPopup] = useState(null)
  const closeConfirmationPopup = () => setConfirmationPopup(null)
  const openConfirmationPopup = (callback) =>
    setConfirmationPopup(() => callback)

  const { isVerifiedUser } = useUserContext(({ user }) => ({
    isVerifiedUser: isVerifiedUserSelector(user),
  }))
  const [isAddTrackFormVisible, setTrackFormVisible] = useState(false)
  const showAddTrackForm = () => setTrackFormVisible(true)
  const hideAddTrackForm = () => setTrackFormVisible(false)

  useEffect(() => {
    if (
      tracks.length === 0 &&
      playlist.tracks &&
      playlist.tracks.items.length > 0
    ) {
      setTracks(playlist.tracks.items)
    }
  }, [playlist, isLoading])

  const handleTrackReorder = async (newOrder) => {
    if (!sourceIndex) {
      setSourceIndex(tracks.findIndex((track) => track.track.id === active))
    }

    setDestIndex(newOrder.findIndex((trackId) => trackId === active))

    const items = newOrder.map((id) => {
      return tracks.find((track) => track.track.id === id)
    })

    setTracks(items)

    updatePlaylistState({
      playlist: {
        ...playlist,
        tracks: items,
      },
    })
  }

  const handleAddTrack = async (track) => {
    try {
      await spotifyEndpoints
        .addTracksToPlaylist(playlistId, [track.id])
        .toPromise()

      const newTrack = {
        track,
        added_at: dayjs().toISOString(),
      }

      const updatedTrackList = [...playlist.tracks.items, newTrack]

      updatePlaylistState({
        playlist: {
          ...playlist,
          tracks: {
            ...playlist.tracks,
            items: updatedTrackList,
          },
        },
      })

      setTracks([...tracks, newTrack])

      toastFeedback(
        'success',
        intl.formatMessage({ id: 'feedback.success.track_add_to_playlist' })
      )
    } catch (error) {
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.default' })
      )
    }
  }

  const handleDeleteTrack = async (trackId) => {
    try {
      await spotifyEndpoints
        .removeTracksFromPlaylist(playlistId, [trackId])
        .toPromise()

      const updatedTrackList = playlist.tracks.items.filter(({ track }) => {
        if (!track) {
          return true
        }
        return track.id !== trackId
      })

      updatePlaylistState({
        playlist: {
          ...playlist,
          tracks: {
            ...playlist.tracks.items,
            items: updatedTrackList,
          },
        },
      })

      setTracks(updatedTrackList)
      toastFeedback(
        'success',
        intl.formatMessage({
          id: 'feedback.success.track_remove_from_playlist',
        })
      )
    } catch (error) {
      toastFeedback(
        'error',
        intl.formatMessage({ id: 'feedback.error.default' })
      )
    }
  }

  const handlePlaylistDetailsUpdate = async (playlistData) => {
    await spotifyEndpoints
      .updatePlaylistDetails(playlistId, playlistData)
      .toPromise()
    updatePlaylistState({
      playlist: {
        ...playlist,
        ...playlistData,
      },
    })
  }

  const onDragEnd = async () => {
    const dest =
      destIndex === tracks.length - 1 || sourceIndex === 0
        ? destIndex + 1
        : destIndex
    await spotifyEndpoints
      .reorderTracksInPlaylist(playlistId, dest, sourceIndex)
      .toPromise()
    setSourceIndex(null)
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <ProfileWrapper
      back
      title={playlist.name}
      image={playlist.images?.[0]?.url}
      className="my-playlist-edit"
    >
      <div>
        <Accordion>
          <AccordionItem label="Edit playlist details">
            <MyPlaylistDetailsForm
              onSave={handlePlaylistDetailsUpdate}
              playlistDetails={playlist}
            />
          </AccordionItem>
        </Accordion>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Cover</th>
                <th>#</th>
                <th>Name</th>
                <th>Album</th>
                <th>Artists</th>
                <th>Duration</th>
                <th>Popularity</th>
                <th>Added At</th>
                <th>Action</th>
              </tr>
            </thead>
            {tracks && tracks.length > 0 && (
              <Reorder.Group
                as="tbody"
                axis="y"
                layout="position"
                onReorder={handleTrackReorder}
                values={tracks.map(({ track }) => track.id)}
              >
                {tracks.map(({ track, added_at: addedAt }, index) => (
                  <Reorder.Item
                    key={track.id}
                    as="tr"
                    value={track.id}
                    drag="y"
                    onDragStart={() => setActive(track.id)}
                    onDragEnd={onDragEnd}
                  >
                    <TrackItem
                      track={track}
                      addedAt={addedAt}
                      index={++index}
                      action={() =>
                        openConfirmationPopup(() => handleDeleteTrack(track.id))
                      }
                    />
                  </Reorder.Item>
                ))}
              </Reorder.Group>
            )}
          </table>
        </div>

        {isAddTrackFormVisible ? (
          <MyPlaylistAddTrackForm
            onAdd={handleAddTrack}
            onCancel={hideAddTrackForm}
          />
        ) : (
          <div
            className="card-promotion"
            style={{ height: 'auto', cursor: 'pointer' }}
            onClick={showAddTrackForm}
          >
            <div className="card-body text-center">
              <Icon name="plus" />
            </div>
          </div>
        )}
      </div>

      <ConfirmationPopup
        isOpen={!!confirmationPopupCallback}
        onReject={() => setConfirmationPopup(null)}
        bodyClasses="p-5"
        onConfirm={() => {
          confirmationPopupCallback()
          closeConfirmationPopup()
        }}
        text={intl.formatMessage({ id: 'feedback.confirmation.delete_track' })}
      />
    </ProfileWrapper>
  )
}
