import React from 'react'
import { useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import Field from '@fnd/components/Field'
import { TabLinks, useTabs } from '@fnd/components/Tabs'
import { components } from 'react-select'

export function PlaylistQueueFilters({ query, setQuery }) {
  const intl = useIntl()

  const handleTabChange = (value) => {
    if (value === 'archived') {
      setQuery({ ...query, archived: true })
      return
    }

    setQuery({ ...query, handled_by: value, archived: false })
  }

  const getSelectedTabIndex = () => {
    if (query.archived) {
      return handledBy.findIndex((type) => type.id === 'archived')
    }

    return handledBy.findIndex((type) => type.id === query.handled_by)
  }

  const statusOptions = [
    {
      label: intl.formatMessage({ id: 'messages.all.female' }),
      icon: 'list',
      color: 'blue',
      value: 'all',
    },
    {
      label: intl.formatMessage({ id: 'messages.accepted' }),
      value: 'accepted',
      color: 'green',
      icon: 'check',
    },
    {
      label: intl.formatMessage({ id: 'messages.rejected' }),
      value: 'rejected',
      color: 'red',
      icon: 'times',
    },
    {
      label: intl.formatMessage({ id: 'messages.in_queue' }),
      value: 'pending',
      color: 'orange',
      icon: 'hourglass',
    },
  ]

  const handledBy = [
    {
      id: 'all',
      icon: 'list',
      label: intl.formatMessage({ id: 'messages.all.female' }),
      onClick: () => handleTabChange('all'),
    },
    {
      id: 'user',
      label: intl.formatMessage({
        id: 'profile.my_playlists.settings_popup.submissions.queue_table.handled_by.user',
      }),
      icon: 'user',
      onClick: () => handleTabChange('user'),
    },
    {
      id: 'auto',
      label: intl.formatMessage({
        id: 'profile.my_playlists.settings_popup.submissions.queue_table.handled_by.auto',
      }),
      icon: 'sparkles',
      onClick: () => handleTabChange('auto'),
    },
    {
      id: 'archived',
      label: intl.formatMessage({ id: 'messages.archived' }),
      icon: 'archive',
      onClick: () => handleTabChange('archived'),
    },
  ]

  const handledByTabs = {
    tabs: handledBy,
    initialTabId: query.type,
  }

  const handledByFilter = useTabs(handledByTabs)

  const CustomSingleValue = (props) => {
    const value = props.data.value
    const selectedOption = props.options.find(
      (option) => option.value === value
    )

    return (
      <components.SingleValue {...props}>
        <div className="flex items-center gap-2">
          <span className="font-medium">
            {intl.formatMessage({ id: 'messages.status' })}
          </span>
          <Badge
            variant={selectedOption?.color}
            icon={selectedOption?.icon}
            label={selectedOption?.label}
            className={`bg-${selectedOption?.color}`}
          />
        </div>
      </components.SingleValue>
    )
  }

  const CustomOption = (props) => {
    const value = props.data.value
    const selectedOption = statusOptions.find(
      (option) => option.value === value
    )

    return (
      <components.Option {...props}>
        <Badge
          variant={selectedOption?.color}
          icon={selectedOption?.icon}
          label={selectedOption?.label}
          className={`bg-${selectedOption?.color}`}
        />
      </components.Option>
    )
  }

  return (
    <div className="flex flex-wrap items-center gap-3 mb-3">
      <Field
        type="select"
        name="queue-status"
        isClearable={false}
        options={statusOptions}
        value={statusOptions.find((option) => option.value === query.status)}
        defaultValue={statusOptions[0]}
        components={{ Option: CustomOption, SingleValue: CustomSingleValue }}
        onChange={({ value }) => {
          setQuery({ ...query, status: value })
        }}
      />

      <TabLinks
        {...handledByFilter.tabProps}
        inline
        variant="toggle"
        className="md:w-auto tab-light"
        selectedTabIndex={getSelectedTabIndex()}
        showIndicator={false}
        showHover={false}
      />
    </div>
  )
}

export default PlaylistQueueFilters
