import React from 'react'
import { FormattedMessage } from 'react-intl'
import Account from '@fnd/modules/User/Account/Account'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'

export default function AccountPlan() {
  return (
    <ProfileWrapper
      icon="euro-sign"
      title={<FormattedMessage id="navigation.account_plan" />}
    >
      <Account />
    </ProfileWrapper>
  )
}
