import React, { useEffect, useState } from 'react'
import SectionTitle from '@fnd/components/SectionTitle/SectionTitle'
import Spinner from '@fnd/components/Spinner'
import Wrapper from '@fnd/components/Wrapper/Wrapper'
import { useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import CuratorCard from '@fnd/components/Card/CuratorCard'
import Badge from '@fnd/components/Badge'
import { Empty } from '@fnd/components/Empty'

export function CuratorPage() {
  const [starCurators, setStarCurators] = useState([])
  const [featuredCurators, setFeaturedCurators] = useState([])
  const [isLoadingStar, setIsLoadingStar] = useState(true)
  const [isLoadingFeatured, setIsLoadingFeatured] = useState(true)
  const intl = useIntl()

  const fetchStarCurators = async () => {
    try {
      const starCurators = await spotimatchEndpoints
        .getStarCurators()
        .toPromise()

      setStarCurators(starCurators)
      setIsLoadingStar(false)
    } catch (err) {
      setIsLoadingStar(false)
      console.log(err)
    }
  }

  const fetchFeaturedCurators = async () => {
    try {
      const featuredCurators = await spotimatchEndpoints
        .getFeaturedCurators()
        .toPromise()

      setFeaturedCurators(featuredCurators)
      setIsLoadingFeatured(false)
    } catch (err) {
      setIsLoadingFeatured(false)
      console.log(err)
    }
  }

  useEffect(() => {
    fetchStarCurators()
    fetchFeaturedCurators()
  }, [])

  const renderCurators = ({ curators, ...props }) => {
    return curators.map((curator) => {
      if (!curator) return null

      return <CuratorCard key={curator?.id} curator={curator} {...props} />
    })
  }

  return (
    <Wrapper>
      {(isLoadingStar ||
        (!isLoadingStar && starCurators && starCurators.length > 0)) && (
        <SectionTitle
          title={intl.formatMessage({ id: 'curator_star.title' })}
          subtitle={intl.formatMessage({ id: 'curator_star.subtitle' })}
          className="pt-10"
          center
          badge={<Badge variant="gold-light" icon="stars" label="Stars" />}
        />
      )}

      {isLoadingStar && <Spinner />}

      {!isLoadingStar && starCurators && starCurators.length > 0 && (
        <div className="mb-12">
          <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 md:gap-8 mb-16">
            {renderCurators({
              curators: starCurators,
              star: true,
            })}
          </div>
        </div>
      )}

      {(isLoadingFeatured ||
        (!isLoadingFeatured &&
          featuredCurators &&
          featuredCurators.length > 0)) && (
        <SectionTitle
          title={intl.formatMessage({ id: 'curator_featured.title' })}
          subtitle={intl.formatMessage({ id: 'curator_featured.subtitle' })}
          className="pt-0"
          center
          badge={
            <Badge
              variant="primary-light"
              icon="user-friends"
              label="Community"
            />
          }
        />
      )}

      {isLoadingFeatured && <Spinner />}

      {!isLoadingFeatured &&
        featuredCurators &&
        featuredCurators.length > 0 && (
          <div className="mb-12">
            <div className="grid grid-cols-2 xs:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4 md:gap-8 mb-16">
              {renderCurators({
                curators: featuredCurators,
                featured: true,
              })}
            </div>
          </div>
        )}

      {!isLoadingFeatured &&
        featuredCurators.length === 0 &&
        !isLoadingStar &&
        starCurators.length === 0 && <Empty />}
    </Wrapper>
  )
}

export default CuratorPage
