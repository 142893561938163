import { make } from './endpoints'

export const getBanner = () => make.get('/open/banner')

export const getPlans = (lang) =>
  make.get(`/open/plans?lang=${lang}`).toPromise()

export const getProducts = (lang) =>
  make.get(`/open/products?lang=${lang}`).toPromise()

export const getFeaturedCurators = () => make.get('/open/curators/featured')

export const getRandomCurators = () => make.get('/open/curators/random')

export const getStarCurators = () => make.get('/open/curators/star')

export const getPopularSubmissions = (query) =>
  make.get(`/open/submissions/popular?${query}`).toPromise()

export const getFeaturedInvoices = () => make.get('/open/invoices/featured').toPromise()

export default {
  getBanner,
  getFeaturedCurators,
  getFeaturedInvoices,
  getPlans,
  getPopularSubmissions,
  getProducts,
  getRandomCurators,
  getStarCurators
}
