import React from 'react'
import classNames from 'classnames'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import { DEFAULT_ICON_TYPE } from '@fnd/constants'

export const ModalHeader = ({
  icon,
  iconType = DEFAULT_ICON_TYPE,
  title,
  onClose,
  children,
  className,
}) => {
  const classes = classNames({
    'modal-header': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <div>
        <h4 className="modal-title">
          {icon && <Icon name={icon} iconType={iconType} />}
          {title}
        </h4>
        {children}
      </div>
      {onClose && (
        <Button icon="times" className="modal-close" onClick={onClose} />
      )}
    </div>
  )
}

export default ModalHeader
