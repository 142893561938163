import React from 'react'
import classNames from 'classnames'
import Chart from '@fnd/components/Chart'
import Icon from '@fnd/components/Icon'
import Spinner from '@fnd/components/Spinner'
import { Link } from 'react-router-dom'
import { Variation } from '@fnd/components/Badge'
import { FormattedMessage } from 'react-intl'
import Tooltip from 'rc-tooltip'
import parse from 'html-react-parser'

const DashboardCard = ({
  label,
  value,
  loading,
  text,
  icon,
  chartData,
  color,
  variation,
  variationInvert,
  to,
  tooltip,
  children,
  progress,
  className,
  ...props
}) => {
  const classes = classNames({
    card: true,
    [`card-${color}`]: color,
    'dashboard-card': true,
    [className]: className,
  })

  const contentClasses = classNames({
    'card-content': true,
    'w-full': !chartData,
    [className]: className,
  })

  const CardWrapper = ({ children }) => {
    if (to) {
      return (
        <Link className={classes} to={to}>
          {children}
        </Link>
      )
    }
    return <div className={classes}>{children}</div>
  }

  const CardNotes = ({ children }) => {
    return (
      <span className="block mt-1 text-sm font-light leading-5 opacity-60">
        {children}
      </span>
    )
  }

  return (
    <CardWrapper className={classes} {...props}>
      {tooltip && (
        <div className="card-tooltip">
          <Tooltip
            mouseEnterDelay={0.5}
            placement="bottom"
            trigger={['hover']}
            overlay={<span>{parse(tooltip)}</span>}
          >
            <span>
              <Icon name="info-circle" />
            </span>
          </Tooltip>
        </div>
      )}

      <div className={contentClasses}>
        <h4>
          {icon && (
            <div className="card-icon">
              <Icon name={icon} />
            </div>
          )}
          {label}
        </h4>

        {loading && (
          <div className="card-loading">
            <Spinner inline />
          </div>
        )}

        {!loading && (
          <div className="card-value">
            {value}

            {variation !== undefined && (
              <Variation
                invert={variationInvert}
                className="ml-2"
                value={variation}
              />
            )}
          </div>
        )}

        {!value && !loading && (
          <CardNotes>
            <Icon name="exclamation-triangle" className="mr-1" />
            <FormattedMessage id="profile.dashboard.no_data" />
          </CardNotes>
        )}

        {text && <CardNotes>{text}</CardNotes>}

        {(variation > 0 || variation < 0) && !text && !loading && (
          <CardNotes>
            <FormattedMessage id="profile.dashboard.month_period" />
          </CardNotes>
        )}

        {progress && !loading && progress}
      </div>

      {chartData && !loading && (
        <div className="card-chart">
          <Chart
            data={chartData}
            color={color}
            id={label}
            showArea={true}
            showBottomAxis={false}
            showLeftAxis={false}
          />
        </div>
      )}

      {children}
    </CardWrapper>
  )
}

export default DashboardCard
