import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '@fnd/screens/constants'
import { isValidEmail } from '@fnd/core/libs/validate'
import Button from '@fnd/components/Button'
import Genres from '@fnd/modules/Analysis/Search/Genres'
import { SectionHeader } from '@fnd/components/Section'
import { toastFeedback } from '@fnd/core/libs/toast'
import { useAppStore } from '@fnd/store'
import Field from '@fnd/components/Field'
import {
  socials,
  appendSocialPrefixes,
  removeSocialPrefixes,
  removeSocialPrefix,
  removeWebsiteProtocol,
} from '@fnd/core/libs/platforms'

const validation = {
  email: (value) => isValidEmail(value),
}

const validate = (value, type) => {
  if (typeof value !== 'string' || !validation[type]) {
    return true
  }

  return validation[type](value)
}

export default function ProfileOverviewEdit({
  userProfile,
  updateUserContacts,
  updateUserGenres,
  updateUserVerified,
}) {
  const intl = useIntl()
  const { setProfileComplete } = useAppStore()
  const [bio, setBio] = useState('')
  const [genres, setGenres] = useState([])
  const [roles, setRoles] = useState([])
  const [stateContacts, updateStateContacts] = useState({})
  const [isSubmitLoading, setSubmitLoading] = useState(false)
  const [isFormValid, setFormValid] = useState(true)
  const navigate = useNavigate()

  const inputChangeHandler = ({ target }) =>
    updateStateContacts((prevState) => ({
      ...prevState,
      [target.name]: target.value,
    }))

  const blurHandler = ({ target }) => {
    let newValue = target.value
    if (target.name !== 'email') {
      newValue =
        target.name === 'website'
          ? removeWebsiteProtocol(target.value)
          : removeSocialPrefix(target.value)
    }
    updateStateContacts((prevState) => ({
      ...prevState,
      [target.name]: newValue,
    }))

    if (validate(target.value, target.name)) {
      setFormValid(true)
      return
    }

    setFormValid(false)
    target.classList.add('invalid')
  }

  const focusHandler = ({ target }) => target.classList.remove('invalid')

  const submitHandler = (evt) => {
    evt.preventDefault()
    if (isSubmitLoading || !isFormValid) {
      return
    }

    setSubmitLoading(true)

    const userRoles = roles.map((role) => role.value)

    spotimatchEndpoints
      .updateUserContacts({
        ...appendSocialPrefixes(stateContacts),
        bio,
        genres,
        roles: userRoles,
      })
      .toPromise()
      .then((updatedProfile) => {
        updateUserContacts(updatedProfile.contacts)
        updateUserVerified(updatedProfile.verified)
        updateUserGenres(updatedProfile.tags)
        setSubmitLoading(false)
        setProfileComplete(false)
        toastFeedback(
          'success',
          intl.formatMessage({ id: 'feedback.success.info_saved' })
        )
        navigate(`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.OVERVIEW}`)
      })
      .catch((error) => {
        console.log(error)
        toastFeedback(
          'error',
          intl.formatMessage({ id: 'feedback.error.default' })
        )
        setSubmitLoading(false)
      })
  }

  const submitButtonDisabled = isSubmitLoading || !isFormValid

  useEffect(() => {
    setBio(
      userProfile?.contacts?.bio || intl.formatMessage({ id: 'profile.no_bio' })
    )
    setGenres(userProfile.tags)

    const userRoles = userProfile?.contacts?.roles.map((role) => ({
      label: intl.formatMessage({ id: `role.${role}` }),
      value: role,
    }))

    setRoles(userRoles)

    const userContacts = removeSocialPrefixes(userProfile.contacts)
    updateStateContacts(userContacts)
  }, [userProfile])

  return (
    <>
      <SectionHeader
        sticky
        icon="user-circle"
        title={<FormattedMessage id="profile.overview.edit" />}
        actions={
          <>
            <Button
              icon="undo"
              to={`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.OVERVIEW}`}
              variant="inverse"
              type="button"
            >
              <FormattedMessage id="profile.overview.cancel" />
            </Button>
            <Button
              icon="check"
              onClick={submitHandler}
              variant="primary"
              disabled={submitButtonDisabled}
              type="submit"
              label={<FormattedMessage id="profile.overview.save_profile" />}
              loading={isSubmitLoading}
            />
          </>
        }
      />

      <div className="p-5">
        <form className="form grid md:grid-cols-2">
          <Field
            id="spotify-id"
            name="spotify-id"
            className="form-group md:col-span-2"
            label="Spotify ID"
            value={userProfile.spotify_id}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={inputChangeHandler}
            readOnly
          />

          <Field
            id="contact-email"
            name="email"
            label="Email"
            value={stateContacts.email}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={inputChangeHandler}
          />

          <Field
            id="contact-twitter"
            name="twitter"
            label="Twitter"
            prefix={socials.twitter.prefix}
            value={stateContacts.twitter}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={inputChangeHandler}
          />

          <Field
            id="contact-instagram"
            name="instagram"
            label="Instagram"
            prefix={socials.instagram.prefix}
            value={stateContacts.instagram}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={inputChangeHandler}
          />

          <Field
            id="contact-facebook"
            name="facebook"
            label="Facebook"
            prefix={socials.facebook.prefix}
            value={stateContacts.facebook}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={inputChangeHandler}
          />

          <Field
            id="contact-linkedin"
            name="linkedin"
            label="LinkedIn"
            prefix={socials.linkedin.prefix}
            value={stateContacts.linkedin}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={inputChangeHandler}
          />

          <Field
            id="contact-tiktok"
            name="tiktok"
            label="TikTok"
            prefix={socials.tiktok.prefix}
            value={stateContacts.tiktok}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={inputChangeHandler}
          />

          <Field
            id="contact-youtube"
            name="youtube"
            label="YouTube"
            prefix={socials.youtube.prefix}
            value={stateContacts.youtube}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={inputChangeHandler}
          />

          <Field
            id="contact-website"
            name="website"
            label="Website"
            value={stateContacts.website}
            onBlur={blurHandler}
            onFocus={focusHandler}
            onChange={inputChangeHandler}
            prefix={socials.website.prefix}
          />

          <div className="form-group md:col-span-2">
            <Field
              id="bio"
              name="bio"
              label="Bio"
              type="textarea"
              value={bio}
              maxLength={160}
              onChange={(e) => setBio(e.target.value)}
            />
          </div>

          <div className="form-group md:col-span-2">
            <Field
              id="role"
              name="role"
              type="select"
              multi={true}
              maxLimit={3}
              onChange={(value) => setRoles(value)}
              label={<FormattedMessage id="messages.roles" />}
              fetchFn={spotimatchEndpoints.getRoles}
              value={roles}
              labelKey="name"
              valueKey="slug"
              translateKey="role"
            />
          </div>

          <div className="form-group md:col-span-2">
            <label htmlFor="contact-genres">Genres</label>
            <Genres
              className="w-full"
              hideLabel={true}
              genres={genres}
              maxLimit={5}
              onGenreSelect={(genres) => setGenres(genres)}
            />
          </div>
        </form>
      </div>
    </>
  )
}
