import React from 'react'
import Badge from '@fnd/components/Badge'
import { FormattedMessage } from 'react-intl'

export const SubmissionsStatus = ({ status }) => {
  if (status === 'accepted') {
    return (
      <Badge
        small
        icon="check"
        label={<FormattedMessage id="messages.accepted" />}
        variant="green-light"
      />
    )
  } else if (status === 'pending') {
    return (
      <Badge
        small
        icon="hourglass"
        label={<FormattedMessage id="messages.in_queue" />}
        variant="orange-light"
      />
    )
  } else if (status === 'rejected') {
    return (
      <Badge
        small
        icon="times"
        label={<FormattedMessage id="messages.rejected" />}
        variant="red-light"
      />
    )
  }
}

export default SubmissionsStatus
