import Empty from '@fnd/components/Empty'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Chart from '@fnd/components/Chart'
import { formatNumber } from '@fnd/core/libs/currency'
import Card, { DashboardCard } from '@fnd/components/Card'
import Icon from '@fnd/components/Icon'

export default function TikTokTalentsResults({ results, status }) {
  const intl = useIntl()
  const { locale } = intl

  switch (status) {
    case 'running':
      return (
        <Empty
          icon="chart-line"
          className="m-0"
          message={
            <FormattedMessage id="profile.vip_campaigns.report.running" />
          }
        ></Empty>
      )
    case 'ended':
      if (!results) return <Empty />
      return (
        <div>
          {results?.length > 0 && (
            <div className="flex flex-col items-start gap-3">
              {results.map((result, index) => (
                <Card
                  key={index}
                  contentClasses="flex items-center justify-between"
                  className="w-full p-3 font-medium text-primary truncate underline rounded border-outline"
                  to={result?.video_url}
                  external={true}
                >
                  <span className="inline-flex items-center gap-2">
                    <Icon name="video-plus" />
                    <span className="truncate">{result?.video_url}</span>
                  </span>

                  <Icon name="external-link-alt" />
                </Card>
              ))}
            </div>
          )}
        </div>
      )
  }
}
