import React from 'react'
import { useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge/Badge'
import parse from 'html-react-parser'
import classNames from 'classnames'

const PlansHeader = ({ className }) => {
  const intl = useIntl()

  const classes = classNames({
    'plans-header': true,
    'flex flex-col items-center justify-end relative': true,
    'pt-16 pb-4 mb-4 md:pt-32 md:mb-10 ': true,
    [className]: className,
  })

  const contentClasses = classNames({
    'w-full h-full': true,
    'flex flex-col items-center justify-end': true,
    'text-center px-4 pb-4 md:pb-4 z-20': true,
  })

  return (
    <div className={classes}>
      <img
        className="rounded-xl border-outline z-0"
        src="/images/pricing/matchfy_pricing_banner.jpg"
        alt="Matchfy Pricing Banner"
      />

      <div className="overlay" />

      <div className={contentClasses}>
        <Badge
          variant="primary"
          label={intl.formatMessage({ id: 'navigation.pricing' })}
          className="rounded-full px-5 mb-3"
        />

        <h1 className="m-0 mb-5">
          {parse(intl.formatMessage({ id: 'plans.header.title' }))}
        </h1>

        <h2 className="font-light text-xl md:text-2xl opacity-80 md:max-w-[54ch] m-0 mb-4">
          {parse(intl.formatMessage({ id: 'plans.header.subtitle' }))}
        </h2>

        <p className="font-medium text-xl md:text-2xl md:max-w-[54ch] m-0 mb-4">
          {parse(intl.formatMessage({ id: 'plans.header.text' }))}
        </p>
      </div>
    </div>
  )
}

export default PlansHeader
