import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import { CURRENCY_SYMBOL } from '@fnd/constants'
import { formatCurrency } from '@fnd/core/libs/currency'
import classNames from 'classnames'
import { useAffiliateStore, useDiscountStore } from '@fnd/store'

export default function PaymentDetails({
  billing,
  creditsScoreUsed,
  discountPercent,
  totalDiscountAmount,
  disableAffiliate,
  price,
  children,
  className,
}) {
  const [totalDiscountedAmount, setTotalDiscountedAmount] = useState(0)
  const intl = useIntl()
  const { locale } = intl

  useEffect(() => {
    if (discountPercent) {
      setTotalDiscountedAmount(price * (discountPercent / 100))
    }
  }, [discountPercent, price])

  useEffect(() => {
    if (totalDiscountAmount) {
      setTotalDiscountedAmount(totalDiscountAmount)
    }
  }, [totalDiscountAmount, price])

  const { affiliate } = useAffiliateStore()
  const { discount, canUseDiscount, discountAmount } = useDiscountStore()

  const classes = classNames({
    'payment-details': true,
    [className]: className,
  })

  return (
    <ul className={classes}>
      {billing && (
        <li>
          <label>
            {billing === 'one-time' && (
              <FormattedMessage id="messages.base_price" />
            )}
            {billing === 'monthly' && (
              <FormattedMessage id="messages.yearly_subscription" />
            )}
          </label>
          <span>{formatCurrency(price, locale)}</span>
        </li>
      )}

      {!billing && price > 0 && (
        <li>
          <label>
            <FormattedMessage id="messages.base_price" />
          </label>
          <span>{formatCurrency(price + totalDiscountedAmount, locale)}</span>
        </li>
      )}

      {totalDiscountedAmount > 0 && (
        <li>
          <label>
            <FormattedMessage id="messages.discount" />
          </label>
          <div className="flex items-center gap-2">
            {discountPercent && (
              <Badge
                variant="green-light"
                label={`-${discountPercent.toFixed(2)}%`}
              />
            )}
            <span>
              {'- '}
              {formatCurrency(totalDiscountedAmount, locale)}
            </span>
          </div>
        </li>
      )}

      {creditsScoreUsed > 0 && (
        <li>
          <label>
            <FormattedMessage id="messages.use_credits_discount" />
          </label>
          <span>
            {'- '}
            {formatCurrency(creditsScoreUsed, locale)}
          </span>
        </li>
      )}

      {!(discount && canUseDiscount) &&
        affiliate?.discount_percentage > 0 &&
        !disableAffiliate && (
          <li>
            <label className="m-0">
              <FormattedMessage id="messages.affiliate_discount" />
            </label>
            <span>
              {'- '}
              {formatCurrency(
                price * (affiliate?.discount_percentage / 100),
                locale
              )}
            </span>
          </li>
        )}

      {canUseDiscount && discount?.amount > 0 && (
        <li>
          <label className="m-0">
            <FormattedMessage id="messages.coupon_discount" />
          </label>
          <span>
            {'- '}
            {formatCurrency(discountAmount, locale)}
          </span>
        </li>
      )}

      {children}
    </ul>
  )
}
