import { make } from './endpoints'

export const getAvailableAnalysis = () =>
  make.get('/analysis/available/count').toPromise()

export const getArtistAnalysis = (artist) => {
  return make
    .post(`/analysis/generate/${artist.id}`, {
      body: { artist },
      responseType: 'blob',
    })
    .toPromise()
}

export default {
  getAvailableAnalysis,
  getArtistAnalysis,
}
