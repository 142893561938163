import React from 'react'
import { PlaylistGenres } from '@fnd/modules/Curator/Playlist/PlaylistGenres'
import classNames from 'classnames'
import { usePlaylistStore } from '@fnd/store'
import PlaylistSelectGenres from './PlaylistSelectGenres'

export const PlaylistSettings = ({ className, closeModalFn }) => {
  const classes = classNames({
    [className]: className,
  })
  const { currentPlaylistId, currentPlaylist } = usePlaylistStore()

  return (
    <div className={classes}>
      {currentPlaylistId && !currentPlaylist.enable_manual_genres && (
        <PlaylistGenres className="w-full" closeModalFn={closeModalFn} />
      )}

      {currentPlaylistId && currentPlaylist.enable_manual_genres && (
        <PlaylistSelectGenres className="w-full" closeModalFn={closeModalFn} />
      )}
    </div>
  )
}

export default PlaylistSettings
