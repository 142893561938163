import { useEffect, useState } from 'react'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { followPlaylist } from '@fnd/core/spotify/endpoints'
import useCheckQueueLimits from './useCheckQueueLimits'

export default function usePushToPlaylist({ trackId, playlistId }) {
  const queueLimitsState = useCheckQueueLimits({
    trackId,
    playlistId,
  })
  const [pushToPlaylistState, setState] = useState(queueLimitsState)

  useEffect(() => {
    setState((state) => ({
      ...state,
      isLoading: queueLimitsState.isLoading,
      isTrackInQueue: queueLimitsState.isTrackInQueue,
    }))
  }, [queueLimitsState])

  const pushToPlaylistQueueHandler = async (track, profile_settings) => {
    try {
      setState((state) => ({ ...state, isLoading: true }))

      const response = await spotimatchEndpoints.putTrackIntoQueue({
        track,
        trackId,
        playlistId,
      })

      try {
        if (profile_settings?.submission_send_follow_playlist) {
          await followPlaylist(playlistId)
        }
      } catch (error) {
        console.error('Error following playlist', error)
      }

      setState((state) => ({
        ...state,
        error: undefined,
        success: true,
        isTrackInQueue: response.is_track_in_queue,
        isLoading: false,
      }))

      return response
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? error.message
      setState((state) => ({
        ...state,
        error: errorMessage,
        success: false,
        isLoading: false,
      }))

      return {
        isTrackInQueue: false,
        error: errorMessage,
      }
    }
  }

  return [pushToPlaylistState, pushToPlaylistQueueHandler]
}
