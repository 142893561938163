export const ROUTES = {
  ACCELERATE_PAGE: '/accelerate',
  ACCOUNT: '/:page?/account',
  AFFILIATE: '/affiliate',
  ALL: '*',
  ANALYSIS: '/analysis',
  BLOCKED: '/user-blocked',
  BLOG: 'https://blog.matchfy.io/',
  CHAT: '/chat',
  CHAT_DETAIL: '/chat/:id',
  CONTEST: '/contest',
  CONTRACT_OF_SERVICE: '/terms/contract-of-service',
  CURATOR_DETAIL: '/curators/:curatorId',
  CURATOR_PLAYLIST_DETAIL: '/curators/:curatorId/:playlistId',
  CURATORS: '/curators',
  DISTRIBUTION: '/distribution',
  ERROR_LOGIN: '/error-auth',
  FACEBOOK: 'https://www.facebook.com/Matchfy.io/',
  FAQ: '/faq',
  HISTORY_ACTIVITY: '/:page?/profile/history',
  HOME: '/',
  INSTAGRAM: 'https://www.instagram.com/matchfy.io/',
  INVOICE: '/invoice/:invoiceId',
  MAGAZINE: '/magazine',
  MATCH: '/match',
  MY_DOWNLOADS: '/:page?/profile/downloads',
  MY_PROFILE: {
    BILLING_INFO: 'billing',
    BILLING_INFO_EDIT: 'billing/edit',
    BLOCKED_USERS: 'blocked-users',
    DASHBOARD: 'dashboard',
    MANAGEMENT: 'management',
    MY_ACCELERATES: 'accelerates',
    MY_ACTIVITY: 'activity',
    MY_ACTIVITY_TRACK_DETAIL: 'activity/:trackId',
    MY_ARTISTS: 'artists',
    MY_DOWNLOAD_VIEW: 'downloads/:downloadId',
    MY_DOWNLOADS: 'downloads',
    MY_PLAYLIST_ANALYSIS: 'playlists/:playlistId/analysis',
    MY_PLAYLIST_EDIT: 'playlists/:playlistId/edit',
    MY_PLAYLIST_VIEW: 'playlists/:playlistId',
    MY_PLAYLISTS: 'playlists',
    OVERVIEW: 'overview',
    OVERVIEW_EDIT: 'overview-edit',
    PAYMENTS: 'payments',
    PLAN: 'plan',
    PLAYLIST_CHECK: 'playlist-check',
    PREFERENCES: 'preferences',
    PROFILE: '/my-profile',
    ROOT: '/my-profile/*',
    SETTINGS: 'settings',
    VIP_CAMPAIGN_DETAILS: 'details',
    VIP_CAMPAIGN_REPORT: 'report',
    VIP_CAMPAIGN_TAB: 'vip-campaign',
    VIP_CAMPAIGN_VIEW: 'vip-campaigns/:campaignId',
    VIP_CAMPAIGNS: 'vip-campaigns',
    VIP_MANAGER_TAB: 'vip-manager',
    WANTED: 'wanted',
  },
  NOTIFICATIONS: '/notifications',
  PAYMENT_REDIRECT: '/payment-callback',
  PAYMENTS_HISTORY: '/:page?/profile/payments',
  PLAYGROUND: '/playground',
  PLAYLIST: {
    ROOT: '/match/playlists/:playlistId',
  },
  PLAYLIST_DETAIL: '/playlist/:playlistId',
  PLAYLIST_FEATURED: '/featured-playlists',
  PLAYLIST_FEATURED_DETAIL: '/featured-playlists/:playlistId',
  POPULAR: '/popular',
  PRICING: '/pricing',
  PRICING_LANDING: '/pricing-landing',
  PRIVACY: '/privacy',
  PROFILE: '/:page?/profile',
  PROMO: '/promo',
  RADIO: '/radio',
  RADIO_DETAIL: '/radio/:id',
  RANK: '/rank',
  REPORT: '/is-this-playlist-fake/:id?',
  REPORT_PAGE: '/is-this-playlist-fake',
  ROOT: '/*',
  SLA: '/terms/sla',
  SPOTIFY_SIGN_CALLBACK: '/sign',
  SPOTIFY_SSO_CALLBACK: '/sso-callback',
  SPOTIFY_SSO: '/sso',
  SUPPORT: '/support',
  TEAM: '/team',
  TERMS: '/terms',
  TIKTOK: '/tiktok',
  TIKTOK_DETAIL: '/tiktok/:id',
  TOKEN: '/token/:code',
  TRACK_DETAIL: '/vip-plays/:trackId',
  TRACKS: '/vip-plays',
  TUTORIALS: '/tutorials',
  USER_VERIFY: '/user/verify',
  VIP: '/vip',
}

export const ROUTES_SEO = {
  [ROUTES.HOME]: {
    changefreq: 'daily',
    priority: 1.0,
    key: 'home',
  },
  [ROUTES.PRICING]: {
    changefreq: 'monthly',
    priority: 1.0,
    key: 'pricing',
    image: 'og_pricing.jpg',
  },
  [ROUTES.PRICING_LANDING]: {
    changefreq: 'monthly',
    priority: 1.0,
    key: 'pricing-landing',
    image: 'og_pricing.jpg',
  },
  [ROUTES.MATCH]: {
    changefreq: 'monthly',
    priority: 0.8,
    key: 'match',
    image: 'og_match.jpg',
  },
  [ROUTES.POPULAR]: {
    changefreq: 'hourly',
    priority: 0.8,
    key: 'popular',
    image: 'og_popular.jpg',
  },
  [ROUTES.CURATORS]: {
    changefreq: 'hourly',
    priority: 0.8,
    key: 'curators',
    image: 'og_curators.jpg',
  },
  [ROUTES.VIP]: {
    changefreq: 'monthly',
    priority: 0.8,
    key: 'vip',
    image: 'og_vip.jpg',
  },
  [ROUTES.ANALYSIS]: {
    changefreq: 'monthly',
    priority: 0.7,
    key: 'analysis',
    image: 'og_analysis.jpg',
  },
  [ROUTES.DISTRIBUTION]: {
    changefreq: 'monthly',
    priority: 0.6,
    key: 'distribution',
    image: 'og_distribution.jpg',
  },
  [ROUTES.ACCELERATE_PAGE]: {
    changefreq: 'monthly',
    priority: 0.6,
    key: 'accelerate',
    image: 'og_accelerate.jpg',
  },
  [ROUTES.RANK]: {
    changefreq: 'daily',
    priority: 0.5,
    key: 'rank',
    image: 'og_rank.jpg',
  },
  [ROUTES.REPORT_PAGE]: {
    changefreq: 'weekly',
    priority: 0.5,
    key: 'report',
    image: 'og_playlist_check.jpg',
  },
  [ROUTES.TRACKS]: {
    changefreq: 'monthly',
    priority: 0.4,
    key: 'vip_tracks',
    image: 'og_vip_plays.jpg',
  },
  [ROUTES.SUPPORT]: {
    changefreq: 'monthly',
    priority: 0.3,
    key: 'support',
    image: 'og_support.jpg',
  },
  [ROUTES.TEAM]: {
    changefreq: 'monthly',
    priority: 0.3,
    key: 'team',
    image: 'og_team.jpg',
  },
  [ROUTES.FAQ]: {
    changefreq: 'yearly',
    priority: 0.3,
    lastmod: '2023-09-23',
    key: 'faq',
  },
  [ROUTES.TERMS]: {
    changefreq: 'yearly',
    priority: 0.1,
    lastmod: '2024-02-21',
    key: 'terms',
  },
}

export const DEFAULT_LANGUAGE = 'en'
export const AVAILABLE_LANGUAGES = [
  {
    label: 'languages.italian',
    value: 'it',
    bpc47: 'it-IT',
  },
  {
    label: 'languages.english',
    value: 'en',
    bpc47: 'en-US',
  },
  {
    label: 'languages.spanish',
    value: 'es',
    bpc47: 'es-ES',
  },
  {
    label: 'languages.french',
    value: 'fr',
    bpc47: 'fr-FR',
  },
]

export const LOGIN_REDIRECT = `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.DASHBOARD}`
export const LOGOUT_REDIRECT = ROUTES.HOME

export const NAV = [
  {
    icon: 'headphones-alt',
    title: 'navigation.curators',
    link: `${ROUTES.CURATORS}`,
    show: true,
  },
  {
    icon: 'file-chart-line',
    title: 'navigation.analysis',
    link: ROUTES.ANALYSIS,
    show: true,
  },
  {
    icon: 'dollar-sign',
    title: 'navigation.pricing',
    link: ROUTES.PRICING,
    show: true,
  },
  {
    icon: 'rocket',
    title: 'navigation.accelerate',
    link: ROUTES.ACCELERATE_PAGE,
    show: false,
  },
  {
    icon: 'album-collection',
    title: 'navigation.distribution',
    link: ROUTES.DISTRIBUTION,
    show: true,
  },
  {
    icon: 'check',
    title: 'navigation.report',
    link: ROUTES.REPORT_PAGE,
    show: true,
  },
  {
    icon: 'play-circle',
    title: 'navigation.vip_tracks',
    link: ROUTES.TRACKS,
    show: true,
  },
]

export const TAB_NAV = [
  {
    icon: 'music',
    title: 'navigation.match',
    link: ROUTES.MATCH,
    show: true,
  },
  {
    icon: 'chart-line',
    title: 'navigation.my_activity',
    link: `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ACTIVITY}`,
    show: true,
  },
  {
    icon: 'list-music',
    title: 'navigation.my_playlists',
    link: `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_PLAYLISTS}`,
    show: true,
  },
  {
    icon: 'rocket',
    title: 'navigation.my_accelerates',
    link: ROUTES.ACCELERATE_PAGE,
    show: false,
  },
  {
    icon: 'album-collection',
    title: 'navigation.distribution',
    link: ROUTES.DISTRIBUTION,
    show: true,
  },
]

export const DRAWER_NAV = [
  {
    title: 'navigation.home',
    items: [
      {
        icon: 'home',
        title: 'navigation.home',
        link: ROUTES.HOME,
        show: true,
      },
      {
        icon: 'user',
        title: 'navigation.profile',
        link: `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.OVERVIEW}`,
        show: true,
      },
      {
        icon: 'file-chart-line',
        title: 'navigation.analysis',
        link: ROUTES.ANALYSIS,
        show: true,
      },
      {
        icon: 'dollar-sign',
        title: 'navigation.pricing',
        link: ROUTES.PRICING,
        show: true,
      },
      {
        icon: 'gem',
        title: 'navigation.vip',
        link: ROUTES.VIP,
        show: true,
      },
    ],
  },
  {
    title: 'navigation.match',
    items: [
      {
        icon: 'music',
        title: 'navigation.match',
        link: ROUTES.MATCH,
        show: true,
      },
      {
        icon: 'tiktok',
        iconType: 'brands',
        title: 'navigation.tiktok',
        link: ROUTES.TIKTOK,
        show: true,
      },
      {
        icon: 'typewriter',
        title: 'navigation.magazine',
        link: ROUTES.MAGAZINE,
        show: true,
      },
      {
        icon: 'radio',
        title: 'navigation.radio',
        link: ROUTES.RADIO,
        show: true,
      },
    ],
  },
  {
    title: 'navigation.community',
    items: [
      {
        icon: 'headphones-alt',
        title: 'navigation.curators',
        link: `${ROUTES.CURATORS}`,
        show: true,
      },
      {
        icon: 'rocket',
        title: 'navigation.my_accelerates',
        link: ROUTES.ACCELERATE_PAGE,
        show: false,
      },
      {
        icon: 'album-collection',
        title: 'navigation.distribution',
        link: ROUTES.DISTRIBUTION,
        show: true,
      },
      {
        icon: 'play-circle',
        title: 'navigation.vip_tracks',
        link: ROUTES.TRACKS,
        show: true,
      },
      {
        icon: 'check',
        title: 'navigation.report',
        link: ROUTES.REPORT_PAGE,
        show: true,
      },
      {
        icon: 'trophy',
        title: 'navigation.rank',
        link: ROUTES.RANK,
        show: true,
      },
      {
        icon: 'star',
        title: 'navigation.popular',
        link: ROUTES.POPULAR,
        show: true,
      },
    ],
  },
  {
    title: 'navigation.support',
    items: [
      {
        icon: 'comment-lines',
        title: 'navigation.support',
        link: ROUTES.SUPPORT,
        show: true,
      },
      {
        icon: 'question-circle',
        title: 'navigation.faq',
        link: ROUTES.FAQ,
        show: true,
      },
      {
        icon: 'info-circle',
        title: 'navigation.tutorials',
        link: ROUTES.TUTORIALS,
        show: true,
      },
    ],
  },
]

export const FOOTER_NAV_1 = [
  {
    title: 'navigation.home',
    link: ROUTES.HOME,
  },
  {
    title: 'navigation.profile',
    link: `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.OVERVIEW}`,
    show: true,
  },
  {
    title: 'navigation.match',
    link: ROUTES.MATCH,
    show: true,
  },
  {
    title: 'navigation.analysis',
    link: ROUTES.ANALYSIS,
    show: true,
  },
  {
    title: 'navigation.pricing',
    link: ROUTES.PRICING,
    show: true,
  },
  {
    title: 'navigation.accelerate',
    link: ROUTES.ACCELERATE_PAGE,
    show: true,
  },
  {
    title: 'navigation.distribution',
    link: ROUTES.DISTRIBUTION,
    show: true,
  },
  {
    title: 'navigation.vip_tracks',
    link: ROUTES.TRACKS,
    show: true,
  },
]

export const FOOTER_NAV_2 = [
  {
    title: 'navigation.affiliate',
    link: ROUTES.AFFILIATE,
    show: true,
  },
  {
    title: 'navigation.blog',
    link: ROUTES.BLOG,
    show: true,
  },
  {
    title: 'navigation.team',
    link: ROUTES.TEAM,
    show: true,
  },
  {
    title: 'navigation.faq',
    link: ROUTES.FAQ,
    show: true,
  },
  {
    title: 'navigation.tutorials',
    link: ROUTES.TUTORIALS,
    show: true,
  },
  {
    title: 'navigation.support',
    link: ROUTES.SUPPORT,
    show: true,
  },
]

export const PROFILE_SIDEBAR = [
  {
    to: ROUTES.MY_PROFILE.DASHBOARD,
    icon: 'tachometer',
    label: 'navigation.dashboard',
    show: true,
  },
  {
    to: ROUTES.MY_PROFILE.OVERVIEW,
    icon: 'user',
    label: 'navigation.overview',
    show: true,
  },
  {
    to: ROUTES.MY_PROFILE.MY_ARTISTS,
    icon: 'headphones-alt',
    label: 'navigation.my_artists',
    show: true,
  },
  {
    to: ROUTES.MY_PROFILE.MY_ACTIVITY,
    icon: 'chart-line',
    label: 'navigation.my_activity',
    show: true,
  },
  {
    to: ROUTES.MY_PROFILE.VIP_CAMPAIGNS,
    icon: 'gem',
    label: 'navigation.vip_campaigns',
    show: true,
  },
  {
    to: ROUTES.MY_PROFILE.MY_PLAYLISTS,
    icon: 'list-music',
    label: 'navigation.my_playlists',
    show: true,
  },
  {
    to: ROUTES.MY_PROFILE.PLAYLIST_CHECK,
    icon: 'check',
    label: 'navigation.report',
    show: true,
  },
  {
    to: ROUTES.MY_PROFILE.MY_DOWNLOADS,
    icon: 'cloud-download-alt',
    label: 'navigation.my_downloads',
    show: true,
  },
  {
    to: ROUTES.MY_PROFILE.MY_ACCELERATES,
    icon: 'rocket',
    label: 'navigation.my_accelerates',
    show: true,
  },
  {
    to: ROUTES.SPOTIFY_SSO,
    icon: 'album-collection',
    label: 'navigation.distribution',
    show: true,
  },
  {
    to: ROUTES.MY_PROFILE.WANTED,
    icon: 'id-badge',
    label: 'navigation.wanted',
    show: false,
  },
  {
    to: ROUTES.MY_PROFILE.SETTINGS,
    icon: 'cog',
    label: 'navigation.settings',
    show: true,
  },
  {
    to: ROUTES.MY_PROFILE.MANAGEMENT,
    icon: 'user-music',
    label: 'navigation.management',
    show: true,
  },
  {
    to: ROUTES.SUPPORT,
    icon: 'question-circle',
    label: 'navigation.support',
    show: true,
  },
]

export const MATCH_CHANNELS = [
  {
    id: 'match',
    label: 'navigation.spotify',
    icon: 'spotify',
    iconType: 'brands',
    to: ROUTES.MATCH,
    show: true,
  },
  {
    id: 'tiktok',
    label: 'navigation.tiktok',
    icon: 'tiktok',
    iconType: 'brands',
    to: ROUTES.TIKTOK,
    show: true,
  },
  {
    id: 'magazine',
    label: 'navigation.magazine',
    icon: 'typewriter',
    to: ROUTES.MAGAZINE,
    show: true,
  },
  {
    id: 'radio',
    label: 'navigation.radio',
    icon: 'radio',
    to: ROUTES.RADIO,
    show: true,
  },
  {
    id: 'playlist_featured',
    label: 'navigation.featured_playlists',
    icon: 'music',
    to: ROUTES.PLAYLIST_FEATURED,
    show: false,
  },
]

export const MATCH_DROPDOWN = (intl) => [
  {
    to: ROUTES.MATCH,
    icon: 'spotify',
    iconType: 'brands',
    title: 'Spotify',
    color: 'green',
    subtitle: intl.formatMessage({ id: 'navigation.spotify.subtitle' }),
    show: true,
  },
  {
    to: ROUTES.TIKTOK,
    icon: 'tiktok',
    iconType: 'brands',
    title: 'TikTok',
    color: 'tiktok',
    subtitle: intl.formatMessage({ id: 'navigation.tiktok.subtitle' }),
    show: true,
  },
  {
    to: ROUTES.MAGAZINE,
    icon: 'typewriter',
    title: 'Magazine',
    color: 'orange',
    subtitle: intl.formatMessage({ id: 'navigation.magazine.subtitle' }),
    show: true,
  },
  {
    to: ROUTES.RADIO,
    icon: 'radio',
    title: 'Radio',
    color: 'blue',
    subtitle: intl.formatMessage({ id: 'navigation.radio.subtitle' }),
    show: true,
  },
  {
    to: ROUTES.PLAYLIST_FEATURED,
    icon: 'music',
    title: intl.formatMessage({ id: 'navigation.featured_playlists' }),
    color: 'primary',
    subtitle: intl.formatMessage({
      id: 'navigation.featured_playlists.subtitle',
    }),
    show: false,
  },
]

export const RANK_DROPDOWN = (intl) => [
  {
    to: ROUTES.RANK,
    icon: 'trophy',
    color: 'gold',
    title: intl.formatMessage({ id: 'navigation.rank' }),
    subtitle: intl.formatMessage({ id: 'rank.page.subtitle' }),
  },
  {
    to: ROUTES.POPULAR,
    icon: 'star',
    color: 'gold',
    title: intl.formatMessage({ id: 'navigation.popular' }),
    subtitle: intl.formatMessage({ id: 'popular.subtitle' }),
  },
]
