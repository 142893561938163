import React from 'react'
import Button from '@fnd/components/Button'
import { Modal } from '@fnd/components/Modal'
import parse from 'html-react-parser'
import { ROUTES } from '@fnd/screens/constants'
import { useIntl } from 'react-intl'

export const MonthlyPushLimitPopup = ({ isOpen }) => {
  const intl = useIntl()

  return (
    <Modal
      title={intl.formatMessage({ id: 'monthly_push_limit.title' })}
      isOpen={isOpen}
    >
      <div className="p-5">
        <h4 className="text-2xl mb-3">
          {parse(intl.formatMessage({ id: 'monthly_push_limit.text_1' }))}
        </h4>

        <p className="mb-3">
          {parse(intl.formatMessage({ id: 'monthly_push_limit.text_2' }))}
        </p>

        <p className="text-lg font-semibold mb-3">
          {parse(intl.formatMessage({ id: 'monthly_push_limit.text_3' }))}
        </p>

        <Button
          variant="gradient"
          className="w-full mt-3"
          to={ROUTES.PRICING}
          label={intl.formatMessage({ id: 'navigation.pricing' })}
        />
      </div>
    </Modal>
  )
}

export default MonthlyPushLimitPopup
