import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Button from '@fnd/components/Button'
import { useUserContext, userProfileSelector } from '@fnd/modules/User'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import SearchSelect from '@fnd/modules/Analysis/Search/SearchSelect'
import { toastFeedback } from '@fnd/core/libs/toast'
import classNames from 'classnames'
import ConfirmationPopup from '@fnd/components/ConfirmationPopup'
import { PLANS } from '@fnd/constants'

function ArtistForm({
  btnVariant = 'primary',
  className,
  onLimitReached,
  searchInputClasses,
  handleForm,
}) {
  const [artist, setArtist] = useState()
  const [artistId, setArtistId] = useState()
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [error, setError] = useState()
  const [limitReached, setLimitReached] = useState(false)
  const [clear, setClear] = useState(false)
  const [submit, setSubmit] = useState(false)
  const intl = useIntl()

  const { profile, updateUser } = useUserContext(
    ({ user, updateUserProfile }) => ({
      profile: userProfileSelector(user),
      updateUser: (next) => updateUserProfile(next),
    })
  )

  useEffect(() => {
    if (submit) {
      onSubmit()
    }
  }, [submit])

  useEffect(() => {
    if (onLimitReached) {
      onLimitReached(limitReached)
    }
  }, [limitReached])

  const onSubmit = async () => {
    try {
      const { user } = await spotimatchEndpoints.addArtist(artistId).toPromise()
      updateUser({
        spotify_artist_id: { $set: user.spotify_artist_id },
      })

      handleForm(user.spotify_artist_id)
      toastFeedback(
        'success',
        intl.formatMessage({ id: 'feedback.success.artist_add' })
      )
    } catch ({ response }) {
      const errorCode = response?.data?.message || 'feedback.error.default'
      const errorMessage = intl.formatMessage({ id: errorCode })

      if (errorCode === 'errors.artist.artist_limit_reached') {
        setLimitReached(true)
      }

      setError(errorMessage)
      toastFeedback('error', errorMessage)
    }
    setClear(true)
    setSubmit(false)
  }

  const onChange = (value) => {
    setClear(false)
    setArtistId(value)
  }

  const handleConfirmation = () => {
    setSubmit(true)
    setIsConfirmationOpen(false)
  }

  const handleClick = () => {
    if (!artistId) return

    if (profile.plan.name === PLANS.AGENCY) {
      onSubmit()
      return
    } else {
      setIsConfirmationOpen(true)
    }
  }

  const classes = classNames({
    'flex flex-col md:flex-row items-start gap-2': true,
    [className]: className,
  })

  const searchInputCn = classNames({
    [searchInputClasses]: searchInputClasses,
  })

  return (
    <div className={classes}>
      <SearchSelect
        id="artist-field"
        className={searchInputCn}
        name="name"
        entity="artist"
        onChange={onChange}
        onDataChange={setArtist}
        clear={clear}
        errors={error && [error]}
        isDisabled={submit}
      />

      <Button
        loading={submit}
        disabled={submit}
        icon="check"
        variant={btnVariant}
        onClick={handleClick}
        label={intl.formatMessage({ id: 'profile.my_artists.confirm_artist' })}
        className="h-[46px] w-full md:w-auto md:mt-8"
      />

      {profile.plan.name !== PLANS.AGENCY && (
        <ConfirmationPopup
          isOpen={isConfirmationOpen}
          title={intl.formatMessage({
            id: 'profile.my_artists.confirm_artist',
          })}
          onConfirm={handleConfirmation}
          bodyClasses="p-5"
          onReject={() => setIsConfirmationOpen(false)}
        >
          {artist && (
            <div className="flex items-center justify-start gap-3 mb-4">
              <img
                src={artist?.images[0]?.url}
                alt={artist?.name}
                className="w-12 h-12 rounded-full"
              />
              <h5 className="m-0">{artist?.name}</h5>
            </div>
          )}

          <p>
            <FormattedMessage id="popup.artist_select" />
          </p>
        </ConfirmationPopup>
      )}
    </div>
  )
}

export default ArtistForm
