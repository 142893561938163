import React from 'react'
import { useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import Icon from '@fnd/components/Icon'
import Info from '@fnd/components/Info'
import { formatCurrency } from '@fnd/core/libs/currency'
import { getCampaignOptions } from '@fnd/components/VipCampaign/utils'
import classNames from 'classnames'
import { addSpacesToCamelCase } from '@fnd/core/libs/helpers'

export default function VipCampaignOverview({ campaign, className }) {
  const intl = useIntl()
  const { locale } = intl

  const classes = classNames({
    'flex flex-col': true,
    [className]: className,
  })

  const campaignOptions = getCampaignOptions(intl)

  return (
    <div className={classes}>
      {campaign && (
        <>
          <Info
            small
            className="capitalize"
            label={intl.formatMessage({
              id: 'vip_campaign.field.typology.label',
            })}
          >
            <div className="flex items-center gap-1">
              {campaign.typologies.map((typology) => (
                <Badge
                  key={typology}
                  variant="primary-light"
                  className="capitalize"
                >
                  {addSpacesToCamelCase(typology)}
                </Badge>
              ))}
            </div>
          </Info>

          {(campaign?.typologies.includes('tiktok') ||
            campaign?.typologies.includes('youtube')) && (
            <>
              <Info
                small
                className="capitalize"
                label={intl.formatMessage({
                  id: 'vip_campaign.field.age.label',
                })}
                value={campaign?.age
                  .map((item) => {
                    if (item === 'all')
                      return intl.formatMessage({ id: 'messages.all.female' })
                    return item
                  })
                  .join(', ')}
              />

              <Info
                small
                className="capitalize"
                label={intl.formatMessage({
                  id: 'vip_campaign.field.gender.label',
                })}
                value={campaign?.gender
                  .map((item) => {
                    if (item === 'all')
                      return intl.formatMessage({ id: 'messages.all.female' })
                    return intl.formatMessage({ id: `messages.gender.${item}` })
                  })
                  .join(', ')}
              />

              <Info
                small
                className="capitalize"
                label={intl.formatMessage({
                  id: 'vip_campaign.field.duration.label',
                })}
                value={campaign?.duration
                  .map((item) => {
                    return `${item} ${intl.formatMessage({
                      id: 'messages.days',
                    })}`
                  })
                  .join(', ')}
              />

              <Info
                small
                className="capitalize"
                label={intl.formatMessage({
                  id: 'vip_campaign.field.country.label',
                })}
                value={campaign?.country
                  .map((item) => {
                    return intl.formatMessage({ id: `country.${item}` })
                  })
                  .join(', ')}
              />
            </>
          )}

          {(campaign?.typologies.includes('spotify') ||
            campaign?.typologies.includes('spotifyAlgo')) && (
            <>
              <Info
                small
                label={intl.formatMessage({ id: 'search_select.artist.label' })}
                valueClasses="inline-flex"
                value={
                  <a
                    href={`https://open.spotify.com/artist/${campaign?.info?.spotify?.artistId}`}
                    className="inline-flex items-center gap-3 text-primary underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={campaign?.info?.spotify?.artist?.image}
                      alt={campaign?.info?.spotify?.artist?.name}
                      className="w-8 h-8 rounded-full"
                    />
                    {campaign?.info?.spotify?.artist?.name}
                  </a>
                }
              />

              <Info
                small
                label={intl.formatMessage({ id: 'search_select.track.label' })}
                valueClasses="inline-flex"
                value={
                  <a
                    href={`https://open.spotify.com/track/${campaign?.info?.spotify?.trackId}`}
                    className="inline-flex items-center gap-3 text-primary underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={campaign?.info?.spotify?.track?.image}
                      alt={campaign?.info?.spotify?.track?.name}
                      className="w-8 h-8 rounded"
                    />
                    {campaign?.info?.spotify?.track?.name}
                  </a>
                }
              />

              <Info
                small
                className="capitalize"
                label={intl.formatMessage({ id: 'messages.genres' })}
                value={campaign?.info?.spotify?.genres.join(', ')}
              />
            </>
          )}

          {campaign?.typologies.includes('tiktok') && (
            <>
              <Info
                small
                label="TikTok Handle"
                value={
                  <a
                    href={`https://tiktok.com/@${campaign?.info?.tiktok?.handle}`}
                    className="text-primary underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    @{campaign?.info?.tiktok?.handle}
                  </a>
                }
              />

              <Info
                small
                label="TikTok Video"
                value={
                  <a
                    href={campaign?.info?.tiktok?.videoUrl}
                    className="text-primary underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {campaign?.info?.tiktok?.videoUrl}
                  </a>
                }
              />

              <Info
                small
                label={`${intl.formatMessage({
                  id: 'vip_campaign.field.tiktok_video_code.label',
                })} TikTok`}
                value={
                  <Badge variant="light" className="font-mono">
                    {campaign?.info?.tiktok?.videoCode}
                  </Badge>
                }
              />
            </>
          )}

          {campaign?.typologies.includes('tiktokTalents') && (
            <>
              <Info
                small
                label={intl.formatMessage({
                  id: 'vip_campaign.field.tiktok_sound_url.label',
                })}
                value={
                  <a
                    href={campaign?.info?.tiktokTalents?.soundUrl}
                    className="text-primary underline block truncate"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {campaign?.info?.tiktokTalents?.soundUrl}
                  </a>
                }
              />

              <Info
                small
                label={intl.formatMessage({
                  id: 'vip_campaign.field.minute.label',
                })}
                value={campaign?.info?.tiktokTalents?.minute}
              />

              <Info
                small
                label={intl.formatMessage({
                  id: 'vip_campaign.field.lyrics.label',
                })}
                valueClasses="whitespace-pre-wrap"
                value={campaign?.info?.tiktokTalents?.lyrics}
              />
            </>
          )}

          {campaign?.typologies.includes('youtube') && (
            <>
              <Info
                small
                label="YouTube Channel"
                value={
                  <a
                    href={campaign?.info?.youtube?.channelUrl}
                    className="text-primary underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {campaign?.info?.youtube?.channelUrl}
                  </a>
                }
              />

              <Info
                small
                label="YouTube Video"
                value={
                  <a
                    href={campaign?.info?.youtube?.videoUrl}
                    className="text-primary underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {campaign?.info?.youtube?.videoUrl}
                  </a>
                }
              />
            </>
          )}

          {campaign?.typologies.includes('spotify') && (
            <Info
              small
              className="capitalize"
              label={`Budget / ${campaignOptions['spotify']?.label}`}
              value={
                <span className="font-semibold text-green">
                  {formatCurrency(campaign?.budget?.spotify, locale)}
                </span>
              }
            />
          )}

          {campaign?.typologies.includes('spotifyAlgo') && (
            <Info
              small
              label={`Budget / ${campaignOptions['spotifyAlgo']?.label}`}
              value={
                <span className="font-semibold text-green">
                  {formatCurrency(campaign?.budget?.spotifyAlgo, locale)}
                </span>
              }
            />
          )}

          {campaign?.typologies.includes('tiktok') && (
            <Info
              small
              label={`Budget / ${campaignOptions['tiktok']?.label}`}
              value={
                <span className="font-semibold text-green">
                  {formatCurrency(campaign?.budget?.tiktok, locale)}
                </span>
              }
            />
          )}

          {campaign?.typologies.includes('youtube') && (
            <Info
              small
              label={`Budget / ${campaignOptions['youtube']?.label}`}
              value={
                <span className="font-semibold text-green">
                  {formatCurrency(campaign?.budget?.youtube, locale)}
                </span>
              }
            />
          )}

          <Info
            small
            label={intl.formatMessage({
              id: 'vip_campaign.field.manager.label',
            })}
            value={
              campaign?.budget?.manager ? (
                <Icon name="check" className="text-green" />
              ) : (
                <span>{intl.formatMessage({ id: 'messages.no' })}</span>
              )
            }
          />

          <Info
            small
            label={intl.formatMessage({
              id: 'vip_campaign.field.notes.label',
            })}
            value={campaign?.notes}
          />
        </>
      )}
    </div>
  )
}
