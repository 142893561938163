import React, { useState } from 'react'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Field from '@fnd/components/Field'
import classNames from 'classnames'
import { useDiscountStore } from '@fnd/store'
import { CURRENCY_SYMBOL } from '@fnd/constants'
import { useIntl } from 'react-intl'

export default function DiscountForm({ plan, price, className }) {
  const [couponCode, setCouponCode] = useState('')
  const intl = useIntl()
  const {
    applyDiscountCode,
    applyDiscountError,
    canUseDiscount,
    clearDiscount,
    discount,
    isLoading,
  } = useDiscountStore()

  const classes = classNames({
    'discount-form': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <div className="form-inline">
        <Field
          name="discount"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value.toUpperCase())}
          placeholder="Coupon"
        />
        <Button
          label={intl.formatMessage({ id: 'messages.apply' })}
          variant="light"
          loading={isLoading}
          onClick={() => applyDiscountCode(plan, price, couponCode)}
        />
      </div>

      {canUseDiscount && discount && discount.amount && (
        <div className="col-span-2 mt-2">
          <Badge
            icon="tag"
            variant="green-light"
            className="items-center"
            label={`${discount?.code} ${discount.amount}${
              discount.is_percent ? '%' : CURRENCY_SYMBOL
            }`}
            value={intl.formatMessage({ id: 'coupon.applied' })}
          >
            <Button
              small
              variant="link-plain"
              icon="times"
              loading={isLoading}
              className="ml-2"
              onClick={() => clearDiscount(plan, price)}
            />
          </Badge>
        </div>
      )}

      {!canUseDiscount && applyDiscountError !== '' && (
        <div className="col-span-2 mt-2">
          <Badge
            icon="tag"
            className="items-center"
            variant="red-light"
            label={discount?.code}
            value={intl.formatMessage({ id: applyDiscountError })}
          >
            <Button
              small
              variant="link-plain"
              icon="times"
              loading={isLoading}
              className="ml-2"
              onClick={() => clearDiscount(plan, price)}
            />
          </Badge>
        </div>
      )}
    </div>
  )
}
