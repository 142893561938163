import { make } from './endpoints'
import { getExistingLocale } from '@fnd/translations'
import { saveAs } from 'file-saver'

const registerMe = (isLogin, metadata) =>
  isLogin
    ? make.post('/users/me', { query: { isLogin }, body: { metadata } })
    : make.post('/users/me', {
      body: {
        preferred_language: getExistingLocale(),
        metadata,
      },
    })

const getMe = () => make.get('/users/me')

const getOwnedTracks = (campaignId) =>
  make.get(`/users/me/activity/owned/${campaignId ?? ''}`)

const getUserDashboard = () => make.get('/users/me/dashboard')

const addArtist = (artistId) =>
  make.post('users/me/artists', { body: { artistId } })

const removeArtist = (artistId) =>
  make.delete('users/me/artists', { body: { artistId } })

const getUserContacts = (userId) => make.get(`/users/${userId}`)

const updateUserContacts = (contacts) =>
  make.post(`/users/contacts`, { body: { contacts } })

const updateUserSettings = (key, value) =>
  make.put(`/users/settings/${key}`, { body: { value } })

const completeProfile = () => make.post(`/users/complete-profile`)

const getUserProfile = (userId) => make.get(`/users/${userId}`)

const getUserPlaylistsIds = (userId) => make.get(`/users/${userId}/playlists`)

const getUserType = (type) => make.get(`/users/type/${type}`)

const createFeaturedPlaylist = ({
  userId,
  playlistId,
  userName,
  playlistName,
  numTracks,
  numFollowers,
  tags,
}) =>
  make.post(`/users/${userId}/playlists`, {
    body: {
      playlistId,
      userName,
      playlistName,
      numTracks,
      numFollowers,
      tags,
    },
  })

export const verifyEmail = (verifyToken) =>
  make.post('/users/email/verify', { body: { token: verifyToken } })

export const sendVerifyEmail = () => make.post('users/email/verify/send')

export const saveBillingInfo = (billingInfo) =>
  make.post('/users/billing', { body: billingInfo })

export const deleteUser = () => make.delete('/users/delete').toPromise()

export const exportUserData = () =>
  make
    .get('/users/export/csv', {
      responseType: 'blob',
    })
    .toPromise()
    .then((response) => saveAs(response, 'user_data.csv'))

export const presentGift = (giftLocation) =>
  make.post('/users/referral/gift', { body: { giftLocation } })

export const setReferralCodeUsed = (referralCode) =>
  make.put('/users/referral/set', { body: { referralCode } })

export default {
  addArtist,
  completeProfile,
  createFeaturedPlaylist,
  deleteUser,
  exportUserData,
  getMe,
  getOwnedTracks,
  getUserContacts,
  getUserDashboard,
  getUserPlaylistsIds,
  getUserProfile,
  getUserType,
  presentGift,
  registerMe,
  removeArtist,
  saveBillingInfo,
  sendVerifyEmail,
  setReferralCodeUsed,
  updateUserContacts,
  updateUserSettings,
  verifyEmail,
}
