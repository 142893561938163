import React, { useState, useEffect } from 'react'
import { getPlatformUrl, getUsername } from '@fnd/core/libs/platforms'
import Info from '@fnd/components/Info'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { FormattedMessage } from 'react-intl'

export function CuratorContacts({ contacts: initialContacts, curatorId }) {
  const [contacts, setContacts] = useState(initialContacts)

  const fetchCuratorContacts = async () => {
    const res = await spotimatchEndpoints.getUserContacts(curatorId).toPromise()
    setContacts(res?.contacts)
  }

  useEffect(() => {
    if (curatorId && !contacts) {
      fetchCuratorContacts()
    }
  }, [curatorId, initialContacts])

  return (
    <>
      {contacts && contacts?.email && (
        <Info
          icon="envelope"
          label="Email"
          value={
            <a
              href={`mailto:${contacts.email}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contacts.email}
            </a>
          }
        />
      )}

      {contacts && contacts?.phone && (
        <Info
          icon="phone"
          label={<FormattedMessage id="field.phone.label" />}
          value={
            <a
              href={`tel:${contacts.phone}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contacts.phone}
            </a>
          }
        />
      )}

      {contacts && contacts.tiktok && (
        <Info
          icon="tiktok"
          iconType="brands"
          label="TikTok"
          value={
            <a
              href={getPlatformUrl('tiktok', contacts?.tiktok)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contacts?.tiktok}
            </a>
          }
        />
      )}

      {contacts && contacts?.facebook && (
        <Info
          icon="facebook"
          iconType="brands"
          label="Facebook"
          value={
            <a
              href={getPlatformUrl('facebook', contacts?.facebook)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contacts?.facebook}
            </a>
          }
        />
      )}

      {contacts && contacts?.instagram && (
        <Info
          icon="instagram"
          iconType="brands"
          label="Instagram"
          value={
            <a
              href={getPlatformUrl('instagram', contacts?.instagram)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getUsername(contacts?.instagram)}
            </a>
          }
        />
      )}

      {contacts && contacts?.linkedin && (
        <Info
          icon="linkedin"
          iconType="brands"
          label="LinkedIn"
          value={
            <a
              href={getPlatformUrl('linkedin', contacts?.linkedin)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getUsername(contacts?.linkedin)}
            </a>
          }
        />
      )}

      {contacts && contacts?.twitter && (
        <Info
          icon="twitter"
          iconType="brands"
          label="Twitter"
          value={
            <a
              href={getPlatformUrl('twitter', contacts?.twitter)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getUsername(contacts?.twitter)}
            </a>
          }
        />
      )}

      {contacts && contacts?.youtube && (
        <Info
          icon="youtube"
          iconType="brands"
          label="YouTube"
          value={
            <a
              href={getPlatformUrl('youtube', contacts?.youtube)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getUsername(contacts?.youtube)}
            </a>
          }
        />
      )}

      {contacts && contacts?.website && (
        <Info
          icon="browser"
          label="Website"
          value={
            <a
              href={getPlatformUrl('website', contacts?.website)}
              target="_blank"
              rel="noopener noreferrer"
              className="truncate"
            >
              {contacts?.website}
            </a>
          }
        />
      )}
    </>
  )
}

export default CuratorContacts
