import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import ProfileCompletion from '@fnd/modules/Profile/ProfileOverview/ProfileCompletion'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import Spinner from '@fnd/components/Spinner'
import { humanReadableDuration, getDateFormat } from '@fnd/core/libs/helpers'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { CuratorScore, DashboardCard } from '@fnd/components/Card'
import { ROUTES } from '@fnd/screens/constants'
import { TRACK_USER_DASHBOARD_ANALYTICS_COOLDOWN } from '@fnd/constants'
import dayjs from 'dayjs'

export default function Dashboard({ userProfile }) {
  const [artistData, setArtistData] = useState([])
  const [curatorData, setCuratorData] = useState([])
  const [curatorStats, setCuratorStats] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [missingData, setMissingData] = useState(false)
  const [updatedAt, setUpdatedAt] = useState()
  const intl = useIntl()
  const { locale } = intl

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const artistData = await spotimatchEndpoints
        .getUserDashboard()
        .toPromise()
      setArtistData(artistData)
      const curatorData = await spotimatchEndpoints
        .getCuratorDashboard(userProfile.id)
        .toPromise()
      const curatorStats = await spotimatchEndpoints
        .getCuratorStats(userProfile.id)
        .toPromise()

      setCuratorData(curatorData)
      setCuratorStats(curatorStats)
      setUpdatedAt(artistData?.updated_at)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      setMissingData(true)
      console.log(error)
    }
  }

  const updateDashboardData = async () => {
    if (
      dayjs(updatedAt).isBefore(
        dayjs().subtract(TRACK_USER_DASHBOARD_ANALYTICS_COOLDOWN, 'millisecond')
      )
    ) {
      setIsLoading(true)
      await spotimatchEndpoints.updateDashboardAnalytics()
      await fetchData()
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (
      !artistData.acceptedSubmissions?.total ||
      !curatorData.totalSubmissions?.total
    ) {
      setMissingData(true)
    } else {
      setMissingData(false)
    }
  }, [artistData, curatorData])

  const artistLink = `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ACTIVITY}`
  const curatorLink = `${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_PLAYLISTS}`

  return (
    <ProfileWrapper
      icon="tachometer"
      title={intl.formatMessage({ id: 'navigation.dashboard' })}
    >
      <ProfileCompletion />

      {isLoading && <Spinner />}

      {!isLoading && (
        <div className="dashboard-section">
          <CuratorScore
            curator={{ ...userProfile, ...curatorStats }}
            full
            hideTitle
          />
        </div>
      )}

      {!isLoading && missingData && (
        <Alert
          variant="primary-light"
          icon="hourglass"
          className="mb-8"
          label={intl.formatMessage({ id: 'profile.dashboard.missing_data' })}
        />
      )}

      {!isLoading && (
        <div className="dashboard-section">
          <div className="dashboard-section-header">
            <h2>{intl.formatMessage({ id: 'profile.dashboard.artist' })}</h2>

            <div className="flex items-center gap-2">
              <span className="text-sm opacity-70">
                {intl.formatMessage({ id: 'messages.last_update' })}
                {': '}
                {dayjs(updatedAt).format(getDateFormat(locale, true))}
              </span>
              <Button
                small
                icon="sync"
                variant="ghost"
                loading={isLoading}
                onClick={updateDashboardData}
              >
                {intl.formatMessage({ id: 'messages.update' })}
              </Button>
            </div>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            <DashboardCard
              to={artistLink}
              label={intl.formatMessage({
                id: 'profile.dashboard.artist.accepted_submissions',
              })}
              value={artistData.acceptedSubmissions?.total}
              variation={artistData.acceptedSubmissions?.variation}
              color="green"
              chartData={artistData.acceptedSubmissions?.data}
              tooltip={intl.formatMessage({
                id: 'profile.dashboard.artist.accepted_submissions.tooltip',
              })}
            />

            <DashboardCard
              to={artistLink}
              label={intl.formatMessage({
                id: 'profile.dashboard.artist.pending_submissions',
              })}
              value={artistData.pendingSubmissions?.total}
              variation={artistData.pendingSubmissions?.variation}
              color="orange"
              chartData={artistData.pendingSubmissions?.data}
              tooltip={intl.formatMessage({
                id: 'profile.dashboard.artist.pending_submissions.tooltip',
              })}
            />

            <DashboardCard
              to={artistLink}
              label={intl.formatMessage({
                id: 'profile.dashboard.artist.rejected_submissions',
              })}
              value={artistData.rejectedSubmissions?.total}
              variation={artistData.rejectedSubmissions?.variation}
              variationInvert={true}
              color="red"
              chartData={artistData.rejectedSubmissions?.data}
              tooltip={intl.formatMessage({
                id: 'profile.dashboard.artist.rejected_submissions.tooltip',
              })}
            />

            {artistData.acceptedRate && (
              <DashboardCard
                to={artistLink}
                label={intl.formatMessage({
                  id: 'profile.dashboard.artist.accepted_rate_submissions',
                })}
                value={`${artistData.acceptedRate?.total}%`}
                variation={artistData.acceptedRate?.variation}
                variationInvert={true}
                color="green"
                chartData={artistData.acceptedRate?.data}
                tooltip={intl.formatMessage({
                  id: 'profile.dashboard.artist.accepted_rate_submissions.tooltip',
                })}
              />
            )}

            {artistData.rejectedRate && (
              <DashboardCard
                to={artistLink}
                label={intl.formatMessage({
                  id: 'profile.dashboard.artist.rejected_rate_submissions',
                })}
                value={`${artistData.rejectedRate?.total}%`}
                variation={artistData.rejectedRate?.variation}
                variationInvert={true}
                color="red"
                chartData={artistData.rejectedRate?.data}
                tooltip={intl.formatMessage({
                  id: 'profile.dashboard.artist.rejected_rate_submissions.tooltip',
                })}
              />
            )}
          </div>
        </div>
      )}

      {!isLoading && (
        <div className="dashboard-section">
          <div className="dashboard-section-header">
            <h2>{intl.formatMessage({ id: 'profile.dashboard.curator' })}</h2>

            <div className="flex items-center gap-2">
              <span className="text-sm opacity-70">
                {intl.formatMessage({ id: 'messages.last_update' })}
                {': '}
                {dayjs(updatedAt).format(getDateFormat(locale, true))}
              </span>
              <Button
                small
                icon="sync"
                variant="ghost"
                loading={isLoading}
                onClick={updateDashboardData}
              >
                {intl.formatMessage({ id: 'messages.update' })}
              </Button>
            </div>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
            <DashboardCard
              to={curatorLink}
              label={intl.formatMessage({
                id: 'profile.dashboard.curator.total_submissions',
              })}
              value={curatorData.totalSubmissions?.total}
              variation={curatorData.totalSubmissions?.variation}
              color="primary"
              chartData={curatorData.totalSubmissions?.data}
              tooltip={intl.formatMessage({
                id: 'profile.dashboard.curator.total_submissions.tooltip',
              })}
            />

            <DashboardCard
              to={curatorLink}
              label={intl.formatMessage({
                id: 'profile.dashboard.curator.gained_followers',
              })}
              value={curatorData.totalNewFollowers?.total}
              variation={curatorData.totalNewFollowers?.variation}
              color="secondary"
              chartData={curatorData.totalNewFollowers?.data}
              tooltip={intl.formatMessage({
                id: 'profile.dashboard.curator.gained_followers.tooltip',
              })}
            />

            <DashboardCard
              to={curatorLink}
              label={intl.formatMessage({
                id: 'profile.dashboard.curator.time_mean',
              })}
              value={humanReadableDuration(curatorData.timeMean?.total, locale)}
              variation={curatorData.timeMean?.variation}
              variationInvert={true}
              color="tertiary"
              chartData={curatorData.timeMean?.data}
              tooltip={intl.formatMessage({
                id: 'profile.dashboard.curator.time_mean.tooltip',
              })}
            />

            <DashboardCard
              to={curatorLink}
              label={intl.formatMessage({
                id: 'profile.dashboard.curator.accepted_submissions',
              })}
              value={curatorData.acceptedSubmissions?.total}
              variation={curatorData.acceptedSubmissions?.variation}
              color="green"
              chartData={curatorData.acceptedSubmissions?.data}
              tooltip={intl.formatMessage({
                id: 'profile.dashboard.curator.accepted_submissions.tooltip',
              })}
            />

            <DashboardCard
              to={curatorLink}
              label={intl.formatMessage({
                id: 'profile.dashboard.curator.pending_submissions',
              })}
              value={curatorData.pendingSubmissions?.total}
              variation={curatorData.pendingSubmissions?.variation}
              color="orange"
              chartData={curatorData.pendingSubmissions?.data}
              tooltip={intl.formatMessage({
                id: 'profile.dashboard.curator.pending_submissions.tooltip',
              })}
            />

            <DashboardCard
              to={curatorLink}
              label={intl.formatMessage({
                id: 'profile.dashboard.curator.rejected_submissions',
              })}
              value={curatorData.rejectedSubmissions?.total}
              variation={curatorData.rejectedSubmissions?.variation}
              variationInvert={true}
              color="red"
              chartData={curatorData.rejectedSubmissions?.data}
              tooltip={intl.formatMessage({
                id: 'profile.dashboard.curator.rejected_submissions.tooltip',
              })}
            />

            {curatorData.acceptedRate && (
              <DashboardCard
                to={curatorLink}
                label={intl.formatMessage({
                  id: 'profile.dashboard.curator.accepted_rate_submissions',
                })}
                value={`${curatorData.acceptedRate?.total}%`}
                variation={curatorData.acceptedRate?.variation}
                variationInvert={true}
                color="green"
                chartData={curatorData.acceptedRate?.data}
                tooltip={intl.formatMessage({
                  id: 'profile.dashboard.curator.accepted_rate_submissions.tooltip',
                })}
              />
            )}

            {curatorData.rejectedRate && (
              <DashboardCard
                to={curatorLink}
                label={intl.formatMessage({
                  id: 'profile.dashboard.curator.rejected_rate_submissions',
                })}
                value={`${curatorData.rejectedRate?.total}%`}
                variation={curatorData.rejectedRate?.variation}
                variationInvert={true}
                color="red"
                chartData={curatorData.rejectedRate?.data}
                tooltip={intl.formatMessage({
                  id: 'profile.dashboard.curator.rejected_rate_submissions.tooltip',
                })}
              />
            )}
          </div>
        </div>
      )}
    </ProfileWrapper>
  )
}
