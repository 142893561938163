import React from 'react'
import ProfileNavSidebar from './ProfileNavSidebar'
import ProfileNavMobile from './ProfileNavMobile'

export default function ProfileNavigation({ isDesktop, userProfile }) {
  return isDesktop ? (
    <ProfileNavSidebar userProfile={userProfile} />
  ) : (
    <ProfileNavMobile />
  )
}
