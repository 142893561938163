import { useEffect, useState } from 'react'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { catchError, finalize } from 'rxjs/operators'
import { EMPTY } from 'rxjs'

export default function useCheckQueueLimits({ userId, trackId, playlistId }) {
  const initialPushToPlaylistState = {
    error: void 0,
    isLoading: true,
    isTrackInQueue: false,
  }

  const [pushToPlaylistState, setState] = useState(initialPushToPlaylistState)

  useEffect(() => {
    const subscription = spotimatchEndpoints
      .checkQueueLimits({ userId, trackId, playlistId })
      .pipe(
        catchError((error) => {
          setState((state) => ({ ...state, error: error.message }))

          return EMPTY
        }),

        finalize(() => setState((state) => ({ ...state, isLoading: false })))
      )
      .subscribe((response) =>
        setState((state) => ({
          ...state,
          error: void 0,
          isTrackInQueue: response.is_track_in_queue,
        }))
      )

    return () => subscription.unsubscribe()
  }, [userId, trackId, playlistId])

  return pushToPlaylistState
}
