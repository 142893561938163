import { create } from 'zustand'

export const useTrackFeedbackStore = create((set) => ({
  showFeedback: false,
  setShowFeedback: (showFeedback) => set({ showFeedback }),
  rejectionReason: null,
  setRejectionReason: (rejectionReason) => set({ rejectionReason }),
}))

export default useTrackFeedbackStore
