import React from 'react'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import { useLocation, useParams } from 'react-router-dom'
import PlaylistQueueFeedback from '@fnd/components/PlaylistQueue/PlaylistQueueFeedback'
import PlaylistQueue from '@fnd/components/PlaylistQueue/PlaylistQueue'
import { useIntl } from 'react-intl'

export const PlaylistSubmissions = () => {
  const intl = useIntl()
  const { playlistId } = useParams()
  const location = useLocation()
  const playlistName = location.state?.playlistName
  const playlistImage = location.state?.playlistImage

  return (
    <ProfileWrapper
      back
      icon="list-music"
      image={playlistImage}
      title={
        playlistName ?? intl.formatMessage({ id: 'navigation.my_activity' })
      }
    >
      <div className="flex">
        <div className="w-full">
          <PlaylistQueue playlistId={playlistId} />

          <PlaylistQueueFeedback playlistId={playlistId} />
        </div>
      </div>
    </ProfileWrapper>
  )
}

export default PlaylistSubmissions
