import React from 'react'
import Button from '@fnd/components/Button'
import { FormattedMessage } from 'react-intl'
import useBlockUnblockUser from './useBlockUnblockUser'
import classNames from 'classnames'

export default ({
  className,
  blockedBtnVariant = 'primary-light',
  btnVariant = 'red-light',
  blockedBtnClasses,
  btnClasses,
  blockedIcon = 'unlock',
  callback,
  checkOnlineStatus = false,
  disabled,
  icon = 'user-lock',
  isBlockedUser,
  spotifyId,
  showLabel = true,
  showTitle = true,
  ...props
}) => {
  const [isLoading, isBlocked, toggle] = useBlockUnblockUser(
    spotifyId,
    isBlockedUser,
    checkOnlineStatus
  )

  const handleButtonClick = () => {
    toggle()
    if (callback) callback(!!isBlocked, spotifyId)
  }

  const classes = classNames({
    [className]: className,
  })

  const buttonClasses = classNames({
    'block-user': true,
    blocked: isBlocked,
    [btnClasses]: btnClasses,
    [blockedBtnClasses]: blockedBtnClasses && isBlocked,
  })

  return (
    <div className={classes}>
      {showTitle && (
        <p className="mb-3">
          <FormattedMessage id="block_user.text" />
        </p>
      )}
      <Button
        className={buttonClasses}
        disabled={disabled}
        loading={isLoading}
        variant={isBlocked ? blockedBtnVariant : btnVariant}
        tooltip={
          !showLabel && (
            <FormattedMessage
              id={isBlocked ? 'block_user.unblock' : 'block_user.block'}
            />
          )
        }
        label={
          showLabel && (
            <FormattedMessage
              id={isBlocked ? 'block_user.unblock' : 'block_user.block'}
            />
          )
        }
        icon={isBlocked ? blockedIcon : icon}
        onClick={handleButtonClick}
        {...props}
      />
    </div>
  )
}
