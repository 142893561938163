import React, { useState, useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Badge from '@fnd/components/Badge'
import DiscountForm from './DiscountForm'
import classNames from 'classnames'
import {
  isUserAuthorizedSelector,
  userProfileSelector,
  useUserContext,
} from '@fnd/modules/User'
import { CURRENCY_SYMBOL } from '@fnd/constants'
import { useAffiliateStore, useDiscountStore } from '@fnd/store'

export default function PaymentCalculator({
  autoApply = false,
  planName,
  billing = 'one-time',
  className,
  creditsDiscount,
  disabled = false,
  disableAffiliate,
  disableCredits,
  disableCoupon = false,
  maxCredits,
  onChange,
  price,
}) {
  const [creditsScoreUsed, setCreditsScoreUsed] = useState(0)
  const { affiliate, canUseAffiliate } = useAffiliateStore()
  const {
    applyDiscountCode,
    calculateDiscountAmount,
    canUseDiscount,
    discount,
  } = useDiscountStore()

  const { profile } = useUserContext(({ user, updateUserProfile }) => ({
    profile: userProfileSelector(user),
    isAuthorized: isUserAuthorizedSelector(user),
    updateReferral: (next) => updateUserProfile({ referral: { $set: next } }),
    updateUserBillingInfo: (next) =>
      updateUserProfile({ billingInfo: { $set: next } }),
  }))

  const canUseReferralScore =
    profile?.referral?.score > 0 && creditsScoreUsed === 0

  const [paymentPrice, setPaymentPrice] = useState(parseFloat(price))
  const [affiliateDiscount, setAffiliateDiscount] = useState(0)
  const [discountPercentage, setDiscountPercentage] = useState(0)
  const [useCredits, setUseCredits] = useState(autoApply && canUseReferralScore)
  const intl = useIntl()

  const calculateAffiliatePrice = (price) => {
    if (
      affiliate?.code &&
      affiliate?.discount_percentage &&
      !disableAffiliate
    ) {
      setAffiliateDiscount(affiliate?.discount_percentage)
      return price * (1 - affiliate?.discount_percentage / 100)
    }
    return price
  }

  const areCreditsDisabled = () => {
    return (!canUseReferralScore && !useCredits) || disabled || maxCredits === 0
  }

  const handleUseCredits = async () => {
    if (disableCredits) {
      return
    }

    const basePrice =
      discount && canUseDiscount
        ? price - calculateDiscountAmount(price)
        : calculateAffiliatePrice(price)
    let creditsToUse = 0

    if (useCredits) {
      const { score } = profile.referral
      creditsToUse = Math.floor(Math.min(score, basePrice * creditsDiscount))

      if (maxCredits && creditsToUse > maxCredits) {
        creditsToUse = maxCredits
      }
    }

    const updatedPrice = basePrice - creditsToUse
    setPaymentPrice(updatedPrice)
    setDiscountPercentage((1 - updatedPrice / price) * 100)
    setCreditsScoreUsed(creditsToUse)

    if (onChange) {
      onChange(updatedPrice, creditsToUse, affiliateDiscount)
    }
  }

  useEffect(() => {
    handleUseCredits()
  }, [price, useCredits, disableCredits, canUseDiscount, affiliate])

  useEffect(() => {
    applyDiscountCode(planName, price)
    canUseAffiliate()
    handleUseCredits()
  }, [])

  const classes = classNames({
    'payment-calculator': true,
    [className]: className,
  })

  const PaymentDiscount = () => {
    if (discountPercentage > 0) {
      return (
        <Badge variant="green-light" className="payment-discount">
          {discountPercentage.toFixed(0)}%
        </Badge>
      )
    }
  }

  const PaymentOneTime = () => {
    if (!affiliate || !useCredits) {
      return (
        <div className="payment-card">
          <label>
            <FormattedMessage id="popup.payment.amount" />
          </label>
          <div>
            <h4 className="text-green">
              {CURRENCY_SYMBOL}
              {paymentPrice.toFixed(2)}
            </h4>
            <span className="text-small uppercase tracking-wide opacity-70">
              <FormattedMessage id="messages.one_time_payment" />
            </span>
          </div>
          <PaymentDiscount />
        </div>
      )
    } else if (affiliate || useCredits) {
      return (
        <div className="payment-card payment-one-time sale">
          <label>
            <FormattedMessage id="popup.payment.amount" />
          </label>
          <div>
            {price > paymentPrice && (
              <h4 className="font-medium opacity-50 line-through mr-2">
                {CURRENCY_SYMBOL}
                {price.toFixed(2)}
              </h4>
            )}
            <h4 className="font-bold text-green">
              {CURRENCY_SYMBOL}
              {paymentPrice.toFixed(2)}
            </h4>
            <span className="text-small uppercase tracking-wide opacity-70">
              <FormattedMessage id="messages.one_time_payment" />
            </span>
          </div>
          <PaymentDiscount />
        </div>
      )
    }
  }

  const PaymentMonthly = () => {
    if (!affiliate && !useCredits) {
      return (
        <div className="payment-card payment-monthly">
          <label>
            <FormattedMessage id="popup.payment.amount" />
          </label>

          <div>
            <h4 className="text-green">
              {CURRENCY_SYMBOL}
              {(paymentPrice / 12).toFixed(2)}
              <span className="ml-1">
                {'/'}
                <FormattedMessage id="messages.month" />
              </span>
            </h4>
            <span className="text-small uppercase tracking-wide opacity-70">
              {creditsScoreUsed > 0 ? (
                <FormattedMessage id="messages.billed_for_first_year" />
              ) : (
                <FormattedMessage id="messages.billed_yearly" />
              )}
            </span>
          </div>
          <PaymentDiscount />
        </div>
      )
    } else if (affiliate || useCredits) {
      return (
        <div className="payment-card payment-monthly sale">
          <label>
            <FormattedMessage id="popup.payment.amount" />
          </label>
          <div>
            <h4 className="font-medium opacity-50 line-through mr-2">
              {CURRENCY_SYMBOL}
              {(price / 12).toFixed(2)}
              <span className="ml-1">
                {'/'}
                <FormattedMessage id="messages.month" />
              </span>
            </h4>
            <h4 className="font-bold text-green">
              {CURRENCY_SYMBOL}
              {(paymentPrice / 12).toFixed(2)}
              <span className="ml-1">
                {'/'}
                <FormattedMessage id="messages.month" />
              </span>
            </h4>
            <span className="text-small uppercase tracking-wide opacity-70">
              <FormattedMessage id="messages.billed_yearly" />
            </span>
          </div>
          <PaymentDiscount />
        </div>
      )
    }
  }

  return (
    <div className={classes}>
      <div
        className={classNames({
          'grid gap-4': true,
          'grid-cols-2': !areCreditsDisabled(),
        })}
      >
        {billing === 'one-time' && <PaymentOneTime />}
        {billing === 'monthly' && <PaymentMonthly />}

        {!areCreditsDisabled() && (
          <div className="payment-card">
            <p className="text-label">
              <FormattedMessage
                id="popup.payment.you_have_score"
                values={{
                  bonusScore: profile.referral.score - creditsScoreUsed,
                }}
              />
            </p>

            <label
              className={classNames({
                'cursor-not-allowed opacity-50': areCreditsDisabled(),
              })}
            >
              <input
                type="checkbox"
                checked={useCredits}
                onChange={(e) => setUseCredits(e.target.checked)}
                disabled={areCreditsDisabled()}
              />
              {canUseReferralScore ? (
                <FormattedMessage id="popup.payment.use_my_credits" />
              ) : (
                <FormattedMessage id="popup.payment.credit_used" />
              )}
            </label>
          </div>
        )}

        {!(discount && canUseDiscount) &&
          !disableAffiliate &&
          affiliate &&
          affiliate.discount_percentage && (
            <div className="col-span-2">
              <Badge
                variant="green-light"
                label={intl.formatMessage({ id: 'affiliate.applied' })}
                value={`${affiliate?.code} ${affiliate.discount_percentage}%`}
              />
            </div>
          )}

        {!disableCoupon && (
          <DiscountForm
            className="col-span-2"
            disableAffiliate={disableAffiliate}
            plan={planName}
            price={price}
          />
        )}
      </div>
    </div>
  )
}
