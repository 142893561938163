import { make } from './endpoints'

export const flagPlaylist = (playlistId) =>
  make.post(`/reports/${playlistId}`).toPromise()

export const checkReportedPlaylist = (playlistId) =>
  make.get(`/reports/${playlistId}`).toPromise()

export const unflagPlaylist = (playlistId) =>
  make.delete(`/reports/${playlistId}`).toPromise()

export default {
  flagPlaylist,
  checkReportedPlaylist,
  unflagPlaylist,
}
