import React from 'react'
import Badge from '@fnd/components/Badge'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

function TrackPopularityBadge({ popularity, className }) {
  const intl = useIntl()

  const classes = classNames({
    [className]: className,
  })

  return (
    <Badge
      variant="light"
      label={intl.formatMessage({ id: 'messages.track_popularity' })}
      value={`${popularity}%`}
      icon="star"
      className={classes}
    />
  )
}

export default TrackPopularityBadge
