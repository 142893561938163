import React from 'react'
import { useIntl } from 'react-intl'
import Modal from 'react-modal'
import { ModalHeader } from '@fnd/components/Modal'
import PaymentGateway from './PaymentGateway'

export default function PaymentPopup(props) {
  const intl = useIntl()
  const { children, onClose } = props

  return (
    <Modal
      isOpen
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      overlayClassName="modal show"
      className="modal-dialog modal-center modal-center modal-xs"
    >
      <div className="modal-content">
        <ModalHeader
          title={intl.formatMessage({ id: 'payment.title' })}
          onClose={onClose}
        />
        <div className="modal-body">
          {children && <div className="pt-4 px-4">{children}</div>}
          <PaymentGateway {...props} />
        </div>
      </div>
    </Modal>
  )
}
