import { useState, createContext, useContext, useEffect } from 'react'
import { ROUTES } from '@fnd/screens/constants'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { isUserAuthorizedSelector } from '@fnd/modules/User'
import { useNavigate } from 'react-router-dom'

export function useEmailVerify() {
  const [isEmailVerifyPopupOpen, setEmailVerifyPopupOpen] = useState(false)
  const [emailVerifyResult, setVerifyResult] = useState(null)
  const navigate = useNavigate()

  const openEmailVerifyPopup = (result = null) => {
    setEmailVerifyPopupOpen(true)
    if (['success', 'error'].includes(result)) {
      setVerifyResult(result)
    }
  }
  const closeEmailVerifyPopup = () => {
    setEmailVerifyPopupOpen(false)
    if (emailVerifyResult) {
      setVerifyResult(null)
    }
    navigate(`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.OVERVIEW}`)
  }
  const onSubmitEmailVerify = () => {
    navigate(`${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.OVERVIEW}`)
    closeEmailVerifyPopup()
  }

  return {
    isEmailVerifyPopupOpen,
    emailVerifyResult,
    openEmailVerifyPopup,
    closeEmailVerifyPopup,
    onSubmitEmailVerify,
  }
}

export const EmailVerifyContext = createContext({
  openEmailVerifyPopup: () => void 0,
})

export const useSubmitEmailVerification = (
  successfulCallback = () => void 0
) => {
  const { openEmailVerifyPopup } = useContext(EmailVerifyContext)
  const { isAuthorized } = useUserContext(({ user }) => ({
    isAuthorized: isUserAuthorizedSelector(user),
  }))

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const token = params.get('token')

    if (!isAuthorized || !token) {
      return
    }

    spotimatchEndpoints
      .verifyEmail(token)
      .toPromise()
      .then(() => {
        successfulCallback()
        openEmailVerifyPopup('success')
      })
      .catch(({ response }) => {
        if (response.status === 400) {
          openEmailVerifyPopup('error')
        }
      })
  }, [isAuthorized])
}
