import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { nanoid } from 'nanoid'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import ModalHeader from './ModalHeader'

export const Modal = ({
  title,
  overlayClose,
  trigger,
  isOpen,
  onClose,
  bodyClasses,
  triggerClasses,
  children,
  className,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen)

  const classes = classNames({
    'modal-info': true,
    [className]: className,
  })

  const modalBodyClasses = classNames({
    'modal-body': true,
    [bodyClasses]: bodyClasses,
  })

  const modalTriggerClasses = classNames({
    'modal-trigger': true,
    [triggerClasses]: triggerClasses,
  })

  const handleEscape = (event) => {
    if (event.keyCode === 27) {
      handleCloseModal()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscape)

    return () => {
      document.removeEventListener('keydown', handleEscape)
      handleCloseModal()
    }
  }, [])

  useEffect(() => {
    setIsModalOpen(isOpen)
  }, [isOpen])

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add('lock-scroll')
    } else {
      document.body.classList.remove('lock-scroll')
    }
  }, [isModalOpen])

  const handleOpenModalClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    document.body.classList.remove('lock-scroll')
    if (onClose) onClose()
  }

  const ModalContent = () => {
    return ReactDOM.createPortal(
      <AnimatePresence mode="wait">
        {isModalOpen && (
          <motion.div
            id={`modal-${nanoid()}`}
            key={`modal-${nanoid()}`}
            className="modal-container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="modal-overlay"
              onClick={overlayClose ? handleCloseModal : null}
            />
            <motion.div
              className={classes}
              initial={{ y: '100%' }}
              animate={{ y: '0%' }}
              exit={{ y: '100%' }}
              transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            >
              {title && (
                <ModalHeader title={title} onClose={handleCloseModal} />
              )}
              <div className={modalBodyClasses}>{children}</div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>,
      document.getElementById('root')
    )
  }

  return (
    <>
      {trigger ? (
        <span className={modalTriggerClasses} onClick={handleOpenModalClick}>
          {trigger && trigger}
        </span>
      ) : null}

      <ModalContent />
    </>
  )
}

export default Modal

Modal.defaultProps = {
  isOpen: false,
  overlayClose: true,
}
