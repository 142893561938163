import React, { useEffect, useState, Fragment } from 'react'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import Tooltip from 'rc-tooltip'
import { FormattedMessage, useIntl } from 'react-intl'
import { TrackItem, trackImg } from '@fnd/components/PlaylistDetails/TrackItem'
import Alert from '@fnd/components/Alert'
import Badge from '@fnd/components/Badge'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import { PLANS, SUBMISSIONS_PER_PAGE, QUERIES } from '@fnd/constants'
import { ROUTES } from '@fnd/screens/constants'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { generatePath, useNavigate } from 'react-router-dom'
import { usePushedTrackStore } from '@fnd/store'
import { userProfileSelector } from '@fnd/modules/User'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import classNames from 'classnames'

function TracksPushedTable({ campaignId, vip, className }) {
  const [total, setTotal] = useState(0)

  const navigate = useNavigate()
  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  const { setCurrentTrack } = usePushedTrackStore()
  const intl = useIntl()

  const queryKey = campaignId
    ? `${QUERIES.TRACK_PUSHED.LIST}_${campaignId}`
    : QUERIES.TRACK_PUSHED.LIST

  const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery({
    queryKey: [queryKey],
    queryFn: async (params) => {
      const query = {
        limit: SUBMISSIONS_PER_PAGE,
        ...params,
      }

      if (campaignId) {
        query.campaignId = campaignId
      }

      if (vip) {
        query.vip = vip
      }

      const res = await spotimatchEndpoints.getPushedTracks(query)
      setTotal(res.total)
      return res
    },
    getNextPageParam: (lastPage) => {
      if (!lastPage?.next_page) return
      return lastPage.next_page
    },
  })

  const { ref, inView } = useInView({
    threshold: 0,
  })

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, fetchNextPage, hasNextPage])

  const tracks = data?.pages?.map((page) => page.data).flat() || []

  const onTrackClick = (track) => {
    setCurrentTrack(track)
    navigate(
      generatePath(
        `/${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_ACTIVITY_TRACK_DETAIL}`,
        { trackId: track.id }
      )
    )
  }

  const classes = classNames({
    [className]: className,
  })

  return (
    <div className={classes}>
      <Alert
        variant="primary"
        className="mb-4"
        cta={
          profile?.plan?.name !== PLANS.AGENCY ? (
            <Button
              small
              to={`${ROUTES.PRICING}?plan=${PLANS.AGENCY}`}
              variant="primary"
            >
              {intl.formatMessage({ id: 'banner.free.cta_upgrade' })}
            </Button>
          ) : null
        }
      >
        <p className="pr-4 text-sm">
          <FormattedMessage id="profile.my_tracks.alert.artist" />
          {profile?.plan?.name !== PLANS.AGENCY && (
            <FormattedMessage id="profile.my_tracks.alert.upgrade" />
          )}
        </p>
      </Alert>

      {tracks.length > 0 && (
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>
                  <FormattedMessage id="profile.my_activity.table.track" />
                </th>
                <th>
                  <Tooltip
                    placement="top"
                    trigger={['hover']}
                    overlay={
                      <span>
                        <FormattedMessage id="profile.my_playlists.table.submissions_tooltip" />
                      </span>
                    }
                  >
                    <div className="flex items-center">
                      <FormattedMessage id="profile.my_activity.table.playlists" />
                      <Icon name="info-circle" className="ml-2" />
                    </div>
                  </Tooltip>
                </th>
                <th>
                  <FormattedMessage id="messages.total" />
                </th>
                <th className="text-right">
                  <FormattedMessage id="messages.actions" />
                </th>
              </tr>
            </thead>

            <tbody>
              {tracks.map((track, trackIndex) => (
                <Fragment key={`${track.id}_${trackIndex}`}>
                  <tr>
                    <td>
                      <TrackItem
                        key={`${track.id}${trackIndex}`}
                        className="items-center p-0 border-0 hover:bg-transparent"
                        name={track?.name ? track.name.substring(0, 40) : ''}
                        link={track?.external_urls?.spotify}
                        artist={track?.artists?.[0]}
                        disablePlayer={true}
                        image={trackImg(track) ? trackImg(track).url : ''}
                      />
                    </td>
                    <td>
                      <div className="flex items-center flex-wrap gap-2">
                        <Badge
                          variant="green-light"
                          icon="check"
                          label={intl.formatMessage({
                            id: 'messages.accepted_plural',
                          })}
                          value={`${track?.accepted_count ?? 0}`}
                        />

                        <Badge
                          variant="orange-light"
                          icon="hourglass"
                          label={intl.formatMessage({
                            id: 'messages.in_queue',
                          })}
                          value={`${track?.pending_count ?? 0}`}
                        />

                        <Badge
                          variant="red-light"
                          icon="times"
                          label={intl.formatMessage({
                            id: 'messages.rejected_plural',
                          })}
                          value={`${track?.rejected_count ?? 0}`}
                        />
                      </div>
                    </td>
                    <td>{track?.total_count}</td>
                    <td className="text-right">
                      <div className="table-actions">
                        <Button
                          small
                          icon="search"
                          onClick={() => onTrackClick(track)}
                        />
                      </div>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {isLoading && <Spinner />}

      {tracks.length === 0 && !isLoading && (
        <Empty
          className="my-0"
          message={intl.formatMessage({ id: 'empty_state.no_tracks_pushed' })}
        >
          <Button to={ROUTES.MATCH} className="mt-5">
            <FormattedMessage id="navigation.match" />
          </Button>
        </Empty>
      )}

      <div ref={ref} />
    </div>
  )
}

export default TracksPushedTable
