import React, { memo, useState } from 'react'
import classNames from 'classnames'
import Icon from '@fnd/components/Icon'
import { Icons } from '@fnd/components/Icons'

function Avatar({
  src,
  size,
  label,
  star,
  name,
  icon = 'user',
  hideStarBadge = false,
  className,
}) {
  const [imageLoaded, setImageLoaded] = useState(true)

  const classes = classNames({
    avatar: true,
    'avatar-placeholder': (!src && !name) || !imageLoaded,
    'avatar-name': !src && name,
    'avatar-star': star,
    [size]: size,
    [className]: className,
  })

  const handleError = () => {
    setImageLoaded(false)
  }

  return (
    <figure className={classes}>
      {src && imageLoaded && (
        <img src={src} alt={label} aria-label={label} onError={handleError} />
      )}

      {!src && name && (
        <span className="uppercase">
          {`${name.charAt(0)}${name.charAt(1)}`}
        </span>
      )}

      {((!src && !name) || !imageLoaded) && (
        <Icon className="flex" name={icon} />
      )}

      {star && !hideStarBadge && (
        <span className="verified">
          <Icons icon="verified" />
        </span>
      )}
    </figure>
  )
}

export default memo(Avatar)
