import React, { useState } from 'react'
import { spotifyEndpoints } from '@fnd/core/spotify'
import Button from '@fnd/components/Button'
import SearchSelect from '@fnd/modules/Analysis/Search/SearchSelect'
import { FormattedMessage } from 'react-intl'

export default function MyPlaylistAddTrackForm({ onAdd, onCancel }) {
  const [track, setTrack] = useState(null)
  const [isTrackValid, setTrackValid] = useState(false)

  const handleChange = async (value) => {
    try {
      const trackData = await spotifyEndpoints.getTrack(value).toPromise()
      setTrack(trackData)
      setTrackValid(true)
    } catch (err) {
      setTrackValid(false)
      setTrack(null)
    }
  }

  const handleAdd = () => {
    if (!isTrackValid) {
      return
    }
    onAdd(track)
  }

  const handleRemove = () => {
    onCancel(track)
  }

  return (
    <div className="form-group flex items-end gap-2 mt-2">
      <SearchSelect
        className="flex-1"
        name="name"
        entity="track"
        onChange={handleChange}
        isDisabled={false}
      />
      <div className="flex items-center gap-2">
        <Button
          icon="undo"
          label={<FormattedMessage id="messages.cancel" />}
          className="w-[140px] h-[46px]"
          variant="inverse"
          onClick={handleRemove}
        />
        <Button
          icon="plus"
          label={<FormattedMessage id="messages.add" />}
          onClick={handleAdd}
          disabled={!isTrackValid}
          className="w-[140px] h-[46px]"
        />
      </div>
    </div>
  )
}
