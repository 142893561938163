import { create } from 'zustand'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'

export const matchStore = (set, get) => ({
  track: null,
  trackId: null,
  matchLocale: 'en',
  genres: [],
  isMatching: false,
  canMatch: true,
  matchType: null,
  matches: {},
  success: null,
  error: null,
  isLoading: false,
  setCanMatch: (canMatch) => {
    set({ canMatch })
  },
  setIsMatching: (isMatching) => {
    set({ isMatching })
  },
  setMatchType: (matchType) => {
    set({ matchType })
  },
  setTrack: (track) => {
    set({ track })
  },
  setTrackId: (trackId) => {
    set({ trackId })
  },
  setPlaylistId: (playlistId) => {
    set({ playlistId })
  },
  setGenres: (genres) => {
    set({ genres })
  },
  setMatchLocale: (matchLocale) => {
    set({ matchLocale })
  },
  setSuccess: (success) => {
    set({ success })
  },
  setError: (error) => {
    set({ error })
  },
  setIsLoading: (isLoading) => {
    set({ isLoading })
  },
  getMatch: async (playlistId, trackId) => {
    const matchTrackId = trackId || get().trackId
    const matchKey = `${playlistId}-${matchTrackId}`
    const match = get().matches?.[matchKey]

    if (!match) {
      const { getPlaylistScore } = get()
      const matchData = await getPlaylistScore(playlistId)
      return matchData
    }

    return match
  },
  addMatch: (playlistId, trackId, match) => {
    const { matches, getMatch } = get()
    const matchKey = `${playlistId}-${trackId}`
    const existingMatch = getMatch(playlistId, trackId)

    if (!existingMatch?.values) {
      set({
        matches: {
          ...matches,
          [matchKey]: match,
        },
      })
    }
  },
  getPlaylistScore: async (playlistId) => {
    const { addMatch, trackId, setIsLoading, setSuccess, setError } = get()
    setIsLoading(true)

    try {
      const matchData = await spotimatchEndpoints.getMatchScore(
        playlistId,
        trackId
      )
      addMatch(playlistId, trackId, matchData)
      setSuccess('feedback.success.analysis')
      setIsLoading(false)

      return matchData
    } catch (error) {
      console.log(error)
      setError('feedback.error.default')
      setIsLoading(false)
    }
  },
})

export const useMatchStore = create((...args) => ({
  ...matchStore(...args),
}))
