import React from 'react'
import { components } from 'react-select'
import classNames from 'classnames'

export const CustomImageSingleValue = (props) => {
  const value = props.data.value
  const selectedOption = props.options.find((option) => option.value === value)
  const imageClasses = classNames({
    [props?.selectProps?.imageClasses]: props?.selectProps?.imageClasses,
  })

  return (
    <components.SingleValue {...props}>
      <div className="flex items-center gap-2">
        {selectedOption?.image && (
          <img
            src={selectedOption.image}
            alt={selectedOption.label}
            className={imageClasses}
          />
        )}
        <span>{selectedOption?.label}</span>
      </div>
    </components.SingleValue>
  )
}

export const CustomImageOption = (props) => {
  const value = props.data.value
  const selectedOption = props.options.find((option) => option.value === value)
  const imageClasses = classNames({
    [props?.selectProps?.imageClasses]: props?.selectProps?.imageClasses,
  })

  return (
    <components.Option {...props}>
      <div className="flex items-center gap-2">
        {selectedOption?.image && (
          <img
            src={selectedOption.image}
            alt={selectedOption.label}
            className={imageClasses}
          />
        )}
        <span>{selectedOption?.label}</span>
      </div>
    </components.Option>
  )
}
