import React, { useEffect } from 'react'
import { userProfileSelector, useUserContext } from '@fnd/modules/User'
import ArtistForm from '@fnd/modules/Artist/ArtistForm'
import ArtistCard from '../Artist/ArtistCard'
import Icon from '@fnd/components/Icon'
import { PLANS } from '@fnd/constants'
import { useReceiveArtists } from '@fnd/modules/Artist'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import { useMatchStore } from '@fnd/store'

export const AuthArtist = ({ onArtistSelect, children, className }) => {
  const intl = useIntl()

  const { setGenres } = useMatchStore()

  const { profile, updateUserArtists } = useUserContext(
    ({ user, updateUserProfile }) => ({
      profile: userProfileSelector(user),
      updateUserArtists: (next) =>
        updateUserProfile({ spotify_artist_id: { $set: next } }),
    })
  )

  const [artistState] = useReceiveArtists(profile.spotify_artist_id)

  useEffect(() => {
    if (artistState.artists.length > 0 && profile.plan.name !== PLANS.AGENCY) {
      const genres = artistState.artists.map((artist) => artist.genres).flat()
      setGenres(genres)
      onArtistSelect()
    }
  }, [artistState])

  const classes = classNames('auth-artist', className)

  return (
    <>
      {profile.plan.name !== PLANS.AGENCY && (
        <div className={classes}>
          {(!profile.spotify_artist_id ||
            profile.spotify_artist_id.length === 0) && (
            <div className="artist-form">
              <h5>
                <Icon name="headphones-alt" className="mr-2" />
                <FormattedMessage id="match.artist_select" />
              </h5>

              <ArtistForm
                btnVariant="gradient"
                searchInputClasses="w-full md:w-[400px]"
                handleForm={updateUserArtists}
              />
            </div>
          )}

          {artistState.artists && artistState.artists.length > 0 && (
            <div className="artist-card flex flex-col">
              <h5>
                <Icon name="user-music" className="mr-2" />
                {intl.formatMessage({ id: 'match.artist_selected' })}
              </h5>

              <div className="flex gap-3">
                {artistState.artists.map((artist) => (
                  <ArtistCard key={artist.id} artist={artist} />
                ))}
              </div>
            </div>
          )}
        </div>
      )}

      {children}
    </>
  )
}

export default AuthArtist
