import React from 'react'
import { FormattedMessage } from 'react-intl'
import CuratorPlaylists from '@fnd/modules/Curator'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'

export default function MyPlaylists() {
  return (
    <ProfileWrapper
      icon="list-music"
      title={<FormattedMessage id="navigation.my_playlists" />}
    >
      <CuratorPlaylists />
    </ProfileWrapper>
  )
}
