import React from 'react'
import classNames from 'classnames'
import { ArtistSelect } from '@fnd/components/Artist'
import { WantedCounters, WantedTable } from '@fnd/components/Wanted'
import { useWantedStore } from '@fnd/store'

export const WantedDashboard = ({ className }) => {
  const { setArtist, setArtistId } = useWantedStore()

  const handleArtistChange = (artist) => {
    setArtist(artist)
    setArtistId(artist.id)
  }

  const classes = classNames({
    'grid grid-cols-1 md:grid-cols-12 gap-4': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <ArtistSelect className="col-span-12" onChange={handleArtistChange} />

      <WantedCounters className="col-span-12" />

      <WantedTable className="col-span-12" />
    </div>
  )
}

export default WantedDashboard
