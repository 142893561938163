import React from 'react'
import { useIntl } from 'react-intl'
import { ProgressBar } from '@fnd/components/Progress'

function MatchDetails({ values }) {
  const intl = useIntl()
  return (
    <div className="playlist-match">
      <ProgressBar
        compact
        label={intl.formatMessage({ id: 'match.card.features.danceability' })}
        labelClasses="font-semibold tracking-tight"
        icon="guitar"
        valueLabel={`${values?.danceability}%`}
        value={values?.danceability}
        variant="red"
        tooltipText={intl.formatMessage({
          id: 'match.card.features.danceability.tooltip',
        })}
      />

      <ProgressBar
        compact
        label={intl.formatMessage({ id: 'match.card.features.acousticness' })}
        labelClasses="font-semibold tracking-tight"
        icon="turntable"
        valueLabel={`${values?.acousticness}%`}
        value={values?.acousticness}
        variant="orange"
        tooltipText={intl.formatMessage({
          id: 'match.card.features.acousticness.tooltip',
        })}
      />

      <ProgressBar
        compact
        label={intl.formatMessage({ id: 'match.card.features.energy' })}
        labelClasses="font-semibold tracking-tight"
        icon="bolt"
        valueLabel={`${values?.energy}%`}
        value={values?.energy}
        variant="yellow"
        tooltipText={intl.formatMessage({
          id: 'match.card.features.energy.tooltip',
        })}
      />

      <ProgressBar
        compact
        label={intl.formatMessage({
          id: 'match.card.features.instrumentalness',
        })}
        labelClasses="font-semibold tracking-tight"
        icon="violin"
        valueLabel={`${values?.instrumentalness}%`}
        value={values?.instrumentalness}
        variant="green"
        tooltipText={intl.formatMessage({
          id: 'match.card.features.instrumentalness.tooltip',
        })}
      />

      <ProgressBar
        compact
        label={intl.formatMessage({ id: 'match.card.features.speechiness' })}
        labelClasses="font-semibold tracking-tight"
        icon="microphone-stand"
        valueLabel={`${values?.speechiness}%`}
        value={values?.speechiness}
        variant="teal"
        tooltipText={intl.formatMessage({
          id: 'match.card.features.speechiness.tooltip',
        })}
      />

      <ProgressBar
        compact
        label={intl.formatMessage({ id: 'match.card.features.mood' })}
        labelClasses="font-semibold tracking-tight"
        icon="smile-wink"
        valueLabel={`${values?.valence}%`}
        value={values?.valence}
        variant="blue"
        tooltipText={intl.formatMessage({
          id: 'match.card.features.mood.tooltip',
        })}
      />

      <ProgressBar
        compact
        label={intl.formatMessage({ id: 'match.card.features.tempo' })}
        labelClasses="font-semibold tracking-tight"
        icon="hourglass-half"
        valueLabel={`${values?.tempo} %`}
        value={values?.tempo}
        variant="purple"
        tooltipText={intl.formatMessage({
          id: 'match.card.features.tempo.tooltip',
        })}
      />
    </div>
  )
}

export default MatchDetails
