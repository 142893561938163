import { ThemeContext } from '@fnd/context/ThemeProvider'
import { STRIPE_CONFIG } from '@fnd/constants'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import React, { useContext } from 'react'
import StripeForm from './StripeForm'

const stripePromise = loadStripe(STRIPE_CONFIG.PUBLIC_KEY)

export default function StripeGateway({
  amount,
  disabled,
  mode,
  plan,
  profile,
  addons,
  ...props
}) {
  const { currentTheme } = useContext(ThemeContext)
  const stripeTheme = currentTheme === 'dark' ? 'night' : 'stripe'
  const price = Math.round(amount * 100)

  const stripeOptions = {
    amount: price,
    currency: STRIPE_CONFIG.CURRENCY,
    mode,
    automatic_payment_methods: {
      enabled: true,
    },
    appearance: {
      theme: stripeTheme,
    },
  }

  return (
    <div className="payment-gateway">
      <Elements stripe={stripePromise} options={stripeOptions}>
        <StripeForm
          amount={price}
          mode={mode}
          disabled={disabled}
          plan={plan}
          profile={profile}
          addons={addons}
          {...props}
        />
      </Elements>
    </div>
  )
}

StripeGateway.defaultProps = {
  mode: 'payment',
}
