import React from 'react'
import classNames from 'classnames'
import PaymentTotal from './PaymentTotal'
import PaymentDetails from './PaymentDetails'

export default function PaymentSummary({
  billing,
  creditsScoreUsed,
  disableAffiliate,
  disableCoupon,
  paymentPrice,
  price,
  className,
}) {
  const classes = classNames({
    'payment-summary': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <div className="grid grid-cols-2 gap-2 mb-6">
        <div className="col-span-2">
          <PaymentDetails
            billing={billing}
            creditsScoreUsed={creditsScoreUsed}
            disableAffiliate={disableAffiliate}
            disableCoupon={disableCoupon}
            price={price}
          />
        </div>

        <div className="col-span-2">
          <PaymentTotal paymentPrice={paymentPrice} price={price} />
        </div>
      </div>
    </div>
  )
}

PaymentSummary.defaultProps = {
  autoApply: false,
  billing: 'one-time',
  disabled: false,
  btnIcon: 'check',
  className: '',
  creditsDiscount: 0.9,
}
