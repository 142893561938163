import React, { useState, useEffect, memo } from 'react'
import Button from '@fnd/components/Button'
import { Modal } from '@fnd/components/Modal'
import parse from 'html-react-parser'
import { ROUTES } from '@fnd/screens/constants'
import { usePushToPlaylistStore } from '@fnd/store'
import { useIntl } from 'react-intl'

export const VipCampaignPopup = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { pushedPlaylists, pushedLimit, incrementLimit } =
    usePushToPlaylistStore()
  const intl = useIntl()

  useEffect(() => {
    if (pushedPlaylists?.length > pushedLimit) {
      setIsOpen(true)
      incrementLimit()
    } else {
      setIsOpen(false)
    }
  }, [pushedPlaylists])

  return (
    <Modal
      title={intl.formatMessage({ id: 'vip_campaign.popup.title' })}
      isOpen={isOpen}
    >
      <div className="p-5">
        <img
          className="rounded-xl border-outline mb-5"
          src="/images/vip/vip_campaign_mockup.jpg"
          alt="VIP Campaign"
        />

        <p className="text-lg mb-3">
          {parse(intl.formatMessage({ id: 'vip_campaign.popup.text_1' }))}
        </p>

        <p className="text-lg mb-3">
          {parse(intl.formatMessage({ id: 'vip_campaign.popup.text_2' }))}
        </p>

        <p className="text-lg mb-3">
          {parse(intl.formatMessage({ id: 'vip_campaign.popup.text_3' }))}
        </p>

        <Button
          variant="gradient"
          className="w-full mt-3"
          to={ROUTES.VIP}
          label="VIP Campaign"
        />
      </div>
    </Modal>
  )
}

export default React.memo(VipCampaignPopup)
