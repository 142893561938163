import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const usePushToPlaylistStore = create(
  persist(
    (set, get) => ({
      pushedCount: 0,
      pushedLimit: 50,
      pushedPlaylists: [],
      increment: () => set((state) => ({ pushedCount: state.pushedCount + 1 })),
      incrementLimit: () =>
        set((state) => ({ pushedLimit: state.pushedLimit * 2 })),
      setPushedPlaylists: (playlists) => set({ pushedPlaylists: playlists }),
      setPushedCount: (count) => set({ pushedCount: count }),
      resetPushedCount: () => set({ pushedCount: 0 }),
      resetPushedPlaylists: () => set({ pushedPlaylists: [] }),
      checkPushedPlaylist: (playlist) => {
        const pushedPlaylists = get().pushedPlaylists
        if (pushedPlaylists.includes(playlist?.id) || playlist?.isAlreadyPushed)
          return true
        return false
      },
    }),
    {
      name: 'pushtoplaylist-storage',
    }
  )
)
