import { create } from 'zustand'

export const useBannerStore = create((set) => ({
  banner: null,
  setBanner: (banner) => set({ banner }),
  dismissed: [],
  dismissBanner: (bannerId) =>
    set((state) => ({
      dismissed: [...state.dismissed, bannerId],
    })),
}))

export default useBannerStore
