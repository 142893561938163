import React from 'react'
import ProfileOverviewView from './ProfileOverviewView'
import ProfileOverviewEdit from './ProfileOverviewEdit'

export default function ProfileOverview({
  mode,
  userProfile,
  updateUserContacts,
  updateUserGenres,
  updateUserVerified,
  updateUserReferral,
}) {
  if (mode === 'edit') {
    return (
      <ProfileOverviewEdit
        userProfile={userProfile}
        updateUserContacts={updateUserContacts}
        updateUserGenres={updateUserGenres}
        updateUserVerified={updateUserVerified}
      />
    )
  }

  return (
    <ProfileOverviewView
      userProfile={userProfile}
      updateUserGenres={updateUserGenres}
      updateUserReferral={updateUserReferral}
    />
  )
}
