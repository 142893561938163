import { make } from './endpoints'
import { PER_PAGE } from '@fnd/constants'

export const getTracks = (params) => {
  const page = params?.pageParam || 1
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt((page - 1) * limit)
  return make.get(`/tracks?limit=${limit}&offset=${offset}`).toPromise()
}

export const saveTrackPlay = (trackId) =>
  make.post(`/tracks/${trackId}/play`).toPromise()

export default {
  getTracks,
  saveTrackPlay,
}
