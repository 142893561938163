import { create } from 'zustand'

export const useQueueFeedbackStore = create((set) => ({
  showFeedback: false,
  setShowFeedback: (showFeedback) => set({ showFeedback }),
  track: null,
  setTrack: (track) => set({ track }),
  submitter: null,
  setSubmitter: (submitter) => set({ submitter }),
}))

export default useQueueFeedbackStore
