import React from 'react'
import Icon from '@fnd/components/Icon'
import { useIntl } from 'react-intl'
import { formatCurrency } from '@fnd/core/libs/currency'
import classNames from 'classnames'

export const PaymentItem = ({
  icon,
  color,
  title,
  subtitle,
  salePrice,
  price,
  className,
}) => {
  const intl = useIntl()
  const { locale } = intl

  const classes = classNames({
    'payment-item': true,
    'flex items-center justify-between': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <div className="flex items-center gap-4">
        {icon && <Icon name={icon} className={`icon-${color ?? 'primary'}`} />}

        <div className="flex flex-col">
          {subtitle && (
            <span className="block tracking-widest uppercase text-sm font-light opacity-70 mb-1">
              {subtitle}
            </span>
          )}
          {title && <h4>{title}</h4>}
        </div>
      </div>

      {(salePrice || price) && (
        <div className="flex flex-col items-end">
          {price && (
            <span
              className={classNames({
                'text-base font-light strike-through opacity-70': salePrice,
                'text-xl font-bold text-green': !salePrice,
              })}
            >
              {formatCurrency(price, locale)}
            </span>
          )}

          {salePrice && (
            <span className="text-xl font-bold text-green">
              {formatCurrency(salePrice, locale)}
            </span>
          )}

          <span className="block mt-1 text-xs opacity-70">
            {intl.formatMessage({ id: 'messages.vat_excluded' })}
          </span>
        </div>
      )}
    </div>
  )
}

export default PaymentItem
