import React from 'react'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import {
  ApplePay,
  GPayLogo,
  KlarnaLogo,
  MastercardLogo,
  PayPalLogo,
  StripeLogo,
  VisaLogo,
} from './PaymentLogos'
import Icon from '../Icon'

export default function PaymentSecure({ className }) {
  const intl = useIntl()

  const classes = classNames({
    'payment-secure': true,
    [className]: className,
  })

  const PaymentLogo = ({ logo, className }) => {
    const logoClasses = classNames({
      'payment-logo': true,
      [className]: className,
    })

    return <div className={logoClasses}>{logo}</div>
  }

  return (
    <div className={classes}>
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="payment-secure-details text-green">
          <Icon name="lock" className="text-green" />
          <span>{intl.formatMessage({ id: 'payment.secure.lock' })}</span>
        </div>
        <div className="payment-secure-details text-blue">
          <Icon name="shield-check" className="" />
          <span>{intl.formatMessage({ id: 'payment.secure.shield' })}</span>
        </div>
      </div>

      <div className="payment-secure-logos">
        <PaymentLogo logo={<ApplePay />} />
        <PaymentLogo logo={<GPayLogo />} />
        <PaymentLogo logo={<KlarnaLogo />} />
        <PaymentLogo logo={<PayPalLogo />} />
        <PaymentLogo logo={<MastercardLogo />} />
        <PaymentLogo logo={<VisaLogo />} />
        <PaymentLogo logo={<StripeLogo />} />
      </div>
    </div>
  )
}
