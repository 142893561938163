import React, { useContext } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Card from '@fnd/components/Card'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { userProfileSelector } from '@fnd/modules/User'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import parse from 'html-react-parser'
import { InlineWidget } from 'react-calendly'
import { ThemeContext } from '@fnd/context/ThemeProvider'
import { CALENDLY_WIDGET_URL } from '../../../constants'

export default function Management() {
  const intl = useIntl()
  const { getCurrentTheme } = useContext(ThemeContext)
  const theme = getCurrentTheme()

  const { profile } = useUserContext(({ user }) => ({
    profile: userProfileSelector(user),
  }))

  return (
    <ProfileWrapper
      icon="user-music"
      title={<FormattedMessage id="navigation.management" />}
    >
      <Card className="p-4">
        <div className="flex flex-col gap-3">
          {profile?.display_name && (
            <p>
              {parse(intl.formatMessage({ id: 'messages.hi' }))}
              {', '}
              <span className="font-bold">{profile?.display_name}</span>
            </p>
          )}
          <p>
            {parse(intl.formatMessage({ id: 'profile.management.text.1' }))}
          </p>
          <p>
            {parse(intl.formatMessage({ id: 'profile.management.text.2' }))}
          </p>
          <p className="font-bold">
            {parse(intl.formatMessage({ id: 'profile.management.text.3' }))}
          </p>
        </div>
      </Card>

      {theme && (
        <InlineWidget
          url={CALENDLY_WIDGET_URL}
          pageSettings={{
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
            primaryColor: theme.palette.primary,
            textColor: theme.palette.primaryText,
            backgroundColor: theme.palette.secondaryBg,
          }}
          prefill={{
            email: profile?.email,
            name: profile?.display_name,
          }}
        />
      )}
    </ProfileWrapper>
  )
}
