import React from 'react'
import classNames from 'classnames'
import Icon from '@fnd/components/Icon'
import { useIntl } from 'react-intl'
import { humanReadableDuration } from '@fnd/core/libs/helpers'
import { truncate } from 'lodash-es'
import Separator from '../Separator/Separator'
import { CURATOR_RANGE } from '@fnd/constants'
import CuratorEmoji from '@fnd/components/Card/CuratorEmoji'

const CuratorScore = ({
  curator,
  compact,
  full,
  hideTitle,
  truncateLength,
  className,
  ...props
}) => {
  const intl = useIntl()
  const { locale } = intl

  const classes = classNames({
    'curator-score': true,
    'curator-score-full': full,
    'curator-score-compact': compact,
    [className]: className,
  })

  const CuratorTitle = () => {
    const { rankNormalized } = curator
    if (!rankNormalized) return null

    if (rankNormalized >= CURATOR_RANGE.HIGHEST) {
      return (
        <h4>
          <CuratorEmoji curator={curator} className="text-3xl" />
          {intl.formatMessage({ id: 'curator.score.highest.title' })}
        </h4>
      )
    } else if (rankNormalized >= CURATOR_RANGE.HIGH) {
      return (
        <h4>
          <CuratorEmoji curator={curator} className="text-3xl" />
          {intl.formatMessage({ id: 'curator.score.high.title' })}
        </h4>
      )
    } else if (rankNormalized >= CURATOR_RANGE.MEDIUM) {
      return (
        <h4>
          <CuratorEmoji curator={curator} className="text-3xl" />
          {intl.formatMessage({ id: 'curator.score.medium.title' })}
        </h4>
      )
    } else if (rankNormalized >= CURATOR_RANGE.LOW) {
      return (
        <h4>
          <CuratorEmoji curator={curator} className="text-3xl" />
          {intl.formatMessage({ id: 'curator.score.low.title' })}
        </h4>
      )
    } else if (rankNormalized > CURATOR_RANGE.LOWEST) {
      return (
        <h4>
          <CuratorEmoji curator={curator} className="text-3xl" />
          {intl.formatMessage({ id: 'curator.score.lowest.title' })}
        </h4>
      )
    }

    return (
      <h4>
        <CuratorEmoji curator={curator} />
        {intl.formatMessage({ id: 'curator.score.lowest.title' })}
      </h4>
    )
  }

  const CuratorDescription = () => {
    const { rankNormalized } = curator
    if (!rankNormalized) return null

    if (rankNormalized >= CURATOR_RANGE.HIGHEST) {
      return <p>{intl.formatMessage({ id: 'curator.score.highest' })}</p>
    } else if (rankNormalized >= CURATOR_RANGE.HIGH) {
      return <p>{intl.formatMessage({ id: 'curator.score.high' })}</p>
    } else if (rankNormalized >= CURATOR_RANGE.MEDIUM) {
      return <p>{intl.formatMessage({ id: 'curator.score.medium' })}</p>
    } else if (rankNormalized >= CURATOR_RANGE.LOW) {
      return <p>{intl.formatMessage({ id: 'curator.score.low' })}</p>
    } else if (rankNormalized > CURATOR_RANGE.LOWEST) {
      return <p>{intl.formatMessage({ id: 'curator.score.lowest' })}</p>
    }

    return intl.formatMessage({ id: 'curator.score.lowest' })
  }

  return (
    <div className={classes} {...props}>
      {!hideTitle && (
        <h4>
          <CuratorEmoji curator={curator} />
          {curator?.display_name
            ? truncate(curator.display_name, { length: truncateLength })
            : truncate(curator?.id, { length: truncateLength })}
        </h4>
      )}

      {full && <CuratorTitle />}
      {full && <CuratorDescription />}

      <div className="curator-score-data">
        {curator?.responseRate > 0 && (
          <span>
            <Icon name="comment" />
            {full && (
              <span>
                {intl.formatMessage({ id: 'curator.score.responseRate' })}:{' '}
              </span>
            )}
            {(curator?.responseRate * 100).toFixed(0)}%
          </span>
        )}

        {!curator?.responseRate && (
          <span>
            <Icon name="comment" className={compact ? 'text-red' : ''} />
            {full && (
              <span>
                {intl.formatMessage({ id: 'curator.score.responseRate' })}:{' '}
              </span>
            )}
            {!compact && intl.formatMessage({ id: 'curator.score.noData' })}
          </span>
        )}

        {!curator?.timeMean && (
          <span>
            <Icon name="clock" className={compact ? 'text-red' : ''} />
            {full && (
              <span>
                {intl.formatMessage({ id: 'curator.score.timeMean' })}:{' '}
              </span>
            )}
            {!compact && intl.formatMessage({ id: 'curator.score.noData' })}
          </span>
        )}

        {curator?.timeMean > 0 && (
          <span>
            <Icon name="clock" />
            {full && (
              <span>
                {intl.formatMessage({ id: 'curator.score.timeMean' })}:{' '}
              </span>
            )}
            {humanReadableDuration(curator?.timeMean, locale)}
          </span>
        )}
      </div>

      {full && (
        <div>
          <Separator className="mb-4" />
          <p className="opacity-70 font-light text-sm">
            {intl.formatMessage({ id: 'curator.score.disclaimer' })}
          </p>
        </div>
      )}
    </div>
  )
}

export default CuratorScore

CuratorScore.defaultProps = {
  hideTitle: false,
  truncateLength: 20,
}
