import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import { ROUTES } from '@fnd/screens/constants'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams, useSearchParams } from 'react-router-dom'
import VipCampaignDetails from './VipCampaignDetails'
import VipCampaignReport from './VipCampaignReport'
import { Tabs, TabContent, useTabs } from '@fnd/components/Tabs'
import { useQuery } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Spinner from '@fnd/components/Spinner'
import Button from '@fnd/components/Button'
import { useConsent } from '@fnd/context/ConsentProvider'
import { QUERIES } from '@fnd/constants'

export default function VipCampaignView() {
  const intl = useIntl()
  const [searchParams] = useSearchParams()
  const { campaignId } = useParams()
  const { handleIntercomToggle } = useConsent()
  const { data: campaign, isPending } = useQuery({
    queryKey: [QUERIES.VIP_CAMPAIGN.DETAIL],
    queryFn: () => spotimatchEndpoints.getVipCampaign(campaignId),
  })

  const tabsData = {
    tabs: [
      {
        id: ROUTES.MY_PROFILE.VIP_CAMPAIGN_DETAILS,
        icon: 'info-circle',
        label: <FormattedMessage id="navigation.vip_campaign.details" />,
        children: <VipCampaignDetails campaign={campaign} className="mt-8" />,
      },
      {
        id: ROUTES.MY_PROFILE.VIP_CAMPAIGN_REPORT,
        icon: 'file-check',
        label: <FormattedMessage id="navigation.vip_campaign.report" />,
        children: <VipCampaignReport campaign={campaign} className="mt-8" />,
      },
    ],
    initialTabId: 'details',
  }

  const tabs = useTabs(tabsData)

  useEffect(() => {
    const tabParam = searchParams.get('tab')

    if (tabParam) {
      const tabIndex = tabsData.tabs.findIndex((tab) => tab.id === tabParam)
      tabs.tabProps.setSelectedTab(tabIndex > -1 ? [tabIndex] : [0])
    }
  }, [])

  return (
    <ProfileWrapper
      back={true}
      icon="gem"
      title={<FormattedMessage id="navigation.vip_campaign.details" />}
      actions={
        campaign &&
        campaign.budget.manager && (
          <Button
            small
            variant="primary-light"
            icon="question-circle"
            className="toggle-intercom"
            onClick={handleIntercomToggle}
            label={intl.formatMessage({ id: 'messages.support' })}
          />
        )
      }
    >
      <Tabs {...tabs.tabProps} />
      <TabContent {...tabs.contentProps} className="p-0">
        {isPending && <Spinner />}
        {!isPending && tabs.selectedTab.children}
      </TabContent>
    </ProfileWrapper>
  )
}
