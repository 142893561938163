import React from 'react'
import { FormattedMessage } from 'react-intl'
import Icon from '@fnd/components/Icon'
import classNames from 'classnames'
import { SignButton } from '@fnd/components/Sign'
import { useLocation } from 'react-router-dom'

function Empty({
  small = false,
  horizontal,
  emoji,
  icon,
  login = false,
  ghost,
  message,
  variant,
  className,
  children,
}) {
  const location = useLocation()

  const classes = classNames({
    'empty-state': true,
    'empty-state-small': small,
    'empty-state-horizontal': horizontal,
    'empty-state-ghost': ghost,
    [`empty-state-${variant}`]: variant,
    [className]: className,
  })

  return (
    <div className={classes}>
      {emoji && emoji}
      {!emoji && !icon && <Icon name="frown" />}
      {icon && <Icon name={icon} />}

      {!message && !children && (
        <p>
          <FormattedMessage id="messages.no_data" />
        </p>
      )}

      {message && <p>{message}</p>}

      {children && children}

      {login && (
        <SignButton
          redirect={location.pathname}
          className="mt-4"
          label={<FormattedMessage id="navigation.login_to_continue" />}
          variant="primary"
        />
      )}
    </div>
  )
}

export default Empty
