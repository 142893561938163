import Empty from '@fnd/components/Empty'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Chart from '@fnd/components/Chart'
import { formatNumber } from '@fnd/core/libs/currency'
import { DashboardCard } from '@fnd/components/Card'

export default function VipCampaignResults({
  results,
  startDate,
  endDate,
  status,
}) {
  const intl = useIntl()
  const { locale } = intl

  const chartData = {
    [startDate]: results?.start,
    [endDate]: results?.end,
  }

  switch (status) {
    case 'running':
      return (
        <Empty
          icon="chart-line"
          className="m-0"
          message={
            <FormattedMessage id="profile.vip_campaigns.report.running" />
          }
        ></Empty>
      )
    case 'ended':
      if (!results) return <Empty />
      return (
        <div className="grid grid-cols-2 gap-6">
          <Chart data={chartData} showBottomAxis={true} showLeftAxis={true} />

          <div className="flex flex-col gap-4">
            <DashboardCard
              icon="eye"
              label={`${intl.formatMessage({
                id: 'profile.vip_campaigns.report.starting_value',
              })} views`}
              value={formatNumber(results.start, locale)}
            />

            <DashboardCard
              icon="eye"
              label={`${intl.formatMessage({
                id: 'profile.vip_campaigns.report.ending_value',
              })} views`}
              value={formatNumber(results.end, locale)}
            />
          </div>
        </div>
      )
  }
}
