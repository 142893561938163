import { saveAs } from 'file-saver'
import { make } from './endpoints'
import { PER_PAGE } from '@fnd/constants'
import dayjs from 'dayjs'

export const getRadios = (params) => {
  const page = params?.pageParam || 0
  const limit = parseInt(params?.limit || PER_PAGE)
  const offset = parseInt(page * limit)
  return make.get(`/radio`, { query: { limit, offset } }).toPromise()
}

export const exportRadios = (ids) => {
  return make
    .post(`/export/radio`, { body: { selectedIds: ids }, responseType: 'blob' })
    .toPromise()
    .then((response) => {
      saveAs(response, `radio_export_${dayjs().format('YYYY-MM-DD')}.csv`)
    })
}

export default {
  exportRadios,
  getRadios,
}
