import React, { useState } from 'react'
import dayjs from 'dayjs'
import { Accordion, AccordionItem } from '@fnd/components/Accordion'
import { PlaylistOverview } from '@fnd/components/PlaylistDetails'
import Empty from '@fnd/components/Empty'
import Chart, { PieChart } from '@fnd/components/Chart'
import { useTabs, TabLinks } from '@fnd/components/Tabs'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { spotifyEndpoints } from '@fnd/core/spotify'
import { useQuery } from '@tanstack/react-query'
import { FormattedMessage, useIntl } from 'react-intl'
import { QUERIES } from '@fnd/constants'
import { parseData } from '@fnd/components/Chart/helpers'
import Spinner from '@fnd/components/Spinner'

export default function PlaylistAnalysis({
  playlistId,
  hideOverview = false,
  latest = false,
}) {
  const [timeFrame, setTimeFrame] = useState('daily')
  const intl = useIntl()

  const timeFrames = [
    {
      id: 'all',
      label: intl.formatMessage({ id: 'chart_filter.everything' }),
      dateStart: dayjs().subtract(1, 'year'),
      dateEnd: dayjs(),
      icon: 'hourglass',
      onClick: () => setTimeFrame('all'),
    },
    {
      id: 'daily',
      label: intl.formatMessage({ id: 'messages.daily' }),
      dateStart: dayjs().subtract(1, 'day'),
      dateEnd: dayjs(),
      icon: 'calendar-day',
      onClick: () => setTimeFrame('daily'),
    },
    {
      id: 'weekly',
      label: intl.formatMessage({ id: 'messages.weekly' }),
      dateStart: dayjs().subtract(1, 'week'),
      dateEnd: dayjs(),
      icon: 'calendar-week',
      onClick: () => setTimeFrame('weekly'),
    },
    {
      id: 'monthly',
      label: intl.formatMessage({ id: 'messages.monthly' }),
      dateStart: dayjs().subtract(1, 'month'),
      dateEnd: dayjs(),
      icon: 'calendar',
      onClick: () => setTimeFrame('monthly'),
    },
  ]

  const timeFrameTabs = {
    tabs: timeFrames,
    initialTabId: timeFrame,
  }

  const timeFrameFilter = useTabs(timeFrameTabs)

  const { data: playlistData } = useQuery({
    queryKey: [`${QUERIES.PLAYLIST.SPOTIFY_DATA}.${playlistId}`],
    queryFn: () => spotifyEndpoints.getPlaylist(playlistId).toPromise(),
    retry: false,
  })

  const { data: playlistAnalysis, isLoading } = useQuery({
    queryKey: [`${QUERIES.PLAYLIST.ANALYSIS}.${playlistId}`],
    queryFn: () =>
      spotimatchEndpoints.getPlaylistAnalysis(playlistId).toPromise(),
    retry: false,
  })

  const { data: playlistCharts, isLoading: chartsLoading } = useQuery({
    queryKey: [`${QUERIES.PLAYLIST.CHARTS}.${playlistId}`, timeFrame],
    queryFn: () => {
      const startTimeFrame = timeFrames.find((time) => time.id === timeFrame)
      const endTimeFrame = timeFrames.find((time) => time.id === timeFrame)
      const startDate = startTimeFrame.dateStart.format('YYYY-MM-DD')
      const endDate = endTimeFrame.dateEnd.format('YYYY-MM-DD')

      return spotimatchEndpoints
        .getPlaylistAnalysisCharts(playlistId, startDate, endDate, latest)
        .toPromise()
    },
    retry: false,
  })

  if (isLoading) {
    return <Spinner />
  }

  return (
    <>
      {playlistData && !isLoading ? (
        <>
          {!hideOverview && (
            <PlaylistOverview
              className="border-none"
              wrapperClassName="pt-0 px-0"
              genres={playlistAnalysis?.analysis?.genres}
              owner={playlistData?.owner}
              playlist={playlistData}
            />
          )}

          {playlistAnalysis?.analysis?.number_artists_per_genre && (
            <div className="flex flex-wrap gap-2">
              <div className="flex items-center w-full h-[500px]">
                <PieChart
                  chartData={Object.keys(
                    playlistAnalysis?.analysis?.number_artists_per_genre
                  ).map((genre) => ({
                    id: genre,
                    label: genre,
                    value:
                      playlistAnalysis?.analysis?.number_artists_per_genre[
                        genre
                      ],
                  }))}
                />
              </div>
            </div>
          )}
        </>
      ) : (
        <Empty small ghost />
      )}

      {isLoading && <Spinner />}

      <Accordion>
        <AccordionItem label="Charts" icon="chart-pie" defaultOpen>
          <TabLinks
            {...timeFrameFilter.tabProps}
            inline
            variant="toggle"
            className="grid grid-cols-2 md:flex tab-light w-full mb-4"
            selectedTabIndex={timeFrames.findIndex(
              (filter) => filter?.id === timeFrame
            )}
            showIndicator={false}
            showHover={false}
          />

          {!isLoading && chartsLoading && <Spinner />}

          {playlistCharts && !chartsLoading && (
            <>
              <div className="grid gap-4">
                {playlistCharts?.analysis?.followers &&
                  !playlistCharts?.analysis?.followers.every(
                    (d) => d.value === 0
                  ) && (
                    <div className="chart-analytics w-full">
                      <FormattedMessage
                        id="playlist_analysis.charts.followers"
                        tagName="h6"
                      />
                      <Chart
                        data={parseData(playlistCharts?.analysis?.followers)}
                        showArea={true}
                        color="blue"
                        showBottomAxis={true}
                        showLeftAxis={true}
                        isInteractive={true}
                      />
                    </div>
                  )}

                {playlistCharts?.analysis?.popularity &&
                  !playlistCharts?.analysis?.popularity.every(
                    (d) => d.value === 0
                  ) && (
                    <div className="chart-analytics w-full">
                      <FormattedMessage
                        id="playlist_analysis.charts.popularity"
                        tagName="h6"
                      />
                      <Chart
                        data={parseData(playlistCharts?.analysis?.popularity)}
                        showArea={true}
                        color="gold"
                        showBottomAxis={true}
                        showLeftAxis={true}
                        isInteractive={true}
                        yScale={{
                          min: 0,
                          max: 100,
                        }}
                      />
                    </div>
                  )}

                {playlistCharts?.analysis?.tracks &&
                  !playlistCharts?.analysis?.tracks.every(
                    (d) => d.value === 0
                  ) && (
                    <div className="chart-analytics w-full">
                      <FormattedMessage
                        id="playlist_analysis.charts.tracks"
                        tagName="h6"
                      />
                      <Chart
                        data={parseData(playlistCharts?.analysis?.tracks)}
                        showArea={true}
                        color="green"
                        showBottomAxis={true}
                        showLeftAxis={true}
                        isInteractive={true}
                      />
                    </div>
                  )}
              </div>
            </>
          )}

          {!chartsLoading && !playlistCharts && <Empty small ghost />}
        </AccordionItem>
      </Accordion>
    </>
  )
}
