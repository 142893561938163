import React, { useEffect, useState, createContext, useContext } from 'react'
import { Modal } from '@fnd/components/Modal'
import { loadScript } from '@fnd/core/libs/helpers'
import { IUBENDA_CONFIG, INTERCOM_CONFIG } from '@fnd/constants'
import { useIntl, FormattedMessage } from 'react-intl'
import Button from '@fnd/components/Button'
import Alert from '@fnd/components/Alert'

const ConsentContext = createContext()

export const useConsent = () => useContext(ConsentContext)

export const ConsentProvider = ({ children }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [consent, setConsent] = useState(false)
  const intl = useIntl()
  const { locale } = intl

  const activateScripts = () => {
    try {
      if (
        window._iub &&
        window._iub.cs &&
        window._iub.cs.api &&
        window._iub.cs.api.activateSnippets
      ) {
        window._iub.cs.api.activateSnippets()
      }
    } catch (err) {
      console.error(err)
    }
  }

  const checkConsent = () => {
    if (window._iub && window._iub.cs) {
      const consent = window._iub.cs.api.isConsentGiven()

      if (consent) {
        activateScripts()
      }

      setConsent(consent)
    }
  }

  const getConsent = () => {
    if (window._iub && window._iub.cs) {
      return window._iub.cs
    }
    return false
  }

  const handleIntercomToggle = () => {
    checkConsent()

    if (consent) {
      window.Intercom('show')
    } else {
      setModalOpen(true)
    }
  }

  useEffect(() => {
    loadScript(
      '//cdn.iubenda.com/cs/tcf/stub-v2.js',
      'iubenda-tcf-stub-v2',
      true,
      true
    )
    loadScript('//cdn.iubenda.com/cs/iubenda_cs.js', 'iubenda-cs', true, true)

    // Iubenda Config
    if (locale) {
      IUBENDA_CONFIG.lang = locale
    }

    window._iub = window._iub || []
    window._iub.csConfiguration = IUBENDA_CONFIG
    window._iub.csConfiguration.callback.onPreferenceExpressed = () => {
      checkConsent()
    }

    // Intercom Config
    window.intercomSettings = INTERCOM_CONFIG

    setLoaded(true)
  }, [])

  if (!loaded) return null

  return (
    <ConsentContext.Provider
      value={{
        activateScripts,
        checkConsent,
        consent,
        getConsent,
        handleIntercomToggle,
      }}
    >
      <Modal
        bodyClasses="p-5"
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={intl.formatMessage({ id: 'consent.warning.title' })}
      >
        <Alert variant="warning" className="mb-3">
          <FormattedMessage id="consent.warning.alert" />
        </Alert>

        <p className="mb-3">
          <FormattedMessage id="consent.warning.body" />
        </p>

        <Button
          wide
          className="iubenda-cs-preferences-link"
          label={intl.formatMessage({ id: 'messages.cookie_preferences' })}
          onClick={() => {
            window._iub.cs.api.showBanner()
            setModalOpen(false)
          }}
        />
      </Modal>

      {children}
    </ConsentContext.Provider>
  )
}

export default ConsentProvider
