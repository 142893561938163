export const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(email)
}

export const isValidURL = (url) => {
  try {
    new URL(url)
    return true
  } catch (e) {
    return false
  }
}
