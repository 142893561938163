import { createContext } from 'react'
import {
  compose,
  fromRenderProps,
  mapProps,
  nest,
  setDisplayName,
  withState,
} from 'recompose'

import update from 'update-immutable'

export const initialUserContextState = {
  error: void 0,
  profile: {},
  isAuthorized: false,
  isLoading: false,
}

export const UserContext = createContext({
  user: initialUserContextState,
  setUserState: () => void 0,
  updateUserProfile: () => void 0,
})

const { Provider, Consumer } = UserContext

const withUserContextStore = compose(
  setDisplayName('withUserContextStore'),
  withState('user', 'setUserState', initialUserContextState),
  mapProps(({ children, user, setUserState }) => ({
    children,
    value: {
      user,
      setUserState,
      updateUserProfile: (next) =>
        setUserState((current) => update(current, { profile: next })),
    },
  }))
)

export const withUserContextProvider = (Component) =>
  compose(setDisplayName('withUserContextProvider'))(
    nest(withUserContextStore(Provider), Component)
  )

export const withUserContextConsumer = compose(
  setDisplayName('withUserContextConsumer'),
  fromRenderProps(Consumer, (context) => context)
)
