import React, { useState, useEffect, useRef } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Button from '@fnd/components/Button'
import Field from '@fnd/components/Field'
import { SectionHeader } from '@fnd/components/Section'
import { checkVAT, countries as vatCountries } from 'jsvat'
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from 'react-phone-number-input'
import CodiceFiscale from 'codice-fiscale-js'
import countries from './countries.json'
import { toastFeedback } from '@fnd/core/libs/toast'
import { usePlaces } from '@fnd/core/hooks/usePlaces'
import { GOOGLE_MAPS_API_KEY } from '@fnd/constants'
import { isValidEmail } from '@fnd/core/libs/validate'

const validation = {
  firstName: (value) => value.length,
  lastName: (value) => value.length,
  country: (value) => value.length,
  city: (value) => value.length,
  address: (value) => value.length,
  zipCode: (value) => value.length,
  provinceState: (value) => value.length,
  email: (value) => value.length && isValidEmail(value),
  phone: (value) => value && isValidPhoneNumber(value),
  companyName: (value) => value.length,
  vatNumber: (value) => value.length && checkVAT(value, vatCountries).isValid,
  pec: (value) => value.length,
  codiceFiscale: (value) => {
    try {
      const cf = new CodiceFiscale(value)
      cf.toJSON()
      return true
    } catch (err) {
      return false
    }
  },
  codiceUnivoco: (value) => value.length,
}

const validate = (value, type) => {
  if (typeof value !== 'string' || !validation[type]) {
    return true
  }

  return Boolean(validation[type](value.trim()))
}

const italianFields = ['codiceFiscale', 'codiceUnivoco', 'pec']
const companyFields = ['companyName', 'vatNumber', 'codiceUnivoco', 'pec']

export default function BillingInfoEdit({
  billingInfo,
  updateUserBillingInfo,
  onSuccess,
  onCancel,
}) {
  const [stateBillingInfo, updateStateBillingInfo] = useState(billingInfo)
  const [isSubmitLoading, setSubmitLoading] = useState(false)
  const [isFormValid, setFormValid] = useState(true)
  const [isPhoneValid, setPhoneValid] = useState(true)
  const [formErrors, setFormErrors] = useState({})
  const [defaultCountryValue, setDefaultCountryValue] = useState(
    countries.find((country) => country.value === billingInfo.country)
  )
  const isItaly = stateBillingInfo.country === 'IT'
  const intl = useIntl()
  const { locale } = intl
  const refs = {}
  for (const key in validation) {
    if (Object.prototype.hasOwnProperty.call(validation, key)) {
      refs[key] = useRef(null)
    }
  }

  useEffect(() => {
    if (billingInfo.phone && billingInfo.phone.charAt(0) === '+') {
      updateStateBillingInfo((currentState) => ({
        ...currentState,
        phone: formatPhoneNumberIntl(billingInfo.phone),
      }))
    }
  }, [billingInfo])

  useEffect(() => {
    setDefaultCountryValue(
      countries.find((country) => country.value === stateBillingInfo.country)
    )

    if (isItaly && stateBillingInfo?.provinceState.length > 2) {
      updateStateBillingInfo((currentState) => ({
        ...currentState,
        provinceState: stateBillingInfo.provinceState
          .substring(0, 2)
          .toUpperCase(),
      }))
    }
  }, [stateBillingInfo.country])

  const countryChangeHandler = (option) => {
    const { value } = option ? option : ''
    updateStateBillingInfo((prevState) => ({ ...prevState, country: value }))
  }

  const phoneChangeHandler = (value) => {
    updateStateBillingInfo((prevState) => ({ ...prevState, phone: value }))
  }

  const validateElement = (key, isFromBlurEvent) => {
    if (!stateBillingInfo.isVatCompany && companyFields.includes(key)) {
      return true
    }

    if (!isItaly && italianFields.includes(key)) {
      return true
    }

    if (key === 'phone') {
      if (isFromBlurEvent) {
        if (!stateBillingInfo.phone || stateBillingInfo.phone.length === 0) {
          setFormErrors((prevState) => ({
            ...prevState,
            phone: [
              intl.formatMessage({
                id: `form.error.required`,
              }),
            ],
          }))
          return false
        }
      }
      const phoneValid = validate(stateBillingInfo[key], key)
      setPhoneValid(phoneValid)
      return phoneValid
    }

    return validate(stateBillingInfo[key], key)
  }

  const validateForm = (elementToMark, isFromBlurEvent = false) => {
    const inputs = Object.entries(refs)
    let formValid = true

    inputs.forEach(([key, ref]) => {
      const validationResult = validateElement(key, isFromBlurEvent)
      if (!validationResult && ref.current) {
        formValid = validationResult
        const htmlElement =
          key === 'country' ? ref.current.inputRef : ref.current
        if (htmlElement === elementToMark) {
          setFormErrors((prevState) => ({
            ...prevState,
            [key]: [
              intl.formatMessage({
                id: `form.error.required`,
              }),
            ],
          }))
        }
      } else {
        setFormErrors((prevState) => ({ ...prevState, [key]: null }))
      }
    })

    setFormValid(formValid)
    return formValid
  }

  const inputChangeHandler = ({ target }) => {
    let value = target.value
    if (target.type === 'checkbox') {
      value = target.checked
    } else if (target.name === 'codiceFiscale') {
      value = value.toUpperCase()
    }
    updateStateBillingInfo((prevState) => ({
      ...prevState,
      [target.name]: value,
    }))
  }

  const blurHandler = ({ target }, isPhoneField = false) =>
    validateForm(target, isPhoneField)

  const submitHandler = (evt) => {
    evt.preventDefault()
    if (isSubmitLoading || !isFormValid) {
      return
    }

    setSubmitLoading(true)

    spotimatchEndpoints
      .saveBillingInfo(stateBillingInfo)
      .toPromise()
      .then((updatedBillingInfo) => {
        updateUserBillingInfo(updatedBillingInfo)
        setSubmitLoading(false)
        toastFeedback(
          'success',
          intl.formatMessage({ id: 'feedback.success.info_saved' })
        )
        if (onSuccess) {
          onSuccess()
        }
      })
      .catch((error) => {
        console.log(error)
        setSubmitLoading(false)
        toastFeedback(
          'error',
          intl.formatMessage({ id: 'feedback.error.default' })
        )
        setSubmitLoading(false)
      })
  }

  useEffect(() => {
    validateForm()
    if (!isItaly) {
      updateStateBillingInfo((currentState) => ({
        ...currentState,
        ...italianFields.reduce(
          (acc, italianField) => ({ ...acc, [italianField]: '' }),
          {}
        ),
      }))
    }
    if (!stateBillingInfo.isVatCompany) {
      updateStateBillingInfo((currentState) => ({
        ...currentState,
        ...companyFields.reduce(
          (acc, companyField) => ({ ...acc, [companyField]: '' }),
          {}
        ),
      }))
    }
  }, [stateBillingInfo.isVatCompany, stateBillingInfo.country])

  const buttonDisabled = isSubmitLoading || !isFormValid

  const handlePlaceSelected = (addressObject) => {
    const address = addressObject.streetNumber
      ? `${addressObject.street} ${addressObject.streetNumber}`
      : addressObject.street

    const billingAddress = {}

    billingAddress.address = address
    billingAddress.city = addressObject.city
    billingAddress.provinceState = addressObject.provinceState
    billingAddress.country = addressObject.country
    billingAddress.zipCode = addressObject.zipCode
    updateStateBillingInfo((currentState) => ({
      ...currentState,
      ...billingAddress,
    }))
  }

  refs.address = usePlaces({
    apiKey: GOOGLE_MAPS_API_KEY,
    inputAutocompleteValue: stateBillingInfo.address,
    onPlaceSelected: (addressObject) => handlePlaceSelected(addressObject),
  }).ref

  return (
    <>
      <SectionHeader
        sticky
        title={<FormattedMessage id="profile.billing_info.edit" />}
        icon="file-invoice"
        actions={
          <>
            <Button variant="inverse" icon="undo" onClick={onCancel}>
              <FormattedMessage id="messages.cancel" />
            </Button>
            <Button
              loading={isSubmitLoading}
              onClick={submitHandler}
              disabled={buttonDisabled}
              icon="check"
              type="submit"
              label={<FormattedMessage id="messages.save" />}
            />
          </>
        }
      />
      <div className="p-5">
        <form className="form">
          <Field
            label={<FormattedMessage id="profile.billing_info.firstName" />}
            ref={refs.firstName}
            onBlur={blurHandler}
            onChange={inputChangeHandler}
            value={stateBillingInfo.firstName}
            placeholder={intl.formatMessage({
              id: 'profile.billing_info.firstName.placeholder',
            })}
            type="text"
            name="firstName"
            id="billing-first-name"
            required
            errors={formErrors.firstName}
          />

          <Field
            label={<FormattedMessage id="profile.billing_info.lastName" />}
            ref={refs.lastName}
            onBlur={blurHandler}
            onChange={inputChangeHandler}
            value={stateBillingInfo.lastName}
            placeholder={intl.formatMessage({
              id: 'profile.billing_info.lastName.placeholder',
            })}
            type="text"
            name="lastName"
            id="billing-last-name"
            required
            errors={formErrors.lastName}
          />

          <Field
            label={<FormattedMessage id="profile.billing_info.country" />}
            type="select"
            id="billing-country"
            name="country"
            onChange={countryChangeHandler}
            onBlur={blurHandler}
            isClearable
            options={countries}
            defaultValue={defaultCountryValue}
            placeholder={intl.formatMessage({
              id: 'profile.billing_info.country.placeholder',
            })}
            required
            className="select"
            classNamePrefix="select"
            ref={refs.country}
            errors={formErrors.country}
          />

          <Field
            label={<FormattedMessage id="profile.billing_info.address" />}
            ref={refs.address}
            onBlur={blurHandler}
            onChange={inputChangeHandler}
            value={stateBillingInfo.address}
            type="text"
            name="address"
            id="billing-address"
            required
            placeholder={intl.formatMessage({
              id: 'profile.billing_info.address.placeholder',
            })}
            errors={formErrors.address}
            autoComplete="off"
          />

          <Field
            ref={refs.city}
            label={<FormattedMessage id="profile.billing_info.city" />}
            onBlur={blurHandler}
            onChange={inputChangeHandler}
            value={stateBillingInfo.city}
            type="text"
            name="city"
            id="billing-city"
            required
            placeholder={intl.formatMessage({
              id: 'profile.billing_info.city.placeholder',
            })}
            errors={formErrors.city}
          />

          <Field
            ref={refs.zipCode}
            label={<FormattedMessage id="profile.billing_info.zipCode" />}
            onBlur={blurHandler}
            onChange={inputChangeHandler}
            value={stateBillingInfo.zipCode}
            type="text"
            name="zipCode"
            id="billing-zip-code"
            required
            placeholder={intl.formatMessage({
              id: 'profile.billing_info.zipCode.placeholder',
            })}
            errors={formErrors.zipCode}
          />

          <Field
            ref={refs.provinceState}
            label={<FormattedMessage id="profile.billing_info.provinceState" />}
            onBlur={blurHandler}
            onChange={inputChangeHandler}
            maxLength={isItaly ? 2 : undefined}
            value={stateBillingInfo.provinceState}
            type="text"
            name="provinceState"
            id="billing-province-state"
            required
            placeholder={intl.formatMessage({
              id: 'profile.billing_info.provinceState.placeholder',
            })}
            errors={formErrors.provinceState}
          />

          {isItaly && (
            <Field
              ref={refs.codiceFiscale}
              label={<FormattedMessage id="profile.billing_info.taxCode" />}
              onBlur={blurHandler}
              onChange={inputChangeHandler}
              value={stateBillingInfo.codiceFiscale}
              type="text"
              name="codiceFiscale"
              id="billing-codiceFiscale"
              placeholder={intl.formatMessage({
                id: 'profile.billing_info.taxCode.placeholder',
              })}
              errors={formErrors.codiceFiscale}
            />
          )}

          <Field
            label={<FormattedMessage id="profile.billing_info.email" />}
            onBlur={blurHandler}
            onChange={inputChangeHandler}
            value={stateBillingInfo.email}
            ref={refs.email}
            type="text"
            name="email"
            id="billing-email"
            required
            placeholder={intl.formatMessage({
              id: 'profile.billing_info.email.placeholder',
            })}
            errors={formErrors.email}
          />

          <div className="form-group">
            <label htmlFor="billing-phone">
              <FormattedMessage id="field.phone.label" />
            </label>

            <PhoneInput
              placeholder={intl.formatMessage({
                id: 'field.phone.label',
              })}
              defaultCountry={
                !stateBillingInfo.phone ? stateBillingInfo.country : ''
              }
              value={stateBillingInfo.phone}
              onChange={phoneChangeHandler}
              onBlur={(e) => blurHandler(e, true)}
            />

            {!isPhoneValid && (
              <span className="error-message mt-1">
                <FormattedMessage id="field.phone.error" />
              </span>
            )}
            {formErrors.phone && isPhoneValid && (
              <span className="error-message mt-1">
                <FormattedMessage id="form.error.required" />
              </span>
            )}
          </div>

          <div className="payment-popup-text">
            <label className="is-company-label">
              <input
                type="checkbox"
                className="custom-control-input"
                checked={stateBillingInfo.isVatCompany}
                onChange={inputChangeHandler}
                name="isVatCompany"
              />
              <FormattedMessage id="profile.billing_info.vatNumber.checkbox_label" />
            </label>
          </div>

          {stateBillingInfo.isVatCompany && (
            <>
              <Field
                label={
                  <FormattedMessage id="profile.billing_info.companyName" />
                }
                onBlur={blurHandler}
                onChange={inputChangeHandler}
                value={stateBillingInfo.companyName}
                ref={refs.companyName}
                type="text"
                name="companyName"
                id="billing-company-name"
                required
                placeholder={intl.formatMessage({
                  id: 'profile.billing_info.companyName.placeholder',
                })}
                errors={formErrors.companyName}
              />

              <Field
                label={<FormattedMessage id="profile.billing_info.vatNumber" />}
                ref={refs.vatNumber}
                onBlur={blurHandler}
                onChange={inputChangeHandler}
                value={stateBillingInfo.vatNumber}
                type="text"
                name="vatNumber"
                id="billing-vat-number"
                required
                placeholder={intl.formatMessage({
                  id: 'profile.billing_info.vatNumber.placeholder',
                })}
                errors={formErrors.vatNumber}
              />

              {isItaly && (
                <>
                  <Field
                    ref={refs.codiceUnivoco}
                    label={
                      <FormattedMessage id="profile.billing_info.codiceUnivoco" />
                    }
                    onBlur={blurHandler}
                    onChange={inputChangeHandler}
                    value={stateBillingInfo.codiceUnivoco}
                    type="text"
                    name="codiceUnivoco"
                    id="billing-codice-univoco"
                    placeholder={intl.formatMessage({
                      id: 'profile.billing_info.codiceUnivoco.placeholder',
                    })}
                    errors={formErrors.codiceUnivoco}
                  />

                  <Field
                    ref={refs.pec}
                    label={<FormattedMessage id="profile.billing_info.pec" />}
                    onBlur={blurHandler}
                    onChange={inputChangeHandler}
                    value={stateBillingInfo.pec}
                    type="text"
                    name="pec"
                    id="billing-pec"
                    placeholder={intl.formatMessage({
                      id: 'profile.billing_info.pec.placeholder',
                    })}
                    errors={formErrors.pec}
                  />
                </>
              )}
            </>
          )}
        </form>
      </div>
    </>
  )
}
