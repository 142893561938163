import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import { formatCurrency } from '@fnd/core/libs/currency'

export default function PaymentTotal({ paymentPrice, price, className }) {
  const intl = useIntl()
  const { locale } = intl

  const classes = classNames({
    'payment-total': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <label className="m-0">
        <FormattedMessage id="messages.payment_total" />
      </label>
      <h4>
        {price > paymentPrice && (
          <span className="font-medium line-through opacity-50 mr-2">
            {formatCurrency(price, locale)}
          </span>
        )}

        {paymentPrice && (
          <span className="text-green">
            {formatCurrency(paymentPrice, locale)}
          </span>
        )}
      </h4>
    </div>
  )
}
