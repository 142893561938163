import { make } from './endpoints'

const calculateVipPrice = (artistPopularity, trackPopularity, genres, code) =>
  make.get(`plans/vip/price`, {
    query: { artistPopularity, trackPopularity, genres, code },
  })

const getAnalysisPrice = () => make.get(`plans/analysis/price`)

const getExportPlaylistPrice = () => make.get(`plans/playlist/price`)

const getProfileFeaturedPrice = () => make.get(`plans/profile-featured/price`)

export default {
  calculateVipPrice,
  getAnalysisPrice,
  getExportPlaylistPrice,
  getProfileFeaturedPrice,
}
