import Icon from '@fnd/components/Icon'
import classNames from 'classnames'
import React from 'react'

function Alert({
  icon,
  title,
  titleTag = 'h4',
  iconTitle,
  label,
  variant,
  color,
  badge,
  cta,
  footer,
  inline,
  contentClasses,
  className,
  children,
}) {
  const classes = classNames({
    alert: true,
    [`alert-${variant}`]: variant && !color,
    'alert-inline': inline,
    'alert-icon': icon,
    [`bg-${color}-500/10`]: color,
    [`border-${color}-500/20`]: color,
    [className]: className,
  })

  const contentClassName = classNames({
    'alert-content': true,
    [`text-${color}-500`]: color,
    [contentClasses]: contentClasses,
  })

  const AlertTitle = titleTag

  return (
    <div className={classes} role="alert">
      {title && (
        <div className="alert-header">
          <AlertTitle>
            {badge && badge}
            {iconTitle && <Icon name={iconTitle} />}
            {title}
          </AlertTitle>
        </div>
      )}

      <div className={contentClassName}>
        {icon && <Icon name={icon} />}
        {label && label}
        {children}
      </div>

      {cta && cta}

      {footer && <div className="alert-footer">{footer}</div>}
    </div>
  )
}

export default Alert

Alert.defaultProps = {
  variant: 'primary',
}
