import React, { useContext } from 'react'
import Icon from '@fnd/components/Icon'
import classNames from 'classnames'
import { ThemeContext } from '@fnd/context/ThemeProvider'
import { Placeholder } from '../Placeholder'

function TrackPlayer({ cover, audioSrc, disablePlayer, className }) {
  const disable = disablePlayer || audioSrc === null
  const {
    audio,
    setAudio,
    isPlaying,
    setIsPlaying,
    pause,
    duration,
    currentTime,
  } = useContext(ThemeContext)

  const handlePlay = () => {
    if (audio === audioSrc) {
      setIsPlaying(!isPlaying)
    } else if (audio !== audioSrc) {
      setAudio(audioSrc)
    } else {
      pause()
    }
  }

  const classes = classNames({
    'track-player': true,
    'is-playing': isPlaying && audio === audioSrc,
    [className]: className,
  })

  return (
    <button onClick={handlePlay} className={classes}>
      {cover ? (
        <div
          className="track-thumbnail"
          style={{ backgroundImage: `url("${cover}")` }}
        />
      ) : (
        <Placeholder icon="music" />
      )}

      {!disable && (
        <div className="overlay">
          {isPlaying && audio === audioSrc ? (
            <Icon name="pause" />
          ) : (
            <Icon name="play" />
          )}
          {audio === audioSrc && (
            <div className="track-progress">
              <div
                style={{ width: `${(currentTime / duration) * 100}%` }}
                className="track-progress"
              />
            </div>
          )}
        </div>
      )}
    </button>
  )
}

export default TrackPlayer
