import React, { useEffect, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { isEmpty } from 'lodash-es'
import Button from '@fnd/components/Button'
import capitalize from '@fnd/core/libs/capitalize'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import { formatCurrency } from '@fnd/core/libs/currency'
import { useConsent } from '@fnd/context/ConsentProvider'
import classNames from 'classnames'

function useReceiveUserTransactions() {
  const initialState = {
    transactions: [],
    isLoading: false,
    error: void 0,
  }

  const [state, setState] = useState(initialState)
  const patch = (next) => setState((current) => ({ ...current, ...next }))

  useEffect(() => {
    patch({ isLoading: true })
    spotimatchEndpoints
      .getUserTransactions()
      .toPromise()
      .then((transactions) => {
        patch({
          transactions: transactions.map((transaction) => ({
            ...transaction,
            createdAt: new Date(transaction.created_at).toLocaleDateString(
              'it-IT'
            ),
          })),
        })
      })
      .catch(console.log)
      .then(() => patch({ isLoading: false }))
  }, [])

  return state
}

function TransactionTable({ className }) {
  const { handleIntercomToggle } = useConsent()
  const userTransactions = useReceiveUserTransactions()
  const intl = useIntl()
  const { locale } = intl

  if (!userTransactions.isLoading && isEmpty(userTransactions.transactions)) {
    return (
      <Empty message={intl.formatMessage({ id: 'messages.no_transactions' })} />
    )
  }

  const classes = classNames({
    'table-responsive': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <table className="table table-responsive text-center">
        <thead>
          <tr>
            <th className="text-left">
              <FormattedMessage id="profile.payments_history.table.purchase_type" />
            </th>
            <th>
              <FormattedMessage id="profile.payments_history.table.purchase_date" />
            </th>
            <th className="text-right">
              <FormattedMessage id="profile.payments_history.table.amount" />
            </th>
            <th className="text-right">
              <FormattedMessage id="messages.actions" />
            </th>
          </tr>
        </thead>
        <tbody>
          {userTransactions.transactions.map((transaction) => (
            <tr key={transaction.order_id}>
              <td className="text-left">{capitalize(transaction.plan)}</td>

              <td>{transaction.createdAt}</td>

              <td className="text-right text-green font-semibold">
                {formatCurrency(transaction.amount, locale)}
              </td>

              <td className="text-right">
                <Button
                  small
                  variant="primary"
                  className="toggle-intercom"
                  onClick={handleIntercomToggle}
                >
                  <FormattedMessage id="profile.payments_history.table.request_invoice" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {userTransactions.isLoading && <Spinner />}
    </div>
  )
}

export default TransactionTable
