import React, { useState, useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { spotifyStorage } from '@fnd/core/spotify'
import { withUserAuthentication } from '@fnd/modules/User'
import { ROUTES, LOGIN_REDIRECT } from '@fnd/screens/constants'
import { useAppStore } from '@fnd/store'

function Sign({ authorize }) {
  const { redirect, setRedirect } = useAppStore()
  const location = useLocation()
  const { search } = location
  const [redirectTo, setRedirectTo] = useState()

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search)
      const token = params.get('access_token')
      const expires = params.get('expires_at')
      const userId = params.get('userId')
      const type = params.get('type')

      spotifyStorage.token = {
        expires: +expires,
        token,
      }
      spotifyStorage.state = userId
      authorize(true)

      if (redirect) {
        if (type === 'sso') {
          setRedirectTo(ROUTES.SPOTIFY_SSO_CALLBACK)
        } else {
          setRedirectTo(redirect)
          setRedirect('')
        }
      } else {
        setRedirectTo(LOGIN_REDIRECT)
      }
    } else {
      setRedirectTo(location ? location.pathname : ROUTES.ROOT)
    }
  }, [])

  return !!redirectTo && <Navigate to={redirectTo} />
}

export default withUserAuthentication(Sign)
