import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import classNames from 'classnames'
import dayjs from 'dayjs'
import BlockUser from '@fnd/components/BlockUser'
import { useQuery } from '@tanstack/react-query'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { QUERIES } from '@fnd/constants'
import { getDateFormat } from '@fnd/core/libs/helpers'

export default ({ className }) => {
  const intl = useIntl()
  const { locale } = intl

  const { data = { data: [] }, isPending } = useQuery({
    queryKey: [QUERIES.USER.BLOCKED],
    queryFn: () => spotimatchEndpoints.getBlockedUsers(1),
  })

  if (!isPending && data.data.length === 0) {
    return <Empty message={intl.formatMessage({ id: 'messages.no_data' })} />
  }

  const classes = classNames({
    'table-responsive': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <table className="table table-responsive text-center">
        <thead>
          <tr>
            <th>#</th>
            <th className="text-left">
              <FormattedMessage id="profile.blocked_users.table.spotify_name" />
            </th>
            <th className="text-left">
              <FormattedMessage id="profile.blocked_users.table.created_at" />
            </th>
            <th>
              <FormattedMessage id="messages.actions" />
            </th>
          </tr>
        </thead>
        <tbody>
          {!isPending &&
            data.data.map((row) => (
              <tr key={row._id}>
                <td className="text-left">{row.spotify_username}</td>

                <td className="text-left">
                  {dayjs(row.created_at).format(getDateFormat(locale))}
                </td>

                <td className="text-left">
                  <BlockUser
                    showTitle={false}
                    spotifyId={row.user_id}
                    checkOnlineStatus
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {isPending && <Spinner />}
    </div>
  )
}
