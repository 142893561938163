import React, { useState, useEffect, memo } from 'react'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import Icon from '@fnd/components/Icon'
import Spinner from '@fnd/components/Spinner'
import { PlaylistCover } from '@fnd/modules/Curator/Playlist'
import { usePlaylistStore } from '@fnd/store'
import { isEmpty } from 'lodash-es'
import Toggle from 'react-toggle'
import dayjs from 'dayjs'
import { ROUTES } from '@fnd/screens/constants'
import { getDateFormat } from '@fnd/core/libs/helpers'
import { generatePath, useNavigate } from 'react-router-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { toastFeedback } from '@fnd/core/libs/toast'

function PlaylistsCheckTable({ isLoading, isOwner, playlists: playlistData }) {
  const [playlists, setPlaylists] = useState([])
  const intl = useIntl()
  const { locale } = intl
  const navigate = useNavigate()
  const { success, error, resetFeedback, toggleWatch } = usePlaylistStore()

  useEffect(() => {
    if (!playlistData) return

    if (isOwner) {
      const ownedPlaylists = playlistData.filter(({ isOwner }) => isOwner)
      setPlaylists(ownedPlaylists)
    } else {
      const monitoredPlaylists = playlistData.filter(({ isOwner }) => !isOwner)
      setPlaylists(monitoredPlaylists)
    }
  }, [isOwner, playlistData])

  useEffect(() => {
    if (success) {
      toastFeedback('success', intl.formatMessage({ id: success }))
    } else if (error) {
      toastFeedback('error', intl.formatMessage({ id: error }))
    }

    resetFeedback()
  }, [success, error])

  if (!isLoading && isEmpty(playlists)) {
    return (
      <Empty
        className="my-0"
        message={intl.formatMessage({ id: 'messages.no_playlists' })}
      />
    )
  }

  const handlePlaylistWatch = async (playlistId, watch) => {
    await toggleWatch(playlistId, watch)

    const updatedPlaylist = playlists.map((playlist) => {
      if (playlist.PlaylistId === playlistId) {
        return {
          ...playlist,
          watch,
        }
      }

      return playlist
    })

    setPlaylists(updatedPlaylist)
  }

  const onAnalysisClick = (playlistId) => {
    navigate(
      generatePath(
        `/${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_PLAYLIST_ANALYSIS}`,
        { playlistId }
      )
    )
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th>Playlist</th>

            <th>
              <div className="flex items-center">
                <FormattedMessage id="profile.my_playlists.table.watch" />
                <Icon name="info-circle" className="ml-2" />
              </div>
            </th>

            <th>
              <FormattedMessage id="playlist_check.field.start_monitoring.label" />
            </th>

            <th>
              <FormattedMessage id="playlist_check.field.stop_monitoring.label" />
            </th>

            <th className="text-right">
              <FormattedMessage id="messages.actions" />
            </th>
          </tr>
        </thead>

        <tbody>
          {playlists &&
            playlists.length > 0 &&
            playlists.map((playlist) => (
              <tr key={playlist.PlaylistId}>
                <td>
                  <div className="flex items-center gap-3">
                    <PlaylistCover
                      name={playlist?.name}
                      image={playlist?.images?.[0]?.url}
                      className="rounded flex-shrink-0"
                      style={{ '--playlist-cover-size': '50px' }}
                    />

                    <div className="flex flex-col items-start">
                      <span className="font-medium cursor-pointer">
                        {playlist.PlaylistName}
                      </span>
                    </div>
                  </div>
                </td>

                <td className="playlist-watch">
                  <Toggle
                    checked={playlist.watch}
                    onChange={() =>
                      handlePlaylistWatch(playlist.PlaylistId, !playlist.watch)
                    }
                    name={playlist.id}
                  />
                </td>

                <td>
                  {playlist?.start_monitoring
                    ? dayjs(playlist.start_monitoring).format(
                        getDateFormat(locale, true)
                      )
                    : '-'}
                </td>

                <td>
                  {playlist?.stop_monitoring
                    ? dayjs(playlist.stop_monitoring).format(
                        getDateFormat(locale, true)
                      )
                    : '-'}
                </td>

                <td className="text-right">
                  <Button
                    small
                    variant="blue-light"
                    onClick={() => onAnalysisClick(playlist.PlaylistId)}
                    icon="chart-line"
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {isLoading && <Spinner />}
    </div>
  )
}

export default memo(PlaylistsCheckTable)
