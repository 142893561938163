import React from 'react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import { ROUTES } from '@fnd/screens/constants'
import Avatar from '@fnd/components/Avatar'
import Badge from '@fnd/components/Badge'
import { FormattedMessage } from 'react-intl'
import { Placeholder } from '@fnd/components/Placeholder'

const CuratorCard = ({
  curator,
  children,
  titleTag,
  className,
  featured,
  star,
  ...props
}) => {
  const classes = classNames({
    card: true,
    'curator-card': true,
    'curator-card-featured': featured,
    'curator-card-star': star,
    [className]: className,
  })

  const Heading = titleTag

  return (
    <Link
      to={`${ROUTES.CURATORS}/${curator.id}`}
      className={classes}
      {...props}
    >
      <div className="card-content">
        {curator?.images ? (
          <Avatar star={star} src={curator.images} className="mx-auto mb-4" />
        ) : (
          <Placeholder
            variant="avatar"
            star={star}
            icon="user-music"
            className="mx-auto mb-4"
          />
        )}

        <div className="flex flex-col items-center justify-center">
          <Heading className="inline-flex items-center gap-2">
            {curator?.display_name ? curator.display_name : curator?.id}
          </Heading>

          <Badge
            icon="users"
            label={<FormattedMessage id="match.table.followers" />}
            value={curator?.followers?.total || `0`}
            variant={star ? 'gold' : 'light'}
          />
        </div>

        {children}
      </div>
    </Link>
  )
}

export default CuratorCard

CuratorCard.defaultProps = {
  titleTag: 'h3',
}
