import React, { useState, useEffect } from 'react'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import classNames from 'classnames'
import Field from './Field'

export const QuantityInput = React.forwardRef(
  (
    {
      className,
      children,
      buttonVariant = 'ghost',
      cardVariant = false,
      icon,
      iconType,
      image,
      min = 1,
      max = 10,
      value: initialValue,
      label,
      step = 1,
      inputProps,
      field,
      onChange,
    },
    ref
  ) => {
    const [value, setValue] = useState(initialValue || min)

    useEffect(() => {
      setValue(initialValue || min)
    }, [initialValue, min])

    const handleMinus = () => {
      setValue((prevValue) => {
        const newValue = Math.max(prevValue - step, min)
        onChange(newValue)
        return newValue
      })
    }

    const handlePlus = () => {
      setValue((prevValue) => {
        const newValue = Math.min(prevValue + step, max)
        onChange(newValue)
        return newValue
      })
    }

    const handleChange = (e) => {
      let newValue = +e.target.value
      newValue = Math.max(min, Math.min(newValue, max))
      setValue(newValue)
      onChange(newValue)
    }

    const classes = classNames({
      [className]: className,
    })

    if (cardVariant) {
      return (
        <div className={classes}>
          <div className="input-card-content">
            {image && (
              <div className="input-card-image">
                <img src={image} alt={label} />
              </div>
            )}

            {icon && (
              <Icon className="input-icon" name={icon} type={iconType} />
            )}

            <div className="flex flex-col gap-4 items-start w-full">
              {label && <span>{label}</span>}

              <div className="input-quantity">
                <Button
                  variant={buttonVariant}
                  onClick={handleMinus}
                  icon="minus"
                  disabled={value <= min}
                />

                {!field && (
                  <Field
                    type="number"
                    className="text-center"
                    value={value}
                    min={min}
                    max={max}
                    ref={ref}
                    step={step}
                    onChange={handleChange}
                    {...inputProps}
                  />
                )}

                {field && field}

                <Button
                  variant={buttonVariant}
                  onClick={handlePlus}
                  icon="plus"
                  disabled={value >= max}
                />
              </div>

              {children}
            </div>
          </div>
        </div>
      )
    }
  }
)

export default QuantityInput
