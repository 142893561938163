import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'
import { formatNumber } from '@fnd/core/libs/currency'
import classNames from 'classnames'

const Slider = React.forwardRef(
  (
    {
      showLabels = false,
      showIndicator = true,
      formatLabelFn = formatNumber,
      labelPrefix = '',
      labelSuffix = '',
      onChange,
      value,
      variant,
      className,
      ...props
    },
    ref
  ) => {
    const percentageLimit = 0.15
    const bottomPercent =
      props?.min + (props?.max - props?.min) * percentageLimit
    const topPercent = props?.max - (props?.max - props?.min) * percentageLimit

    return (
      <div className="flex flex-col gap-3 w-full">
        <div>
          <SliderPrimitive.Root
            ref={ref}
            className={classNames({
              'slider-input relative flex w-full touch-none select-none items-center': true,
              [`slider-input-${variant}`]: variant,
              [className]: className,
            })}
            onValueChange={onChange}
            {...props}
          >
            <SliderPrimitive.Track className="relative h-4 w-full grow overflow-hidden rounded-full slider-input-track">
              <SliderPrimitive.Range className="absolute h-full slider-input-value" />
            </SliderPrimitive.Track>
            <SliderPrimitive.Thumb className="block h-6 w-6 rounded-full border-2 slider-input-thumb ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50">
              {showIndicator && value?.[0] && (
                <span className="slider-input-indicator text-sm">
                  {labelPrefix}
                  {formatLabelFn(value[0])}
                  {labelSuffix}
                </span>
              )}
            </SliderPrimitive.Thumb>
          </SliderPrimitive.Root>
        </div>

        {showLabels && (
          <div className="flex justify-between">
            <span
              className={classNames({
                'text-sm transition-opacity': true,
                'opacity-70': showIndicator && value?.[0] > bottomPercent,
                'opacity-0': showIndicator && value?.[0] <= bottomPercent,
              })}
            >
              {labelPrefix}
              {formatLabelFn(props?.min)}
              {labelSuffix}
            </span>
            <span
              className={classNames({
                'text-sm transition-opacity': true,
                'opacity-70': showIndicator && value?.[0] < topPercent,
                'opacity-0': showIndicator && value?.[0] >= topPercent,
              })}
            >
              {labelPrefix}
              {formatLabelFn(props?.max)}
              {labelSuffix}
            </span>
          </div>
        )}
      </div>
    )
  }
)

Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
export default Slider
