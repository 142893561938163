import React, { useState, useEffect, Fragment } from 'react'
import { FormattedMessage } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { ROUTES } from '@fnd/screens/constants'
import CopyToClipboard from '@fnd/components/CopyToClipboard'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import { useConsent } from '@fnd/context/ConsentProvider'

const useReceiveMyAccelerates = () => {
  const [state, setState] = useState({ isLoading: true, accelerates: [] })
  const patch = (next) =>
    setState((currentState) => ({ ...currentState, ...next }))

  useEffect(() => {
    spotimatchEndpoints
      .getMyAccelerates()
      .toPromise()
      .then((accelerates) => patch({ accelerates, isLoading: false }))
      .catch(console.log)
  }, [])

  return [state]
}

export default function MyAccelerates() {
  const [myAcceleratesState] = useReceiveMyAccelerates()
  const { handleIntercomToggle } = useConsent()

  const getContent = () => {
    if (myAcceleratesState.isLoading) {
      return <Spinner />
    }

    if (!myAcceleratesState.accelerates.length) {
      return (
        <Empty message={<FormattedMessage id="messages.no_data" />}>
          <Button to={ROUTES.ACCELERATE_PAGE} className="mt-5">
            <FormattedMessage id="navigation.accelerate" />
          </Button>
        </Empty>
      )
    }

    return (
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="profile.my_accelerates.table.artist_name" />
              </th>
              <th>
                <FormattedMessage id="profile.my_accelerates.table.song_link" />
              </th>
              <th>
                <FormattedMessage id="profile.my_playlists.settings_popup.settings.status" />
              </th>
              <th>
                <FormattedMessage id="messages.sent_created_at" />
              </th>
            </tr>
          </thead>
          <tbody>
            {myAcceleratesState.accelerates.map((accelerate) => {
              const artistLink = `https://open.spotify.com/artist/${accelerate.artistId}`
              return (
                <Fragment key={new Date(accelerate.created_at).getTime()}>
                  <tr>
                    <td className="accelerate-status">
                      <a
                        href={artistLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {accelerate.name}
                      </a>
                      <CopyToClipboard className="ml-2" text={artistLink} />
                    </td>

                    <td>
                      <a
                        href={accelerate.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id="profile.my_accelerates.table.song_link" />
                      </a>
                      <CopyToClipboard
                        className="ml-2"
                        text={accelerate.link}
                      />
                    </td>

                    <td className="accelerate-status">
                      {accelerate.status === 'approved' && (
                        <Badge
                          variant="green-light"
                          icon="check"
                          label={
                            <FormattedMessage id="profile.my_accelerates.table.approved" />
                          }
                        />
                      )}
                      {accelerate.status === 'pending' && (
                        <Badge
                          variant="blue-light"
                          icon="hourglass"
                          label={
                            <FormattedMessage id="profile.my_accelerates.table.pending" />
                          }
                        />
                      )}
                      {accelerate.status === 'declined' && (
                        <Badge
                          variant="red-light"
                          icon="times"
                          label={
                            <FormattedMessage id="profile.my_accelerates.table.declined" />
                          }
                        />
                      )}
                    </td>

                    <td className="accelerate-status">
                      {new Date(accelerate.created_at).toLocaleDateString(
                        'it-IT'
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={99}>
                      <div className="accelerate-comment">
                        {accelerate.comment}
                        <Button
                          icon="comment"
                          type="button"
                          disabled={accelerate.status !== 'approved'}
                          className={
                            accelerate.status !== 'declined'
                              ? 'toggle-intercom'
                              : ''
                          }
                          onClick={
                            accelerate.status !== 'declined'
                              ? handleIntercomToggle
                              : null
                          }
                        >
                          <FormattedMessage id="profile.my_accelerates.table.start_conversation_btn" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                </Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }
  return (
    <ProfileWrapper
      icon="rocket"
      title={<FormattedMessage id="navigation.my_accelerates" />}
    >
      {getContent()}
    </ProfileWrapper>
  )
}
