import React, { useState } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import Alert from '@fnd/components/Alert'
import Button from '@fnd/components/Button'
import { STRIPE_PLAN_SUBSCRIPTIONS, APP_URL } from '@fnd/constants'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { FormattedMessage } from 'react-intl'
import { useAffiliateStore, useDiscountStore } from '@fnd/store'
import { ROUTES } from '@fnd/screens/constants'
import PaymentSecure from './PaymentSecure'

export default function StripeForm({
  amount,
  creditsScoreUsed,
  disabled,
  mode,
  plan,
  payload,
  profile,
  addons,
}) {
  const stripe = useStripe()
  const elements = useElements()
  const [error, setError] = useState()
  const [loading, setLoading] = useState(false)
  const { affiliate } = useAffiliateStore()
  const { discount, canUseDiscount, discountAmount } = useDiscountStore()

  const handleError = (error) => {
    setLoading(false)
    setError(error.message)
  }

  const handleSubmit = async (event) => {
    setLoading(true)
    let customerId = profile?.customer_id
    event.preventDefault()

    const formattedAmount = parseFloat((amount / 100).toFixed(2))

    if (!stripe || !elements || !profile) {
      return
    }

    try {
      const { error: submitError } = await elements.submit()

      if (submitError) {
        handleError(submitError)
        return
      }

      if (!profile?.customer_id) {
        const newCustomer = await spotimatchEndpoints
          .createCustomer()
          .toPromise()
        customerId = newCustomer.customerId
      }

      const metadata = {
        amount,
        affiliateCode: affiliate?.code,
        payload: JSON.stringify(payload),
        plan,
        formattedAmount,
        creditsScoreUsed,
        addOns: JSON.stringify(addons),
      }

      if (canUseDiscount) {
        metadata.couponCode = JSON.stringify({
          code: discount.code,
          amount: discountAmount,
        })
        await spotimatchEndpoints.redeemDiscountCode(
          discount.code,
          plan,
          amount
        )
      }

      if (mode === 'payment') {
        const { clientSecret } = await spotimatchEndpoints
          .createPaymentIntent(plan, customerId, metadata)
          .toPromise()

        await stripe.confirmPayment({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${APP_URL}${ROUTES.PAYMENT_REDIRECT}`,
          },
          redirect: 'always',
        })
      }

      if (mode === 'subscription') {
        const subscription = await spotimatchEndpoints
          .createSubscription({
            customerId,
            plan: STRIPE_PLAN_SUBSCRIPTIONS[plan],
            metadata,
          })
          .toPromise()

        const { type, clientSecret } = subscription
        const confirmIntent =
          type === 'setup' ? stripe.confirmSetup : stripe.confirmPayment

        await confirmIntent({
          elements,
          clientSecret,
          confirmParams: {
            return_url: `${APP_URL}${ROUTES.PAYMENT_REDIRECT}`,
          },
          redirect: 'always',
        })
      }
    } catch (error) {
      handleError(error)
    }

    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />

      <div id="before-pay"></div>

      {error && <Alert variant="danger" className="mb-4 mx-4" label={error} />}

      <PaymentSecure className="px-4 pb-4" />

      <div className="payment-cta">
        <Button
          wide
          loading={loading}
          icon="credit-card"
          label={<FormattedMessage id="payment.pay" />}
          type="submit"
          disabled={!stripe || disabled || loading}
        />
      </div>
    </form>
  )
}
