import React from 'react'
import Button from '@fnd/components/Button'
import Icon from '@fnd/components/Icon'
import { NotificationBadge } from '@fnd/components/Notification'
import { ROUTES } from '@fnd/screens/constants'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import { useQuery } from '@tanstack/react-query'
import { QUERIES } from '@fnd/constants'

export const ConversationNavItem = () => {
  const { data } = useQuery({
    queryKey: [QUERIES.CHAT.COUNT],
    queryFn: () => spotimatchEndpoints.getConversationCount(),
  })

  return (
    <div>
      <Button className="relative" variant="ghost" to={ROUTES.CHAT}>
        <Icon name="comments" />
        <NotificationBadge
          count={data?.totalUnreadCount > 0 ? data?.totalUnreadCount : 0}
        />
      </Button>
    </div>
  )
}

export default ConversationNavItem
