import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import Button from '@fnd/components/Button'
import Empty from '@fnd/components/Empty'
import Spinner from '@fnd/components/Spinner'
import { getMediaUrl } from '@fnd/core/libs/media'
import { useParams } from 'react-router-dom'
import ProfileWrapper from '@fnd/modules/Profile/ProfileWrapper'
import parse from 'html-react-parser'

function DownloadDetail() {
  const { downloadId } = useParams()
  const [download, setDownload] = useState()
  const [isLoading, setIsLoading] = useState(true)

  const fetchDownload = async () => {
    setIsLoading(true)
    const _download = await spotimatchEndpoints
      .getDownload(downloadId)
      .toPromise()

    setDownload(_download)
    setIsLoading(false)
  }

  const handleDownloadFile = async () => {
    spotimatchEndpoints
      .getDownloadAttachment(download._id)
      .toPromise()
      .then(() => {
        const link = document.createElement('a')
        link.href = getMediaUrl(download.attachment)
        link.setAttribute('download', download.attachment.split('/').pop())
        document.body.appendChild(link)
        link.click()
        link.remove()
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(() => {
    fetchDownload()
  }, [])

  if (isLoading) {
    return <Spinner />
  }

  if (!isLoading && !download) {
    return <Empty />
  }

  return (
    <ProfileWrapper
      back={true}
      icon="cloud-download-alt"
      title={<FormattedMessage id="navigation.my_downloads" />}
    >
      <div className="content max-w-screen-sm mx-auto">
        {download.image !== '' && (
          <img
            src={getMediaUrl(download.image)}
            alt={download.name}
            className="rounded-xl"
          />
        )}
        <h1 className="mb-2">{download.title !== '' && download.title}</h1>
        {download.description !== '' && parse(download.description)}
        <div className="flex gap-2">
          {download.attachment !== '' && (
            <Button
              onClick={() => handleDownloadFile()}
              variant="primary"
              icon="download"
            >
              <FormattedMessage id="profile.my_downloads.table.download" />
            </Button>
          )}
        </div>
      </div>
    </ProfileWrapper>
  )
}

export default DownloadDetail
