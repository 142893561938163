import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { useIntl } from 'react-intl'
import debounce from 'lodash/debounce'
import { spotimatchEndpoints } from '@fnd/core/spotimatch'
import ValueContainer from './GenresSelectValueContainer'
import classNames from 'classnames'

const loadOptions = (query, callback) => {
  spotimatchEndpoints
    .getQueries(query)
    .toPromise()
    .then((results) =>
      callback(
        results.map((genre) => ({ label: genre.query, value: genre.query }))
      )
    )
    .catch(console.error)
}

const debouncedLoadOptions = debounce(loadOptions, 1000)

const Genres = React.forwardRef(
  (
    {
      id,
      defaultValue,
      genres,
      onGenreSelect,
      hideTitle = false,
      title,
      isDisabled,
      label,
      errors,
      hideLabel = false,
      maxLimit,
      className,
    },
    ref
  ) => {
    const [options, setOptions] = useState([])
    const [value, setValue] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const intl = useIntl()

    const handleGenreChange = (options, { action }) => {
      const newOptions = options || []
      setValue(newOptions)

      const actionsList = ['select-option', 'remove-value', 'clear']
      if (actionsList.includes(action)) {
        onGenreSelect(newOptions.map((option) => option.value))
      }
    }

    const checkIfOptionDisabled = (option) => {
      const isMaxLimitReached = genres && genres.length >= maxLimit
      const isOptionAlreadySelected = genres && genres.includes(option.value)
      return isMaxLimitReached && !isOptionAlreadySelected
    }

    useEffect(() => {
      if (defaultValue) {
        setValue(defaultValue.map((genre) => ({ value: genre, label: genre })))
        if (onGenreSelect) {
          onGenreSelect(defaultValue)
        }
      }
    }, [defaultValue])

    useEffect(() => {
      if (genres) {
        setValue(genres.map((genre) => ({ value: genre, label: genre })))
      }
    }, [genres])

    useEffect(() => {
      setIsLoading(true)

      // Load initial options with empty query
      loadOptions('', (results) => {
        setOptions(results)
        setIsLoading(false)
      })
    }, [])

    const handleInputChange = (inputValue) => {
      if (inputValue && inputValue.length >= 3) {
        setIsLoading(true)
        debouncedLoadOptions(inputValue, (results) => {
          setOptions(results)
          setIsLoading(false)
        })
      }
    }

    const classes = classNames({
      'field-input-container': true,
      'field-error': errors,
      'cursor-not-allowed opacity-50': isDisabled,
      [className]: className,
    })

    return (
      <div id={id} className={classes}>
        {!hideTitle && title && <h5>{title}</h5>}

        {!hideLabel && (
          <label htmlFor="genres-select">
            {label ? label : intl.formatMessage({ id: 'field.genres.label' })}
          </label>
        )}

        <Select
          ref={ref}
          blurInputOnSelect={false}
          components={{
            ValueContainer,
          }}
          className="select"
          classNamePrefix="select"
          closeMenuOnSelect={false}
          isDisabled={isDisabled}
          isMulti
          isClearable
          inputId="genres-select"
          isLoading={isLoading}
          isOptionDisabled={checkIfOptionDisabled}
          onInputChange={handleInputChange}
          onChange={handleGenreChange}
          options={options}
          placeholder={intl.formatMessage({ id: 'field.genres.placeholder' })}
          value={value}
        />
      </div>
    )
  }
)

export default Genres
