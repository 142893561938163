import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useAppStore = create(
  persist(
    (set) => ({
      version: null,
      profileComplete: false,
      setProfileComplete: (profileComplete) => set({ profileComplete }),
      redirect: '',
      setRedirect: (redirect) => set({ redirect }),
      showBanner: false,
      setShowBanner: (showBanner) => set({ showBanner }),
      setVersion: (version) => set({ version }),
    }),
    {
      name: 'app-storage',
    }
  )
)

export default useAppStore
