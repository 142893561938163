import React from 'react'
import { components } from 'react-select'

export default function ValueContainer({ children, ...props }) {
  return (
    <div className="select-value-container">
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    </div>
  )
}
