import toast from 'react-hot-toast'

export const toastFeedback = (type, message, icon) => {
  switch (type) {
    case 'success':
      toast.success(message)
      break
    case 'error':
      toast.error(message)
      break
    case 'icon':
      toast(message, {
        icon: icon,
      })
      break
    default:
      toast(message)
  }
}
