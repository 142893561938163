import React from 'react'
import dayjs from 'dayjs'
import classNames from 'classnames'
import { useIntl } from 'react-intl'
import { getDateFormat } from '@fnd/core/libs/helpers'

export const Date = ({
  className,
  dateString,
  format,
  includeTime = false,
}) => {
  const intl = useIntl()
  const { locale } = intl

  if (!dateString) return null

  const classes = classNames({
    [className]: className,
  })

  return (
    <time className={classes} dateTime={dateString}>
      {dayjs(dateString).format(getDateFormat(locale, includeTime, format))}
    </time>
  )
}

export default Date
