import React from 'react'
import { ProgressBar } from '@fnd/components/Progress'
import { useIntl } from 'react-intl'
import classNames from 'classnames'

function ArtistPopularityBar({ popularity, className }) {
  const intl = useIntl()

  const classes = classNames({
    'artist-popularity': true,
    [className]: className,
  })

  return (
    <div className={classes}>
      <ProgressBar
        variant="green"
        valueLabel={`${popularity}%`}
        label={intl.formatMessage({ id: 'artist.popularity.label' })}
        value={popularity}
      />
    </div>
  )
}

export default ArtistPopularityBar
