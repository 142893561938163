import Auth from '@fnd/components/Auth'
import Payment from '@fnd/components/Payment'
import { ROUTES } from '@fnd/screens/constants'
import React, { useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { PaymentItem } from '@fnd/components/Plans/PaymentItem'

const PlanActionDefault = ({
  title,
  slug,
  icon,
  color,
  isActive,
  displayMonthly,
  disabled,
  disabledDowngrade,
  defaultOpen,
  user,
  planName,
  price,
  save,
}) => {
  const getPaymentLabel = () => {
    if (user.isAuthorized && user.profile.plan.name === 'artist') {
      return <FormattedMessage id="profile.account_plan.upgrade" />
    }
    return void 0
  }

  return (
    <Auth
      login
      loginLabel={<FormattedMessage id="plans.card.button.login_to_start" />}
      redirect={ROUTES.PRICING}
    >
      <Payment
        disabled={disabled}
        defaultOpen={defaultOpen}
        mode="subscription"
        disabledDowngrade={disabledDowngrade}
        isActive={isActive}
        plan={slug}
        planName={planName}
        color={color}
        title={title}
        price={price}
        save={save}
        monthly={displayMonthly}
        label={getPaymentLabel()}
      >
        <PaymentItem
          title={title}
          subtitle={<FormattedMessage id="navigation.account_plan" />}
          icon={icon}
        />
      </Payment>
    </Auth>
  )
}

export default PlanActionDefault
