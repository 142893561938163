import React, { memo, useState } from 'react'
import AutoSubmitPopup from '@fnd/components/AutoSubmitPopup'
import Badge from '@fnd/components/Badge'
import Button from '@fnd/components/Button'
import { ModalHeader } from '@fnd/components/Modal'
import PlaylistCover from './PlaylistCover'
import { PlaylistSettings } from '@fnd/modules/Curator/Playlist'
import { userProfileSelector } from '@fnd/modules/User'
import useUserContext from '@fnd/modules/User/core/useUserContext'
import { ROUTES } from '@fnd/screens/constants'
import { usePlaylistStore, useQueueStore } from '@fnd/store'
import classNames from 'classnames'
import { FormattedMessage, useIntl } from 'react-intl'
import Modal from 'react-modal'
import Toggle from 'react-toggle'
import { formatNumber } from '@fnd/core/libs/currency'
import { generatePath, useNavigate } from 'react-router-dom'

export function PlaylistTableItem({
  playlist,
  index,
  updatePlaylistData,
  isUserVerified,
  toggleVerification,
  toggleWatch,
}) {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false)
  const [isAutoSubmitModalOpen, setIsAutoSubmitModalOpen] = useState(false)
  const { setPlaylist, resetTracks } = usePlaylistStore()
  const { scoreOffset, resetScoreOffset } = useQueueStore()
  const intl = useIntl()

  const { profile, updateUserReferral } = useUserContext(
    ({ user, updateUserProfile }) => ({
      profile: userProfileSelector(user),
      updateUserReferral: (next) =>
        updateUserProfile({ referral: { $set: next } }),
    })
  )

  const handleModalClose = () => {
    if (profile?.referral) {
      updateUserReferral({
        ...profile?.referral,
        score: profile.referral.score + scoreOffset,
      })
      resetScoreOffset()
    }
    setIsOpen(false)
  }

  const onSettingsClick = async (e) => {
    e.stopPropagation()
    setPlaylist(playlist.PlaylistId, playlist)
    resetTracks(playlist.PlaylistId)
    setIsOpen(true)
  }

  const onAutoSubmitClick = (e) => {
    e.stopPropagation()
    setPlaylist(playlist.PlaylistId, playlist)
    setIsAutoSubmitModalOpen(true)
  }

  const onVerificationClick = (e) => {
    e.stopPropagation()
    setPlaylist(playlist.PlaylistId, playlist)
    toggleVerification(e.target.checked)
  }

  const onWatchClick = (e) => {
    e.stopPropagation()
    setPlaylist(playlist.PlaylistId, playlist)
    toggleWatch()
  }

  const onPlaylistClick = () => {
    setPlaylist(playlist.PlaylistId, playlist)
    navigate(
      generatePath(
        `/${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_PLAYLIST_EDIT}`,
        { playlistId: playlist.PlaylistId }
      )
    )
  }

  const onAnalysisClick = () => {
    setPlaylist(playlist.PlaylistId, playlist)
    navigate(
      generatePath(
        `/${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_PLAYLIST_ANALYSIS}`,
        { playlistId: playlist.PlaylistId }
      )
    )
  }

  return (
    <tr>
      <td>
        <div className="flex items-start gap-3">
          <PlaylistCover
            image={playlist?.images?.[0]?.url}
            name={playlist?.name}
            className="flex-shrink-0"
            onClick={onPlaylistClick}
          />

          <div className="flex flex-col items-start">
            <span
              className="font-medium cursor-pointer"
              onClick={onPlaylistClick}
            >
              {index}
              {'. '}
              {playlist.PlaylistName}
            </span>

            <div className="flex flex-wrap items-center gap-1 mt-1">
              <Badge
                small
                variant="light"
                label={intl.formatMessage({
                  id: 'profile.my_playlists.table.tracks',
                })}
                value={formatNumber(playlist?.NumTracks)}
              />

              <Badge
                small
                variant="light"
                label={intl.formatMessage({
                  id: 'profile.my_playlists.table.followers',
                })}
                value={formatNumber(playlist?.NumFollowers ?? 0)}
              />
            </div>
          </div>
        </div>
      </td>

      <td>
        <Button
          small
          variant={
            playlist.autoAcceptRate < 100 ? 'blue-light' : 'inverse-light'
          }
          label={
            playlist.autoAcceptRate < 100
              ? `${playlist.autoAcceptRate}%`
              : 'Off'
          }
          icon={playlist.autoAcceptRate < 100 ? 'sparkles' : 'toggle-off'}
          onClick={onAutoSubmitClick}
          name={playlist.id}
        />
      </td>

      <td className="playlist-verify">
        <Toggle
          checked={playlist.featured}
          disabled={!isUserVerified}
          onChange={onVerificationClick}
          name={playlist.id}
        />
      </td>

      <td className="playlist-watch">
        <Toggle
          checked={playlist.watch}
          onChange={onWatchClick}
          name={playlist.id}
        />
      </td>

      <td className="playlist-requests">
        <Button
          small
          icon="inbox-in"
          variant={playlist?.submission > 0 ? 'primary-light' : 'light'}
          label={`${playlist?.submission ?? 0}`}
          onClick={() => {
            setPlaylist(playlist.PlaylistId, playlist)
            navigate(
              generatePath(
                `/${ROUTES.MY_PROFILE.PROFILE}/${ROUTES.MY_PROFILE.MY_PLAYLIST_VIEW}`,
                { playlistId: playlist.PlaylistId }
              ),
              {
                state: {
                  playlistName: playlist?.PlaylistName,
                  playlistImage: playlist?.images?.[0]?.url,
                },
              }
            )
          }}
        />
      </td>

      <td>
        <div className="table-actions">
          <Button
            small
            variant="blue-light"
            onClick={onAnalysisClick}
            icon="chart-line"
          />
          <Button small variant="light" icon="cog" onClick={onSettingsClick} />

          <Button small icon="search" onClick={onPlaylistClick} />
        </div>
      </td>

      {isOpen && (
        <Modal
          isOpen={isOpen}
          onRequestClose={handleModalClose}
          shouldCloseOnOverlayClick={false}
          overlayClassName="modal show"
          className={classNames({
            'modal-dialog modal-center modal-md overflow-visible': true,
          })}
        >
          <div className="modal-content">
            <ModalHeader
              title={playlist.PlaylistName}
              onClose={handleModalClose}
            >
              {!playlist.featured && (
                <Badge
                  small
                  className="ml-3"
                  variant="blue"
                  icon="exclamation-triangle"
                  label={<FormattedMessage id="messages.unverified" />}
                />
              )}

              {playlist.featured && (
                <Badge
                  small
                  className="ml-3"
                  variant="green"
                  icon="check"
                  label={
                    <FormattedMessage id="profile.my_playlists.table.verified" />
                  }
                />
              )}
            </ModalHeader>

            <div className="modal-body overflow-visible p-5">
              <PlaylistSettings closeModalFn={handleModalClose} />
            </div>
          </div>
        </Modal>
      )}

      {isAutoSubmitModalOpen && (
        <AutoSubmitPopup
          defaultValue={
            playlist.autoAcceptRate < 100 ? playlist.autoAcceptRate : 50
          }
          updatePlaylistData={updatePlaylistData}
          isOpen={isAutoSubmitModalOpen}
          onClose={() => setIsAutoSubmitModalOpen(false)}
          playlistId={playlist.PlaylistId}
        />
      )}
    </tr>
  )
}

export default memo(PlaylistTableItem)
